export const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}

export const getNonZeroPositiveNumberFromValue = (
  value: string | number | null | undefined,
): number | undefined => {
  // if value = 0, '', undefined or null
  if (!value) return undefined

  const numValue = Number(value)
  // if value = 'string'
  if (Number.isNaN(numValue)) return undefined

  if (numValue < 1) return undefined

  return numValue
}

export const isNumVal = (
  value: string | number | null | undefined,
): boolean => {
  return value != null && value !== '' && !Number.isNaN(Number(value))
}

export const getValIfNum = (
  value: string | number | null | undefined,
): number | undefined => {
  if (value == null || value === '') return undefined

  const num = Number(value)
  if (Number.isNaN(num)) return undefined

  return num
}

export const getAscendingOrDescendingListData = (
  start: number,
  end: number,
  isAscending = true,
) => {
  const list: { value: string; label: string }[] = []

  if (isAscending) {
    for (let i = start; i <= end; i++) {
      if (i < 10) {
        list.push({ value: i.toString(), label: `0${i}` })
      } else {
        list.push({ value: i.toString(), label: i.toString() })
      }
    }
  } else {
    for (let i = end; i >= start; i--) {
      if (i < 10) {
        list.push({ value: i.toString(), label: `0${i}` })
      } else {
        list.push({ value: i.toString(), label: i.toString() })
      }
    }
  }

  return list
}

/**
 * @example
 * round(1.255784, 4)
 * returns 1.2558
 *
 * @param value - the value to be rounded
 * @param decimals - the number of decimal places
 * @returns - the rounded value
 */
export const round = (value: number, decimals: number) => {
  const rounder = 10 ** decimals
  return Math.round((value + Number.EPSILON) * rounder) / rounder
}
