import { Row } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '~/alpha/core'
import isJapanese from '~/utils/isJapanese'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'

/**
 * Path: /tests
 */
const TestPage = () => {
  const auth = useContext(AuthContext)

  const { t, i18n } = useTranslation()

  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJpRuby = isLevelB && isJapanese(i18n)

  const history = useHistory()

  const title = <div className="theme-nav-title">{t('テスト')}</div>

  const cardItems = [
    {
      title: t('トレーニング'),
      url: 'training',
      image: '/images/Training.png',
      desc: isUsingJpRuby ? (
        <span style={{ fontWeight: 'bold' }}>
          <ruby>
            <span>何回も</span>
            <rt className="font-normal text-10-hina">なんかい</rt>
          </ruby>
          {'テストをして\n'}
          <ruby>
            <span>良</span>
            <rt className="font-normal text-10-hina">い</rt>
          </ruby>
          い
          <ruby>
            <span>記録</span>
            <rt className="font-normal text-10-hina">きろく</rt>
          </ruby>
          を
          <ruby>
            <span>目指</span>
            <rt className="font-normal text-10-hina">めざ</rt>
          </ruby>
          そう！
        </span>
      ) : (
        t('何回もテストをして良い記録を目指そう！')
      ),
    },
    {
      title: isUsingJpRuby ? (
        <ruby>
          <span>本番</span>
          <rt className="font-normal text-14-hina">ほんばん</rt>
        </ruby>
      ) : (
        t('本番')
      ),
      url: 'test',
      image: '/images/Video3.png',
      desc: isUsingJpRuby ? (
        <span style={{ fontWeight: 'bold' }}>
          <ruby>
            <span style={{ paddingLeft: 11 }}>本番</span>
            <rt className="font-normal text-10-hina">ほんばん</rt>
          </ruby>
          のテスト
          <ruby>
            <span>結果</span>
            <rt className="font-normal text-10-hina">けっか</rt>
          </ruby>
          {'を\n'}
          <ruby>
            <span style={{ paddingLeft: 11 }}>登録</span>
            <rt className="font-normal text-10-hina">とうろく</rt>
          </ruby>
          しよう！
        </span>
      ) : (
        t('本番のテスト結果を登録しよう！')
      ),
    },
  ]

  const Card = (cardItem, idx) => (
    <div
      key={idx}
      className="w-56 h-96 pt-6 rounded-10px cursor-pointer"
      style={{ background: '#D3ECF3' }}
      onClick={() => history.push(`/${cardItem.url}`)}
    >
      <Row justify="center" align="bottom" className="text-2xl font-black h-11">
        <span>{cardItem.title}</span>
      </Row>
      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 246 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain' }}
        />
      </Row>
      <Row justify="center" className="w-full">
        <span
          className="text-center bold"
          style={{ whiteSpace: 'break-spaces', fontWeight: 700 }}
        >
          {cardItem.desc}
        </span>
      </Row>
    </div>
  )

  const CardMobile = (cardItem, idx) => (
    <div
      key={idx}
      className="pt-6 mb-6 pb-3 rounded-10px cursor-pointer"
      style={{ background: '#D3ECF3', width: '100%', height: 290 }}
      onClick={() => history.push(`/${cardItem.url}`)}
    >
      <Row justify="center" align="bottom" className="text-2xl font-black h-11">
        <span>{cardItem.title}</span>
      </Row>
      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 160 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain', height: 160 }}
        />
      </Row>
      <Row justify="center" className="w-full">
        <span
          className="text-center bold"
          style={{ whiteSpace: 'break-spaces', fontWeight: 700 }}
        >
          {cardItem.desc}
        </span>
      </Row>
    </div>
  )

  return (
    <Dashboard selectedMenu={TabIndex.Tests} navbar={title}>
      <div className="show-pc">
        <Row justify="center" align="middle" className="space-x-4 mt-12 p-3">
          {cardItems.map((cardItem, idx) => Card(cardItem, idx))}
        </Row>
      </div>
      <div className="show-sp">
        <Row
          justify="center"
          align="middle"
          className="pt-6 m-auto"
          style={{ width: 'calc(100vw - 30px)' }}
        >
          {cardItems.map((cardItem, idx) => CardMobile(cardItem, idx))}
        </Row>
      </div>
    </Dashboard>
  )
}

export default TestPage
