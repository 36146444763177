import './PastResultPage.css'

import { AuthContext, useGradesLazyQuery } from '@alpha/core'
import { Button, InputNumber, Modal, Row, message } from 'antd'
import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CloseOutlined } from '@ant-design/icons'
import { useAlphaStore } from '~/context'
import { inputEleParser } from '~/student-utils/string'
import type { OldResultValueBody } from '~/types/api/alpha/student/old-result-value'
import type {
  IOldTestResult,
  IOldTestResultWithTitleStudentId,
  ISchoolBaseInfo,
  StudentTestResultResponse,
} from '~/types/api/alpha/student/test-result'
import type { TestSubjectKey } from '~/types/api/alpha/test-key'
import { schoolHasEnduranceTest } from '~/utils'
import Api from '~/utils/api'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { isNumVal } from '~/utils/number'
import type { StudentMeSchool } from '~/utils/types/api/students'
import { Rt } from '../common/Rt'
import LineTestChart from '../graph/LineTestChart'
import { WarnIcon } from '../icons/WarnIcon'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'
import {
  TestResultByGradeRow,
  TestResultShowingMode,
} from './past-result/types/table'
import {
  convertData,
  convertKey,
  currentTestsImage,
} from './past-result/utils/convert'
import { finDataByIndex, findGradeIndex } from './past-result/utils/find'

/**
 * Path: /past_result
 */
const PastResultPage = () => {
  const { t, i18n } = useTranslation()

  const auth = useContext(AuthContext)
  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isUsingJp
  const gradeTitleClass = `rating-title-hina${
    isUsingJp ? '' : ' rating-title-en'
  }`

  const [isShowCanNotChangeModal, setIsShowCanNotChangeModal] = useState<
    boolean | undefined
  >()
  const [isStudentInputActive, setStudentInputActive] = useState<
    boolean | undefined
  >(undefined)

  const [school, setSchool] = useState<StudentMeSchool | null>(null)

  const [dataBefore, setDataBefore] = useState<IOldTestResult[]>([])

  const [dataEdit, setDataEdit] = useState<IOldTestResultWithTitleStudentId>(
    {} as IOldTestResultWithTitleStudentId,
  )
  const [dataTest, setDataTest] = useState<TestResultByGradeRow>(
    {} as TestResultByGradeRow,
  )
  const [editMode, setEditMode] = useState(false)
  const [loading, setLoading] = useState(false)

  const [minutes, setMinutes] = useState<number | null>(null)
  const [seconds, setSeconds] = useState<number | null>(null)

  const { me: meData } = useAlphaStore()

  const [getGrades] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  const indexEdit = findGradeIndex(dataEdit)

  const [isEnduranceRun, setIsEnduranceRun] = useState(false)

  // testYears
  useEffect(() => {
    if (!meData?.school) {
      return
    }

    const school = meData.school
    setSchool(school)
    setIsEnduranceRun(schoolHasEnduranceTest(school))
    setStudentInputActive(school?.isStudentInputActive)
    setIsShowCanNotChangeModal(!school?.isStudentInputActive)
    getTestData(school?.isStudentInputActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData])

  // getGrades
  useEffect(() => {
    if (!school) {
      return
    }
    const schoolId = school._id
    getGrades({
      variables: {
        input: {
          schoolId,
          testYear: currentYear,
        },
      },
    })
  }, [school, getGrades])

  const [showResult, setShowResult] = useState(TestResultShowingMode.table)

  const checkActive = (state: TestResultShowingMode) => {
    return state === showResult
  }

  const currentTests = isEnduranceRun
    ? {
        height: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>身長</span>
              <rt className="font-normal text-xxs">しんちょう</rt>
            </ruby>
          ) : (
            t('身長')
          ),
          suffix: 'cm',
        },
        weight: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>体重</span>
              <rt className="font-normal text-xxs">たいじゅう</rt>
            </ruby>
          ) : (
            t('体重')
          ),
          suffix: 'kg',
        },
        grip: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>握力</span>
              <rt className="font-normal text-xxs">あくりょく</rt>
            </ruby>
          ) : (
            t('握力')
          ),
          suffix: 'kg',
        },
        sitUps: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>上体起こ</span>
                <rt className="font-normal text-xxs">じょうたいお</rt>
              </ruby>
              し
            </span>
          ) : (
            t('上体起こし')
          ),
          suffix: isUsingJpRuby ? (
            <ruby>
              <span>回</span>
              <rt className="font-normal text-xxs">かい</rt>
            </ruby>
          ) : (
            t('回')
          ),
        },
        bending: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>長座体前屈</span>
              <rt className="font-normal text-xxs w-kanji">
                ちょうざたいぜんくつ
              </rt>
            </ruby>
          ) : (
            t('長座体前屈')
          ),
          suffix: 'cm',
        },
        sideJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>反復横と</span>
                <rt className="font-normal text-xxs">はんぷくよこ</rt>
              </ruby>
              び
            </span>
          ) : (
            t('反復横跳び')
          ),
          suffix: isUsingJpRuby ? (
            <ruby>
              <span>回</span>
              <rt className="font-normal text-xxs">かい</rt>
            </ruby>
          ) : (
            t('回')
          ),
        },
        enduranceRun: { title: t('持久走'), suffix: t('分秒') },
        shuttleRun: {
          title: t('20mシャトルラン'),
          suffix: isUsingJpRuby ? (
            <ruby>
              <span>回</span>
              <rt className="font-normal text-xxs">かい</rt>
            </ruby>
          ) : (
            t('回')
          ),
        },
        sprintRun: {
          title: isUsingJpRuby ? (
            <span>
              50m
              <ruby>
                <span>走</span>
                <rt className="font-normal text-xxs">そう</rt>
              </ruby>
            </span>
          ) : (
            t('50m走')
          ),
          suffix: t('秒'),
        },
        standingJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>立</span>
                <rt className="font-normal text-xxs">た</rt>
              </ruby>
              ち
              <ruby>
                <span>幅</span>
                <rt className="font-normal text-xxs">はば</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('立ち幅跳び')
          ),
          suffix: 'cm',
        },
        handballThrow: {
          title: isUsingJpRuby ? (
            <span>
              ボール
              <ruby>
                <span>投</span>
                <rt className="font-normal text-xxs">な</rt>
              </ruby>
              げ
            </span>
          ) : (
            t('ボール投げ')
          ),
          suffix: 'm',
        },
      }
    : {
        height: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>身長</span>
              <rt className="font-normal text-xxs" style={{ marginRight: -8 }}>
                しんちょう
              </rt>
            </ruby>
          ) : (
            t('身長')
          ),
          suffix: 'cm',
        },
        weight: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>体重</span>
              <rt className="font-normal text-xxs">たいじゅう</rt>
            </ruby>
          ) : (
            t('体重')
          ),
          suffix: 'kg',
        },
        grip: {
          title: isUsingJpRuby ? (
            <ruby>
              <span style={{ paddingLeft: 4, paddingRight: 4 }}>握力</span>
              <rt className="font-normal text-xxs">あくりょく</rt>
            </ruby>
          ) : (
            t('握力')
          ),
          suffix: 'kg',
        },
        sitUps: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>上体起こ</span>
                <rt className="font-normal text-xxs">じょうたいお</rt>
              </ruby>
              し
            </span>
          ) : (
            t('上体起こし')
          ),
          suffix: isUsingJpRuby ? (
            <ruby>
              <span>回</span>
              <rt className="font-normal text-xxs">かい</rt>
            </ruby>
          ) : (
            t('回')
          ),
        },
        bending: {
          title: isUsingJpRuby ? (
            <ruby>
              <span>長座体前屈</span>
              <rt className="font-normal text-xxs w-kanji">
                ちょうざたいぜんくつ
              </rt>
            </ruby>
          ) : (
            t('長座体前屈')
          ),
          suffix: 'cm',
        },
        sideJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>反復横</span>
                <rt className="font-normal text-xxs">はんぷくよこ</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('反復横跳び')
          ),
          suffix: isUsingJpRuby ? (
            <ruby>
              <span>回</span>
              <rt className="font-normal text-xxs">かい</rt>
            </ruby>
          ) : (
            t('回')
          ),
        },
        shuttleRun: {
          title: t('20mシャトルラン'),
          suffix: isUsingJpRuby ? (
            <ruby>
              <span>回</span>
              <rt className="font-normal text-xxs">かい</rt>
            </ruby>
          ) : (
            t('回')
          ),
        },
        sprintRun: {
          title: isUsingJpRuby ? (
            <span>
              50m
              <ruby>
                <span>走</span>
                <rt className="font-normal text-xxs">そう</rt>
              </ruby>
            </span>
          ) : (
            t('50m走')
          ),
          suffix: isUsingJpRuby ? (
            <ruby>
              <span>秒</span>
              <rt className="font-normal text-xxs">びょう</rt>
            </ruby>
          ) : (
            t('秒')
          ),
        },
        standingJump: {
          title: isUsingJpRuby ? (
            <span>
              <ruby>
                <span>立</span>
                <rt className="font-normal text-xxs">た</rt>
              </ruby>
              ち
              <ruby>
                <span>幅</span>
                <rt className="font-normal text-xxs">はば</rt>
              </ruby>
              とび
            </span>
          ) : (
            t('立ち幅跳び')
          ),
          suffix: 'cm',
        },
        handballThrow: {
          title: isUsingJpRuby ? (
            <span>
              ボール
              <ruby>
                <span>投</span>
                <rt className="font-normal text-xxs">な</rt>
              </ruby>
              げ
            </span>
          ) : (
            t('ボール投げ')
          ),
          suffix: 'm',
        },
      }

  const showStateTitle = {
    1: isUsingJpRuby ? (
      <span>
        <ruby>
          <span>過去</span>
          <rt className="font-normal text-14-hina">かこ</rt>
        </ruby>
        の
        <ruby>
          <span>結果</span>
          <rt className="font-normal text-14-hina">けっか</rt>
        </ruby>
        <ruby>
          <span>（表）</span>
          <rt className="font-normal text-14-hina text-center">ひょう</rt>
        </ruby>
      </span>
    ) : (
      t('過去の結果（表）')
    ),
    2: isUsingJpRuby ? (
      <span>
        <ruby>
          <span>過去</span>
          <rt className="font-normal text-14-hina">かこ</rt>
        </ruby>
        の
        <ruby>
          <span>結果</span>
          <rt className="font-normal text-14-hina">けっか</rt>
        </ruby>
        （グラフ）
      </span>
    ) : (
      t('過去の結果（グラフ）')
    ),
  }

  const showStateText = {
    1: isUsingJpRuby ? (
      <span>
        <ruby>
          <span>表</span>
          <rt className="font-normal text-14-hina">ひょう</rt>
        </ruby>
      </span>
    ) : (
      t('表')
    ),
    2: t('グラフ'),
  }

  const editTest = (index: number) => {
    if (!dataTest?.state[index]) {
      return
    }
    if (isStudentInputActive) {
      const schoolInfo: ISchoolBaseInfo = {
        schoolCategory: meData?.schoolCategoryCode || null,
        schoolGrade: meData?.schoolGrade || null,
      }

      const dataEdit = finDataByIndex(
        dataBefore,
        schoolInfo,
        currentYear,
        index,
      )

      if (isEnduranceRun) {
        if (isNumVal(dataEdit.enduranceRunValue)) {
          const enduranceRunValue = dataEdit.enduranceRunValue || 0
          const enduranceRunValueSeconds = enduranceRunValue % 60
          setMinutes((enduranceRunValue - enduranceRunValueSeconds) / 60)
          setSeconds(enduranceRunValueSeconds)
        } else {
          setMinutes(null)
          setSeconds(null)
        }
      }

      setEditMode(true)
      setDataEdit(dataEdit)
    }
  }

  const getTestData = useCallback(
    async (isStudentActive: boolean) => {
      if (!meData) {
        message.error(
          `${t('エラーが発生しました。')} [student data is not available]`,
        )
        return
      }

      const val = {
        schoolCategory: meData.schoolCategoryCode,
        schoolGrade: meData.schoolGrade,
      }

      const { data }: { data: { data: StudentTestResultResponse } } =
        await Api.get('/alpha/v1/student/getStudentTestResult')

      const studentTestResultRes = data?.data

      setDataBefore(studentTestResultRes?.dataBefore || [])
      setDataTest(convertData(studentTestResultRes, val, isStudentActive))
    },
    [meData, t],
  )

  const onEditOldResultValue = async () => {
    try {
      if (isStudentInputActive) {
        setLoading(true)

        const body: OldResultValueBody = {
          gripValue: dataEdit?.gripValue ?? null,
          sitUpsValue: dataEdit?.sitUpsValue ?? null,
          bendingValue: dataEdit?.bendingValue ?? null,
          sideJumpValue: dataEdit?.sideJumpValue ?? null,
          enduranceRunValue: isEnduranceRun
            ? isNumVal(minutes) || isNumVal(seconds)
              ? (minutes ?? 0) * 60 + (seconds ?? 0)
              : null
            : null,
          shuttleRunValue: dataEdit?.shuttleRunValue ?? null,
          sprintRunValue: dataEdit?.sprintRunValue ?? null,
          standingJumpValue: dataEdit?.standingJumpValue ?? null,
          handballThrowValue: dataEdit?.handballThrowValue ?? null,
          sizeTest: {
            weight: dataEdit?.sizeTest?.weight ?? null,
            height: dataEdit?.sizeTest?.height ?? null,
          },
          testYear: dataEdit?.testYear ?? null,
          schoolCategory: dataEdit?.schoolCategory ?? null,
          schoolGrade: dataEdit?.schoolGrade ?? null,
        }

        const apiUrl = '/alpha/v1/student/old-results'

        if (dataEdit?._id) {
          body.id = dataEdit._id

          await Api.put(apiUrl, body)
        } else {
          await Api.post(apiUrl, body)
        }

        await getTestData(isStudentInputActive)
        setLoading(false)
        setEditMode(false)
        message.success(t('登録完了'))
      }
    } catch (error) {
      const schoolInfo = {
        schoolCategory: meData?.schoolCategoryCode || null,
        schoolGrade: meData?.schoolGrade || null,
      }
      setDataEdit(
        finDataByIndex(dataBefore, schoolInfo, currentYear, indexEdit),
      )
      setLoading(false)
      console.error('When call create or edit API, error: ', error)

      message.error(`${t('エラーが発生しました。')} [${error}]`)
    }
  }

  const [dataChart, setDataChart] = useState<any>([])
  /**
   * Test subject keyword
   */
  const [chart, setChart] = useState<TestSubjectKey>('grip')

  useEffect(() => {
    const testResult = dataTest[chart]

    if (testResult?.length) {
      const isReverseChart = chart === 'sprintRun' || chart === 'enduranceRun'
      const dataChartTest = []

      testResult.forEach((value, index) => {
        dataChartTest.push({
          suffix: currentTests[chart].suffix,
          name: t(dataTest.title[index]),
          value:
            isReverseChart && typeof value === 'number' ? 0 - value : value,
        })
      })

      setDataChart(dataChartTest)
    }
  }, [dataTest, chart])

  const editModeEnduranceRunInput = (
    <Row style={{ width: 210 }}>
      <Row className="ant-row-bottom mr-2">
        <InputNumber
          className="border-primary"
          type="number"
          inputMode="decimal"
          step="any"
          disabled={loading}
          defaultValue={minutes ?? undefined}
          parser={inputEleParser}
          onChange={(value) => {
            if (isNumVal(value)) {
              setMinutes(Number.parseInt(value ? value?.toString() : '0'))
            } else {
              // when deleted value
              setMinutes(null)
            }
          }}
        />
        <div className="text-10px">{t('分')}</div>
      </Row>

      <Row className="ant-row-bottom">
        <InputNumber
          className="border-primary"
          type="number"
          inputMode="decimal"
          step="any"
          disabled={loading}
          defaultValue={seconds ?? undefined}
          parser={inputEleParser}
          onChange={(value) => {
            if (isNumVal(value)) {
              setSeconds(Number.parseInt(value ? value.toString() : '0'))
            } else {
              // when deleted value
              setSeconds(null)
            }
          }}
        />
        <div className="text-10px">{t('秒')}</div>
      </Row>
    </Row>
  )

  return (
    <Dashboard
      selectedMenu={TabIndex.View}
      navbar={
        <div className="theme-nav-title">{showStateTitle[showResult]}</div>
      }
    >
      <div className="w-full">
        <div className="select-show-result">
          <div
            className={`result-item ${
              checkActive(TestResultShowingMode.table) ? 'result-active' : ''
            }`}
            onClick={() => {
              setShowResult(TestResultShowingMode.table)
            }}
          >
            {showStateText[1]}
          </div>

          <div
            className={`result-item ${
              checkActive(TestResultShowingMode.graph) ? 'result-active' : ''
            }`}
            onClick={() => {
              setShowResult(TestResultShowingMode.graph)
              setEditMode(false)
            }}
          >
            {showStateText[2]}
          </div>
        </div>
        <div className="show-notify-register-group">
          {isUsingJpRuby ? (
            <span>
              <ruby>
                <span>{'学年'}</span>
                <rt className="text-10px">がくねん</rt>
              </ruby>
              を
              <ruby>
                <span>{'選'}</span>
                <rt className="text-10px">えら</rt>
              </ruby>
              んで、
              <ruby>
                <span>{'過去'}</span>
                <rt className="text-10px">かこ</rt>
              </ruby>
              の
              <ruby>
                <span>{'結果'}</span>
                <rt className="text-10px">けっか</rt>
              </ruby>
              を
              <ruby>
                <span>{'登録'}</span>
                <rt className="text-10px">とうろく</rt>
              </ruby>
              しよう！
            </span>
          ) : (
            <span>{t('学年を選んで、過去の結果を登録しよう！')}</span>
          )}
        </div>
        <div className="p-5">
          {checkActive(TestResultShowingMode.table) && (
            <>
              <Row className="table-test-form">
                <table className="table-test-label">
                  <thead>
                    <tr>
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(currentTests).map((key) => (
                      <tr key={key}>
                        <td className="image-test">
                          <div className="flex items-center">
                            <div className="image">
                              <img src={currentTestsImage[key]} alt="" />
                            </div>

                            <div
                              className={`flex items-center${
                                isUsingJp ? '' : ' text-xs'
                              }`}
                            >
                              <span
                                style={{
                                  margin: 0,
                                  padding: 0,
                                  border: 'none',
                                  outline: 'none',
                                  boxShadow: 'none',
                                }}
                              >
                                {currentTests[key].title} (
                                {currentTests[key].suffix})
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <div className="table-right">
                  <table className="table-test">
                    <thead>
                      <tr>
                        {dataTest?.title?.map((value, index) => (
                          <th
                            className={`text-center ${
                              dataTest?.state[index] ? 'cursor-pointer' : ''
                            }`}
                            key={index}
                            onClick={() => {
                              editTest(index)
                            }}
                          >
                            {index === 0 ? (
                              isUsingJpRuby ? (
                                <span className="rating-title-hina">
                                  <ruby>
                                    小1
                                    <rt>しょう</rt>
                                  </ruby>
                                </span>
                              ) : (
                                <span className={gradeTitleClass}>
                                  <ruby>{t('小1')}</ruby>
                                </span>
                              )
                            ) : index === 6 ? (
                              isUsingJpRuby ? (
                                <span className="rating-title-hina">
                                  <ruby>
                                    中1
                                    <rt>ちゅう</rt>
                                  </ruby>
                                </span>
                              ) : (
                                <span className={gradeTitleClass}>
                                  <ruby>{t('中1')}</ruby>
                                </span>
                              )
                            ) : index === 9 ? (
                              isUsingJpRuby ? (
                                <span className="rating-title-hina">
                                  <ruby>
                                    高1
                                    <rt>こう</rt>
                                  </ruby>
                                </span>
                              ) : (
                                <span className={gradeTitleClass}>
                                  <ruby>{t('高1')}</ruby>
                                </span>
                              )
                            ) : (
                              <span className="rating-title">{value}</span>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {Object.keys(currentTests).map((key) => {
                        const keyValue = convertKey(key)
                        return (
                          <tr key={key}>
                            {dataTest[key]?.map(
                              (val: null | number, index: number) => (
                                <td
                                  className="text-center"
                                  key={index}
                                  style={{ minWidth: 72 }}
                                >
                                  {editMode && index === indexEdit ? (
                                    key === 'enduranceRun' ? (
                                      editModeEnduranceRunInput
                                    ) : (
                                      <InputNumber
                                        className="border-primary"
                                        type="number"
                                        inputMode="decimal"
                                        step="any"
                                        disabled={loading}
                                        defaultValue={
                                          key === 'height' || key === 'weight'
                                            ? dataEdit?.sizeTest?.[key] ?? ''
                                            : dataEdit[keyValue]
                                        }
                                        parser={inputEleParser}
                                        onChange={(value) => {
                                          if (
                                            key === 'height' ||
                                            key === 'weight'
                                          ) {
                                            dataEdit.sizeTest[key] = value
                                          } else {
                                            dataEdit[keyValue] = value
                                          }

                                          setDataEdit(dataEdit)
                                        }}
                                      />
                                    )
                                  ) : key === 'enduranceRun' ? (
                                    val != null ? (
                                      moment()
                                        .startOf('day')
                                        .seconds(val)
                                        .format(t('m分s秒'))
                                    ) : (
                                      '--'
                                    )
                                  ) : (
                                    val ?? '--'
                                  )}
                                </td>
                              ),
                            )}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </Row>
              {editMode ? (
                <Row justify="center" className="mt-5">
                  <Button
                    type="primary"
                    className="w-60"
                    size="large"
                    loading={loading}
                    disabled={!isStudentInputActive}
                    onClick={onEditOldResultValue}
                  >
                    {isUsingJpRuby ? (
                      <ruby>
                        <span style={{ marginLeft: 2, marginRight: 2 }}>
                          {'登録'}
                        </span>
                        <rt className="text-10px">とうろく</rt>
                      </ruby>
                    ) : (
                      <span>{t('登録')}</span>
                    )}
                  </Button>
                </Row>
              ) : null}
            </>
          )}
          {checkActive(TestResultShowingMode.graph) && (
            <>
              <div className="show-pc">
                <div className="grid grid-cols-8 list-exam-graph">
                  {Object.keys(currentTests).map((key: TestSubjectKey) => {
                    if (['weight', 'height'].includes(key)) {
                      return
                    }
                    return (
                      <div
                        key={key}
                        className={`${
                          chart === key ? 'exam-active ' : ''
                        }text-center${isUsingJp ? '' : '  text-12px'}`}
                        onClick={() => setChart(key)}
                        style={key === 'shuttleRun' ? { fontSize: 11 } : {}}
                      >
                        {currentTests[key]!.title}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="show-sp">
                <div className="grid grid-cols-4 list-exam-graph">
                  {Object.keys(currentTests).map((key: TestSubjectKey) => {
                    if (['weight', 'height'].includes(key)) {
                      return
                    }

                    return (
                      <div
                        key={key}
                        className={`${
                          chart === key ? 'exam-active' : ''
                        }text-center`}
                        onClick={() => setChart(key)}
                        style={key === 'shuttleRun' ? { fontSize: 11 } : {}}
                      >
                        {currentTests[key]!.title}
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="mt-5">
                <LineTestChart dataChart={dataChart} />
              </div>
            </>
          )}
          <Modal
            visible={isShowCanNotChangeModal}
            onCancel={() => setIsShowCanNotChangeModal(false)}
            width={900}
            closeIcon={
              <CloseOutlined
                className="text-base"
                style={{ color: '#036EB8' }}
              />
            }
            footer={null}
            centered={true}
            bodyStyle={{ border: '3px solid #036EB8', borderRadius: 4 }}
          >
            <div
              className="p-10 flex flex-col justify-center items-center text-base font-black"
              style={{ color: '#B80303' }}
            >
              <div className="flex">
                {WarnIcon}
                {isUsingJpRuby ? (
                  <span>
                    ただいま
                    <ruby>
                      測定結果<Rt className="text-tiny">そくていけっか</Rt>
                    </ruby>
                    を
                    <ruby>
                      入力<Rt className="text-tiny">にゅうりょく</Rt>
                    </ruby>
                    できません。
                  </span>
                ) : (
                  <span>{t('ただいま測定結果を入力できません。')}</span>
                )}
              </div>
              {isUsingJpRuby ? (
                <span>
                  <ruby>
                    設定<Rt className="text-tiny">せってい</Rt>
                  </ruby>
                  を
                  <ruby>
                    変更<Rt className="text-tiny">へんこう</Rt>
                  </ruby>
                  したい
                  <ruby>
                    場合<Rt className="text-tiny">ばあい</Rt>
                  </ruby>
                  は
                  <ruby>
                    先生<Rt className="text-tiny">せんせい</Rt>
                  </ruby>
                  にお
                  <ruby>
                    問<Rt className="text-tiny">と</Rt>
                  </ruby>
                  い
                  <ruby>
                    合<Rt className="text-tiny">あ</Rt>
                  </ruby>
                  わせください。
                </span>
              ) : (
                <span>
                  {t('設定を変更したい場合は先生にお問い合わせください。')}
                </span>
              )}
            </div>
          </Modal>
        </div>
      </div>
    </Dashboard>
  )
}

export default PastResultPage
