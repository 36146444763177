import './screen-container.css'

import { Spin } from 'antd'
import { LoadingIcon } from './LoadingIcon'

const ScreenLoading = ({ isLoading }: { isLoading: boolean }) => (
  <div className="loading-container" hidden={!isLoading}>
    <Spin indicator={LoadingIcon} />
  </div>
)

export default ScreenLoading
