import { TFunction } from 'i18next'

export const getMinutes = (
  seconds: number | undefined | null,
): number | '-' => {
  return seconds != null ? Math.floor(seconds / 60) : '-'
}

/**
 * @example
 * `408.85 - Math.floor(408.85 / 60) * 60` is
 * 48.85000000000002
 *
 * So we need to round the number to the 2 place decimal.
 */
export const getRemainingSeconds = (
  seconds: number | undefined | null,
): number | '-' => {
  return seconds != null
    ? Math.round(seconds - Math.floor(seconds / 60) * 60)
    : '-'
}

export const getFormattedMinSeconds = (
  seconds: number,
  t: TFunction<'translation', undefined>,
) => `${getMinutes(seconds)}${t('分')}${getRemainingSeconds(seconds)}${t('秒')}`
