import { useHistory } from 'react-router-dom'

const HomePage = () => {
  const history = useHistory()
  history.push('/movie')

  return <></>
}

export default HomePage
