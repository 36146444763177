/**
 * {@link https://fairnavi.com/}
 */
export const getFairNavEle = (): HTMLDivElement | undefined => {
  const elements = window.document.getElementsByClassName(
    'openSideBarButton',
  ) as HTMLCollectionOf<HTMLDivElement>

  return elements[0]
}

export const displayNoneFairNav = () => {
  const element = getFairNavEle()

  if (element !== undefined) {
    element.style.display = 'none'
  } else {
    // keep this log
    console.log('FairNav is not found!')
  }
}

export const displayFairNav = () => {
  const element = getFairNavEle()

  if (element !== undefined) {
    element.style.display = ''
  } else {
    // keep this log
    console.log('FairNav is not found!')
  }
}
