import type { i18n } from 'i18next'

/**
 *
 * @param instance
 * @returns
 */
const isJapanese = (instance: i18n) => {
  return instance.language.startsWith('ja')
}

export default isJapanese
