import { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { LOCAL_STORAGE_AUTH_TOKEN_KEY } from '~/alpha/core'

interface PrivateRouteProps {
  component: FC<any>
  path: string
  notRedirect?: boolean
}

export const PrivateRoute = ({
  component: Component,
  path,
  notRedirect,
}: PrivateRouteProps) => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)

  return (
    <Route
      notRedirect={notRedirect}
      render={(props) => {
        if (!authToken && path !== '/') {
          // keep this log
          console.log('Not found auth token, redirect to login page!')

          return (
            <Redirect to={{ pathname: '/', state: { from: props.location } }} />
          )
        }
        return <Component {...props} />
      }}
    />
  )
}
