import axios from 'axios'
import {
  AUTH_TOKEN_KEY,
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  SCHOOL_LEVEL,
} from '~/alpha/core/auth/AuthProvider'
import { LOCAL_STORAGE_POPUP } from '~/utils/constants'

const clearedStorageKeys = [
  LOCAL_STORAGE_AUTH_TOKEN_KEY,
  LOCAL_STORAGE_POPUP,
  SCHOOL_LEVEL,
  /**
   * {@link https://github.com/carvajalconsultants/apollo-link-offline}
   */
  'offlineLinkAttemptIds',
] as const

export const clearLocalStorage = () => {
  for (const key of clearedStorageKeys) {
    window.localStorage.removeItem(key)
  }
}

export const clearStorageAndAuthToken = () => {
  clearLocalStorage()
  delete axios.defaults.headers.common[AUTH_TOKEN_KEY]
}
