/**
 * First item is city code.
 * Second item can be prefecture or city name.
 * Third item is question IDs.
 */
export type PrefectureCityQuestion2024 = [number, string, number[]]

export interface StudentQuestionExtraText {
  name: string
  hiragana: string
}

export type StudentQuestionExtra = Record<string, StudentQuestionExtraText>

export enum AnswerType {
  options = 'options',
  text = 'text', // can enter text value
  number = 'number', // can enter number value
  multipleSelect = 'multipleSelect'
}

export interface Question {
  question: string
  answerType?: AnswerType // default is options
  answerUnit?: string
  kanjiAnswerUnit?: Record<string, string>[]
  id: number
  name: string
  options: Record<string, number>
  kanjiQuestion: Record<string, string>[]
  kanjiOption: Record<string, Record<string, string>[]>

  /**
   * Determine whether this question is required or not.
   * Default is `false` or `undefined`.
   */
  isOptional?: boolean

  /**
   * @example
   * {
   *   '1': {name: '', hiragana: ''},
   *   '2': {name: '', hiragana: ''},
   * }
   */
  extra?: StudentQuestionExtra
  extra25?: StudentQuestionExtra
}
