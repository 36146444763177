import {
  ResponsiveContainer,
  Tooltip,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Line,
} from 'recharts'
import moment from 'moment'

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const data =
      typeof payload[0]?.value === 'number'
        ? Math.abs(payload[0].value)
        : payload[0].value

    let descLabel =
      typeof payload[0].payload.suffix === 'string' ? (
        `${data}${payload[0].payload.suffix}`
      ) : (
        <>
          {data}
          {payload[0].payload.suffix}
        </>
      )
    if (payload?.[0]?.payload?.suffix === '分秒') {
      descLabel = moment()
        .startOf('day')
        .seconds(data || 0)
        .format('m分s秒')
    }
    return (
      <div className="custom-tooltip">
        <p className="label">{label}</p>
        <p className="desc">{descLabel}</p>
      </div>
    )
  }

  return null
}

const LineTestChart = ({ dataChart }) => {
  return (
    <div className="chart-result">
      <ResponsiveContainer width="100%" height={352}>
        <LineChart
          width={500}
          height={500}
          data={dataChart}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" padding={{ left: 44, right: 0 }} />
          <YAxis
            tickFormatter={(value) => {
              return Math.abs(Number.parseInt(value)).toString()
            }}
          />
          <Tooltip
            content={
              <CustomTooltip
                active={undefined}
                payload={undefined}
                label={undefined}
              />
            }
          />
          <Line
            type="monotone"
            dataKey="value"
            stroke="#036EB8"
            fill="#8884d8"
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default LineTestChart
