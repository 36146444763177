import { useApolloClient } from '@apollo/client'
import { Button } from 'antd'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { clearStorageAndAuthToken } from '~/student-utils/storages'
import isJapanese from '~/utils/isJapanese'
import { Rt } from '../common/Rt'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'

const LogoutPage = () => {
  const { t, i18n } = useTranslation()

  const isUsingJp = isJapanese(i18n)

  const AccountMenu = () => {
    const client = useApolloClient()
    const history = useHistory()

    const handleLogoutBtn = useCallback(async () => {
      clearStorageAndAuthToken()
      await client.clearStore()

      setTimeout(() => {
        window.open(`/?${Date.now()}`, '_self')

        // keep this log
        console.log('User is logout completely!')
      }, 500)
    }, [client])

    const handleReturnBtn = () => {
      history.push('/movie')
    }

    return (
      <Dashboard
        selectedMenu={TabIndex.NoFocus}
        navbar={<div className="theme-nav-title">{t('ログアウト')}</div>}
      >
        <div className="show-pc">
          <div
            className="h-full flex justify-center items-center overflow-hidden"
            style={{ paddingTop: 140 }}
          >
            <div
              style={{ width: 800, height: 320 }}
              className="bg-gray-150 rounded-10px flex flex-col justify-between items-center pt-7 pb-16 relative"
            >
              <img
                className="absolute"
                style={{ top: -128 }}
                src="/images/AlphaWhale.png"
                alt="AlphaWhale"
              />
              <div className="text-2xl font-black">{t('ログアウト')}</div>
              {/* TODO: 学年区分の判定 */}
              {isUsingJp ? (
                <div className="font-black pt-5">
                  <ruby>
                    本当
                    <Rt className="text-xxs">ほんとう</Rt>
                  </ruby>
                  にログアウトしますか？
                </div>
              ) : (
                <div className="font-black pt-5">
                  {t('本当にログアウトしますか？')}
                </div>
              )}
              <div className="space-x-4">
                <Button
                  className="w-60 rounded"
                  type="primary"
                  onClick={handleLogoutBtn}
                >
                  {t('はい')}
                </Button>
                <Button
                  className="w-60 rounded"
                  type="primary"
                  onClick={handleReturnBtn}
                >
                  {t('いいえ')}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="show-sp">
          <div className="h-full flex justify-center items-center overflow-hidden">
            <div className="bg-gray-150 rounded-10px flex flex-col justify-between items-center pt-7 pb-16 relative modal-mypage mt-40">
              <img
                className="absolute"
                style={{ top: -128 }}
                src="/images/AlphaWhale.png"
                alt="AlphaWhale"
              />
              <div className="text-2xl font-black">{t('ログアウト')}</div>
              {/* TODO: 学年区分の判定 */}
              {isUsingJp ? (
                <div className="font-black pt-5">
                  <ruby>
                    本当
                    <Rt className="text-xxs">ほんとう</Rt>
                  </ruby>
                  にログアウトしますか？
                </div>
              ) : (
                <div className="font-black pt-5">
                  {t('本当にログアウトしますか？')}
                </div>
              )}
              <div className="space-x-4 mt-4">
                <Button
                  className="w-30 rounded"
                  type="primary"
                  onClick={handleLogoutBtn}
                >
                  {t('はい')}
                </Button>
                <Button
                  className="w-30 rounded"
                  type="primary"
                  onClick={handleReturnBtn}
                >
                  {t('いいえ')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dashboard>
    )
  }

  return <AccountMenu />
}

export default LogoutPage
