import { getFairNavEle } from '~/utils/ele'

/**
 * Duration for long press in milliseconds
 */
const pressDuration = 500
let pressTimer: number | undefined = undefined
let isDraggableFromLongPress = false
/**
 * Size of the marker / 2 = 50 / 2 = 25
 */
const markerOffset = { x: 25, y: 25 }

// Keep this comments:
// For testing without FairNav, create a sample marker:
// const marker = document.createElement('div')
// Object.assign(marker.style, {
//   position: 'absolute',
//   left: '100px',
//   top: '100px',
//   width: '20px',
//   height: '20px',
//   backgroundColor: 'red',
//   borderRadius: '50%',
//   cursor: 'move',
// })
// marker.id = 'draggable-marker'
// marker.className = 'animate-up-down'
// marker.style.animationPlayState = 'paused'
// document.body.appendChild(marker)

export const createDraggableMarker = () => {
  window.oncontextmenu = (event: MouseEvent) => {
    if ((event as PointerEvent).pointerType === 'touch') {
      // context menu was not triggered by long press
      return false
    }

    // just to show that pointerEvent.pointerType has another value 'mouse' aka right click
    // if (pointerEvent.pointerType === 'mouse') {
    // context menu was triggered by right click
    //   return true
    // }

    // returning true will show a context menu for other cases
    return true
  }

  const marker = getFairNavEle()

  if (marker === undefined) {
    // keep this log
    console.log('FairNav is not found! when createDraggableMarker')
    return
  }

  marker.classList.add('animate-up-down')
  marker.style.animationPlayState = 'paused'

  const handleMouseMove = (e: TouchEvent) => {
    if (!isDraggableFromLongPress) return

    const touch = e.touches[0]

    const clientX = touch.clientX
    const clientY = touch.clientY

    const style = marker.style
    style.left = `${clientX - markerOffset.x}px`
    style.top = `${clientY - markerOffset.y}px`
  }

  const handleTouchStart = (e: TouchEvent) => {
    // e.preventDefault()
    if (!isDraggableFromLongPress) {
      pressTimer = window.setTimeout(() => {
        isDraggableFromLongPress = true
        marker.style.animationPlayState = 'running'
      }, pressDuration)
    }
  }

  const handleTouchEnd = () => {
    if (pressTimer !== undefined) {
      clearTimeout(pressTimer)
      pressTimer = undefined
    }

    if (isDraggableFromLongPress) isDraggableFromLongPress = false
    if (marker.style.animationPlayState === 'running') {
      marker.style.animationPlayState = 'paused'
    }
  }

  marker.addEventListener('touchstart', handleTouchStart)
  marker.addEventListener('touchend', handleTouchEnd)
  document.addEventListener('touchmove', handleMouseMove as EventListener)

  // return () => {
  //   document.removeEventListener('mousemove', handleMouseMove)
  //   document.removeEventListener('mouseup', handleMouseUp)
  //   document.body.removeChild(marker)
  // }
}
