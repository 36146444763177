export const studentGradeListTitle = [
  '小1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '中1',
  '2',
  '3',
  '高1',
  '2',
  '3',
] as const
