import type { TFunction } from 'i18next'

export const elementaryStandard = (t: TFunction<'translation', undefined>) => [
  {
    level: 'A',
    '6': t('以上', { v: '39' }),
    '7': t('以上', { v: '47' }),
    '8': t('以上', { v: '53' }),
    '9': t('以上', { v: '59' }),
    '10': t('以上', { v: '65' }),
    '11': t('以上', { v: '71' }),
  },
  {
    level: 'B',
    '6': '33~38',
    '7': '41~46',
    '8': '46~52',
    '9': '52~58',
    '10': '58~64',
    '11': '63~70',
  },
  {
    level: 'C',
    '6': '27~32',
    '7': '34~40',
    '8': '39~45',
    '9': '45~51',
    '10': '50~57',
    '11': '55~62',
  },
  {
    level: 'D',
    '6': '22~26',
    '7': '27~33',
    '8': '32~38',
    '9': '38~44',
    '10': '42~49',
    '11': '46~54',
  },
  {
    level: 'E',
    '6': t('以下', { v: '21' }),
    '7': t('以下', { v: '26' }),
    '8': t('以下', { v: '31' }),
    '9': t('以下', { v: '37' }),
    '10': t('以下', { v: '41' }),
    '11': t('以下', { v: '45' }),
  },
]
