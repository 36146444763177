import create from 'zustand'

export interface IOnlineInfo {
  isOnline: boolean
}

interface IOnlineStore extends IOnlineInfo {
  setIsOnline: (isOnline: boolean) => void
}

export const useIsOnlineStore = create<IOnlineStore>()((set) => ({
  isOnline: true,
  setIsOnline: (isOnline: boolean) => set({ isOnline }),
}))
