export const WarnIcon = (
  <svg
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.7679 3C15.5378 1.66667 17.4623 1.66667 18.2321 3L29.0574 21.75C29.8272 23.0833 28.8649 24.75 27.3253 24.75H5.67468C4.13508 24.75 3.17283 23.0833 3.94263 21.75L14.7679 3Z"
      fill="#FFC833"
    />
    <path
      d="M17.64 17.74C17.62 17.5 17.62 17.41 17.62 17.3C17.62 17.14 17.63 16.91 17.65 16.51L17.85 13.34C17.89 12.8 17.92 12.38 17.97 11.99H15.64C15.68 12.3 15.73 12.84 15.76 13.34L15.96 16.51C16 17.11 16 17.11 16 17.22C16 17.45 16 17.51 15.98 17.74H17.64ZM17.82 20.31V18.42H15.81V20.31H17.82Z"
      fill="#B80303"
    />
  </svg>
)
