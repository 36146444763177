export const formatDataQuestion = (
  text: string,
  kanji: Record<string, string>[],
) => {
  const data: ({ type: 'text' | 'kanji'; kanji?: string } & Record<
    string,
    string
  >)[] = []
  let textCheck = text

  kanji.forEach((value, index) => {
    if (textCheck) {
      const arrayText = textCheck.split(value.kanji)
      if (arrayText.length) {
        switch (arrayText.length) {
          case 1:
            if (arrayText[0] === '') {
              data.push({ ...value, type: 'kanji' })
            } else {
              data.push({ kanji: arrayText[0], type: 'text' })
            }
            textCheck = ''
            break
          case 2:
            if (arrayText[0] === '') {
              data.push({ ...value, type: 'kanji' })
              textCheck = arrayText[1]
            } else {
              data.push({ kanji: arrayText[0], type: 'text' })
              data.push({ ...value, type: 'kanji' })
              textCheck = arrayText[1]
            }
            break
          default:
            if (arrayText[0] === '') {
              data.push({ ...value, type: 'kanji' })
              textCheck = textCheck.replace(value.kanji, '')
            } else {
              data.push({ kanji: arrayText[0], type: 'text' })
              data.push({ ...value, type: 'kanji' })
              textCheck = textCheck.replace(arrayText[0] + value.kanji, '')
            }
            break
        }
      }
      if (kanji.length === index + 1 && textCheck) {
        data.push({ kanji: textCheck, type: 'text' })
      }
    }
  })

  return data
}
