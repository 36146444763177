import { Rule } from 'antd/lib/form'
import { TFunction } from 'i18next'
import { Rt } from '~/components/common/Rt'
import { getOneDecimalPlaceRule, integerRule } from './constants'

export interface InputConfig {
  key: string
  tab: string | JSX.Element
  tab_width: string
  measurable: boolean
  suffix?: string
  items: {
    label?: string
    name: string
    suffix: string
    min?: number
    max?: number
    rules: Rule[]
  }[]
}

/**
 * Grip:
 * 握力の計算式について
 * 左右良い方の記録を小数点切り捨て
 * 握力平均＝（左の良い方＋右の良い方）/2
 * ※握力平均が小数点の場合は繰り上げ
 *
 * 例）
 * 左1回目：10.4
 * 左2回目：11.8
 * 右1回目：9
 * 右2回目：12.7
 *
 * 左の良い方：11
 * 右の良い方：12
 *
 * 握力平均＝(11+12)/2=11.5=12
 * ※Excelでの一括アップロード時も入力範囲外の数値がある場合アラートを出すようにしたい
 *
 */
export const getInputListEle = (
  t: TFunction<'translation', undefined>,
  isUsingJpRuby: boolean,
  isUsingJp: boolean,
): InputConfig[] => [
  {
    key: 'grip',
    tab: isUsingJpRuby ? (
      <ruby>
        <span className="py-11px">握力</span>
        <Rt className="text-10px">あくりょく</Rt>
      </ruby>
    ) : (
      t('握力')
    ),
    tab_width: isUsingJp ? 'w-16' : 'w-36',
    measurable: true,
    suffix: 'kg',
    items: [
      {
        label: t('右（1回目）'),
        name: 'gripRight1',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 99,
            }),
          },
          getOneDecimalPlaceRule(t),
        ],
      },
      {
        label: t('右（2回目）'),
        name: 'gripRight2',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 99,
            }),
          },
          getOneDecimalPlaceRule(t),
        ],
      },
      {
        label: t('左（1回目）'),
        name: 'gripLeft1',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 99,
            }),
          },
          getOneDecimalPlaceRule(t),
        ],
      },
      {
        label: t('左（2回目）'),
        name: 'gripLeft2',
        suffix: 'kg',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 99,
            }),
          },
          getOneDecimalPlaceRule(t),
        ],
      },
    ],
  },
  {
    key: 'sitUps',
    tab: isUsingJpRuby ? (
      <ruby>
        上体起
        <Rt className="text-10px">じょうたいお</Rt>
        こし
      </ruby>
    ) : (
      t('上体起こし')
    ),
    tab_width: 'w-28',
    measurable: true,
    suffix: '回',
    items: [
      {
        name: 'sitUps',
        suffix: '回',
        min: 0,
        max: 70,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 70,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 70,
            }),
          },
          integerRule(t),
        ],
      },
    ],
  },
  {
    key: 'bending',
    tab: isUsingJpRuby ? (
      <ruby>
        長座体前屈
        <Rt className="text-10px">ちょうざたいぜんくつ</Rt>
      </ruby>
    ) : (
      t('長座体前屈')
    ),
    tab_width: 'w-32',
    measurable: true,
    suffix: 'cm',
    items: [
      {
        label: t('（1回目）'),
        name: 'bending1',
        suffix: 'cm',
        min: 1,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 1,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 1,
              max: 99,
            }),
          },
          integerRule(t),
        ],
      },
      {
        label: t('（2回目）'),
        name: 'bending2',
        suffix: 'cm',
        min: 1,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 1,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 1,
              max: 99,
            }),
          },
          integerRule(t),
        ],
      },
    ],
  },
  {
    key: 'sideJump',
    tab: isUsingJpRuby ? (
      <ruby>
        反復横跳び
        <Rt className="text-10px">はんぷくよことび</Rt>
      </ruby>
    ) : (
      t('反復横跳び')
    ),
    tab_width: 'w-28',
    measurable: true,
    suffix: '回',
    items: [
      {
        label: t('（1回目）'),
        name: 'sideJump1',
        suffix: '回',
        min: 1,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 1,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 1,
              max: 99,
            }),
          },
          integerRule(t),
        ],
      },
      {
        label: t('（2回目）'),
        name: 'sideJump2',
        suffix: '回',
        min: 1,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 1,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 1,
              max: 99,
            }),
          },
          integerRule(t),
        ],
      },
    ],
  },
  {
    key: 'shuttleRun',
    tab: t('20mシャトルラン'),
    tab_width: 'w-36',
    measurable: true,
    suffix: '回',
    items: [
      {
        name: 'shuttleRunCount',
        suffix: '回',
        min: 0,
        max: 247,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 247,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 247,
            }),
          },
          integerRule(t),
        ],
      },
    ],
  },
  {
    key: 'enduranceRun',
    tab: t('持久走'),
    tab_width: isUsingJp ? 'w-20' : 'w-36',
    measurable: true,
    items: [
      {
        name: 'runningTime',
        suffix: '分',
        rules: [
          {
            type: 'number',
            min: 1,
            max: 30,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 1,
              max: 30,
            }),
          },
          integerRule(t),
        ],
      },
      {
        name: 'runningTimeSeconds',
        suffix: '秒',
        rules: [
          {
            type: 'number',
            min: 0,
            max: 59,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 59,
            }),
          },
          integerRule(t),
        ],
      },
    ],
  },
  {
    key: 'sprintRun',
    tab: isUsingJpRuby ? (
      <span>
        50m
        <ruby>
          走<Rt className="text-10px">そう</Rt>
        </ruby>
      </span>
    ) : (
      t('50m走')
    ),
    tab_width: isUsingJp ? 'w-20' : 'w-36',
    measurable: true,
    suffix: '秒',
    items: [
      {
        name: 'sprintRunSeconds',
        suffix: '秒',
        min: 5,
        max: 60,
        rules: [
          {
            type: 'number',
            min: 5,
            max: 60,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 5,
              max: 60,
            }),
          },
          getOneDecimalPlaceRule(t),
        ],
      },
    ],
  },
  {
    key: 'standingJump',
    tab: isUsingJpRuby ? (
      <span>
        <ruby>
          立<Rt className="text-10px">た</Rt>
        </ruby>
        ち
        <ruby>
          幅跳<Rt className="text-10px">はばと</Rt>び
        </ruby>
      </span>
    ) : (
      t('立ち幅とび')
    ),
    tab_width: isUsingJp ? 'w-24' : 'w-48',
    measurable: true,
    suffix: 'cm',
    items: [
      {
        label: t('（1回目）'),
        name: 'standingJump1',
        suffix: 'cm',
        min: 1,
        max: 399,
        rules: [
          {
            type: 'number',
            min: 1,
            max: 399,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 1,
              max: 399,
            }),
          },
          integerRule(t),
        ],
      },
      {
        label: t('（2回目）'),
        name: 'standingJump2',
        suffix: 'cm',
        min: 1,
        max: 399,
        rules: [
          {
            type: 'number',
            min: 1,
            max: 399,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 1,
              max: 399,
            }),
          },
          integerRule(t),
        ],
      },
    ],
  },
  {
    key: 'handballThrow',
    tab: isUsingJpRuby ? (
      <span>
        ボール
        <ruby>
          投<Rt className="text-10px">な</Rt>
        </ruby>
        げ
      </span>
    ) : (
      t('ボール投げ')
    ),
    tab_width: 'w-24',
    measurable: true,
    suffix: 'm',
    items: [
      {
        label: t('（1回目）'),
        name: 'handballThrow1',
        suffix: 'm',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 99,
            }),
          },
          integerRule(t),
        ],
      },
      {
        label: t('（2回目）'),
        name: 'handballThrow2',
        suffix: 'm',
        min: 0,
        max: 99,
        rules: [
          {
            type: 'number',
            min: 0,
            max: 99,
            message: t('{{min}}から{{max}}の数字を入力してください', {
              min: 0,
              max: 99,
            }),
          },
          integerRule(t),
        ],
      },
    ],
  },
]

export const getNoEnduranceRunInputListEle = (
  t: TFunction<'translation', undefined>,
  isUsingJpRuby: boolean,
  isUsingJp: boolean,
) =>
  getInputListEle(t, isUsingJpRuby, isUsingJp).filter(
    (input) => input.key !== 'enduranceRun',
  )
