export const CaretUpIcon = (
  <svg
    width="22"
    height="6"
    viewBox="0 0 22 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 0L21.3923 6H0.607696L11 0Z" fill="#FE0000" />
  </svg>
)
