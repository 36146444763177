import { Row } from 'antd'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { AuthContext } from '~/alpha/core'
import isJapanese from '~/utils/isJapanese'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'

const ViewPage = () => {
  const { t, i18n } = useTranslation()

  const auth = useContext(AuthContext)
  const isLevelB = auth.schoolLevel === 'B'
  const history = useHistory()

  const isUsingJpRuby = isLevelB && isJapanese(i18n)

  const cardItems = [
    {
      title: isUsingJpRuby ? (
        <span>
          <ruby>
            <span>今年</span>
            <rt className="font-normal text-14-hina">ことし</rt>
          </ruby>
          の
          <ruby>
            <span>結果</span>
            <rt className="font-normal text-14-hina">けっか</rt>
          </ruby>
        </span>
      ) : (
        t('今年の結果')
      ),
      url: 'result',
      image: '/images/year_result.png',
      level: 1,
      color: '#D3ECF3',
    },
    {
      title: isUsingJpRuby ? (
        <span>
          <ruby>
            <span>過去</span>
            <rt className="font-normal text-14-hina">かこ</rt>
          </ruby>
          の
          <ruby>
            <span>結果</span>
            <rt className="font-normal text-14-hina">けっか</rt>
          </ruby>
        </span>
      ) : (
        t('過去の結果')
      ),
      url: 'past_result',
      image: '/images/part_result.png',
      level: 2,
      color: '#D3ECF3',
    },
    {
      title: isUsingJpRuby ? (
        <span>
          アンケートの
          <ruby>
            <span>結果</span>
            <rt className="font-normal text-14-hina">けっか</rt>
          </ruby>
        </span>
      ) : (
        t('アンケートの結果')
      ),
      url: 'questionnaire_result',
      image: '/images/question_result.png',
      level: 3,
      color: '#D3ECF3',
    },
  ]

  const Card = (cardItem, idx) => (
    <div
      key={idx}
      className="w-56 h-96 pt-16 rounded-10px cursor-pointer"
      style={{ background: cardItem.color }}
      onClick={() => history.push(`/${cardItem.url}`)}
    >
      <Row
        justify="center"
        align="bottom"
        className="text-2xl font-black h-11 px-4"
      >
        <span>{cardItem.title}</span>
      </Row>

      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 246 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain' }}
        />
      </Row>
    </div>
  )

  const CardMobile = (cardItem, idx) => (
    <div
      key={idx}
      className="pt-6 mb-6 pb-3 rounded-10px cursor-pointer"
      style={{ background: '#D3ECF3', width: '100%', height: 290 }}
      onClick={() => history.push(`/${cardItem.url}`)}
    >
      <Row justify="center" align="bottom" className="text-2xl font-black h-11">
        <span>{cardItem.title}</span>
      </Row>
      <Row
        justify="center"
        className="w-full items-end"
        style={{ height: 160 }}
      >
        <img
          src={cardItem.image}
          alt={cardItem.title}
          style={{ objectFit: 'contain', height: 160 }}
        />
      </Row>
    </div>
  )

  return (
    <Dashboard
      selectedMenu={TabIndex.View}
      navbar={<div className="theme-nav-title">{t('みる')}</div>}
    >
      <div className="show-pc">
        <Row justify="center" align="middle" className="space-x-4 mt-12 p-3">
          {cardItems.map((cardItem, idx) => Card(cardItem, idx))}
        </Row>
      </div>

      <div className="show-sp">
        <Row
          justify="center"
          align="middle"
          className="pt-6 m-auto"
          style={{ width: 'calc(100vw - 30px)' }}
        >
          {cardItems.map((cardItem, idx) => CardMobile(cardItem, idx))}
        </Row>
      </div>
    </Dashboard>
  )
}

export default ViewPage
