export enum TabIndex {
  Movie = 1,
  Tests,
  Questionnaire,
  View,
  Account,
  NoFocus = 8,
}

// export type DashboardNavGraphItem = {
//   key: string
//   title: string | JSX.Element
//   tabIndex: number | undefined
//   subIndex: number | undefined
// }

// export const dashboardNavGraphItems: DashboardNavGraphItem[] = [
//   {
//     key: 'home',
//     title: 'ホーム',
//     tabIndex: 1,
//     subIndex: undefined,
//   },
// {
//   key: 'tests',
//   title: 'テスト',
//   tabIndex: 2,
//   subIndex: undefined,
// },
// {
//   key: 'advise',
//   title: (
//     <>
//       <ruby>
//         動画<Rt className="text-10px font-black">どうが</Rt>を
//       </ruby>
//       <ruby>
//         見<Rt className="text-10px font-black">み</Rt>る
//       </ruby>
//     </>
//   ),
//   tabIndex: 2,
//   subIndex: undefined,
// },
// {
//   key: 'my_page',
//   title: 'マイページ',
//   tabIndex: 5,
//   subIndex: undefined,
// },
// {
//   key: 'movie',
//   title: '動画',
//   tabIndex: 1,
//   subIndex: undefined,
// },
// {
//   key: 'questionnaire',
//   title: 'アンケート',
//   tabIndex: 3,
//   subIndex: undefined,
// },
// {
//   key: 'questionnaire_result',
//   title: 'アンケートの結果',
//   tabIndex: undefined,
//   subIndex: 4,
// },
// {
//   key: 'test',
//   title: '本番',
//   tabIndex: undefined,
//   subIndex: 2,
// },
// {
//   key: 'result',
//   title: (
//     <>
//       <ruby>
//         測定結果<Rt className="text-10px font-black">そくていけっか</Rt>をみる
//       </ruby>
//     </>
//   ),
//   tabIndex: 4,
//   subIndex: undefined,
// },
// {
//   key: 'logout',
//   title: 'ログアウト',
//   tabIndex: 8,
//   subIndex: undefined,
// },
// {
//   key: 'register',
//   title: 'ユーザー情報登録',
//   tabIndex: undefined,
//   subIndex: undefined,
// },
// {
//   key: '',
//   title: 'サインイン',
//   tabIndex: undefined,
//   subIndex: undefined,
// },
// {
//   key: 'training',
//   title: 'トレーニング',
//   tabIndex: undefined,
//   subIndex: 2,
// },
// {
//   key: 'advise',
//   title: 'お手本動画',
//   tabIndex: undefined,
//   subIndex: 1,
// },
// {
//   key: 'videos',
//   title: '自分にあった動画',
//   tabIndex: undefined,
//   subIndex: 1,
// },
// {
//   key: 'view',
//   title: 'みる',
//   tabIndex: 4,
//   subIndex: undefined,
// },
// {
//   key: 'past_result',
//   title: '過去の結果',
//   tabIndex: undefined,
//   subIndex: 4,
// },
// ]
