import { ReactNode } from 'react'
import { useAlphaStore } from '~/context'

export const Rt: React.FC<any> = ({
  children,
  className,
}: {
  children: ReactNode
  className?: any
}) => {
  const { schoolCategoryCode } = useAlphaStore()
  return (
    schoolCategoryCode === 'B1' && <rt className={className}>{children}</rt>
  )
}
