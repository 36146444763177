import { CheckOutlined } from '@ant-design/icons'

interface CompletionLayoutProps {
  message: JSX.Element | string
  button?: JSX.Element
}

export default function CompletionLayout({
  message,
  button = undefined,
}: CompletionLayoutProps) {
  return (
    <div
      className="flex items-center flex-col justify-center p-10"
      style={{ height: '100%' }}
    >
      <div className="relative flex items-center justify-center w-40 h-40 rounded-full ring-8 ring-gray-200 ring-offset-8 ring-offset-gray-200 check-outlined-wrapper">
        <img
          className="absolute"
          style={{ top: -107 }}
          src="/images/AlphaWhale.png"
          alt=""
        />
        <CheckOutlined className="check-outlined-icon" />
      </div>
      <div className="mt-8 text-base font-bold">{message}</div>
      {button && <div className="mt-7">{button}</div>}
    </div>
  )
}
