import { studentGradeListTitle } from './../utils/constants'

/**
 * For a student.
 */
export interface TestResultByGradeRow {
  _id: (null | string)[]
  state: boolean[]
  title: typeof studentGradeListTitle
  height: (null | number)[]
  weight: (null | number)[]
  grip: (null | number)[]
  sitUps: (null | number)[]
  bending: (null | number)[]
  sideJump: (null | number)[]
  shuttleRun: (null | number)[]
  enduranceRun: null[]
  sprintRun: (null | number)[]
  standingJump: (null | number)[]
  handballThrow: (null | number)[]
}

export enum TestResultShowingMode {
  table = 1,
  graph = 2,
}
