import { FunctionComponent } from 'react'
import { FallbackProps } from 'react-error-boundary'

const ErrorFallback: FunctionComponent<FallbackProps> = ({ error }) => {
  return (
    <div className="p-16 border-red-400 border-solid border-8 text-rose-900">
      <p className="text-2xl font-bold border-8">エラーが発生しました。</p>

      <p className="font-bold">メッセージ</p>
      <pre>{error.message}</pre>

      <p className="font-bold">詳細</p>
      <pre>{error.stack}</pre>
    </div>
  )
}

export default ErrorFallback
