import { message } from 'antd'
import axios from 'axios'
import { AUTH_TOKEN_KEY } from '~/alpha/core'
import i18n from '~/i18n'
import { clearLocalStorage } from './storages'

export const initializeInterceptors = () => {
  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response
    },
    (error) => {
      const status = error.response?.status

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      let mess: string | undefined

      if (status === 429) {
        // Too many requests. Please wait a moment and try again.
        mess = i18n.t(
          'リクエストが多すぎます。しばらく待ってから、もう一度お試しください。',
        )
      } else if (status === 409) {
        mess = i18n.t(
          '要求は現在の状態と競合しています。リソースの状態を確認して、もう一度試してください。',
        )
      } else {
        mess = `${i18n.t('エラーが発生しました。')} [API - status: ${
          status ?? '-'
        } ]`
      }

      message.error(mess, 8)

      // Unauthorized error
      if (status === 401 || status === 403) {
        clearLocalStorage()
        delete axios.defaults.headers.common[AUTH_TOKEN_KEY]

        // keep this log
        console.error(
          'When response status is unauthorized (401), logout at axios.interceptors!',
          error.response,
        )

        const resData = error.response.data
        if (resData?.error === 'TokenExpiredError') {
          console.error('TokenExpiredError at:', resData.expiredAt)
        }

        setTimeout(() => {
          window.location.reload()
        }, 5000)
      }

      console.error('At axios.interceptors, error:', error)

      return Promise.reject(error)
    },
  )
}
