import { TFunction } from 'i18next'
import { isNumVal } from '~/utils/number'
import { ITestResult } from '~/utils/types/api/alpha/test-result'
import { InputConfig } from './test-input/utils/input'

export const showEnduranceRunMinSec = (
  lastYearStudent: ITestResult,
  input: InputConfig,
  t: TFunction<'translation', undefined>,
) => {
  const obj = lastYearStudent[input.key]

  return `${
    obj && (isNumVal(obj.runningTimeSeconds) || isNumVal(obj.runningTime))
      ? `${obj.runningTime ?? '0'}${t('分')} ${
          obj.runningTimeSeconds ?? '0'
        }${t('秒')}`
      : `-${t('分')} -${t('秒')}`
  }`
}
