import { StudentTestResultResponse } from '~/types/api/alpha/student/test-result'
import { SchoolCategoryCode } from '~/utils/constants'
import { studentGradeListTitle } from './constants'
import { findGradeIndex } from './find'

export const convertKey = (key: string) => {
  switch (key) {
    case 'grip':
      return 'gripValue'
    case 'sitUps':
      return 'sitUpsValue'
    case 'bending':
      return 'bendingValue'
    case 'sideJump':
      return 'sideJumpValue'
    case 'shuttleRun':
      return 'shuttleRunValue'
    case 'sprintRun':
      return 'sprintRunValue'
    case 'standingJump':
      return 'standingJumpValue'
    case 'handballThrow':
      return 'handballThrowValue'
    case 'enduranceRun':
      return 'enduranceRunValue'

    default:
      break
  }

  return key
}

export const currentTestsImage = {
  height: '/images/height_icon.png',
  weight: '/images/weight_icon.png',
  grip: '/images/grip_icon.png',
  sitUps: '/images/sit_up_icon.png',
  bending: '/images/bending_icon.png',
  sideJump: '/images/side_jump_icon.png',
  shuttleRun: '/images/shuttle_run_icon.png',
  enduranceRun: '/images/shuttle_run_icon.png',
  sprintRun: '/images/sprint_run_icon.png',
  standingJump: '/images/standing_jump_icon.png',
  handballThrow: '/images/handball_throw_icon.png',
} as const

export const convertData = (
  inputTestResult: StudentTestResultResponse,
  nowData: { schoolCategory: SchoolCategoryCode; schoolGrade: number },
  isEdit: boolean,
) => {
  const defaultData: any = {
    _id: Array.from(Array(12), () => null),
    state: Array.from(Array(12), () => false),
    title: studentGradeListTitle,
    height: Array.from(Array(12), () => null),
    weight: Array.from(Array(12), () => null),
    grip: Array.from(Array(12), () => null),
    sitUps: Array.from(Array(12), () => null),
    bending: Array.from(Array(12), () => null),
    sideJump: Array.from(Array(12), () => null),
    shuttleRun: Array.from(Array(12), () => null),
    enduranceRun: Array.from(Array(12), () => null),
    sprintRun: Array.from(Array(12), () => null),
    standingJump: Array.from(Array(12), () => null),
    handballThrow: Array.from(Array(12), () => null),
  }

  const yearsDataBefore = inputTestResult?.dataBefore ?? []

  for (const item of yearsDataBefore) {
    const index = findGradeIndex(item)

    if (index >= 0) {
      defaultData._id[index] = item?._id ?? null
      defaultData.height[index] = item?.sizeTest?.height ?? null
      defaultData.weight[index] = item?.sizeTest?.weight ?? null
      defaultData.grip[index] = (item?.grip?.value || item?.gripValue) ?? null
      defaultData.sitUps[index] =
        (item?.sitUps?.value || item?.sitUpsValue) ?? null
      defaultData.bending[index] =
        (item?.bending?.value || item?.bendingValue) ?? null
      defaultData.sideJump[index] =
        (item?.sideJump?.value || item?.sideJumpValue) ?? null
      defaultData.shuttleRun[index] =
        (item?.shuttleRun?.value || item?.shuttleRunValue) ?? null
      defaultData.enduranceRun[index] =
        (item?.enduranceRun?.value || item?.enduranceRunValue) ?? null
      defaultData.sprintRun[index] =
        (item?.sprintRun?.value || item?.sprintRunValue) ?? null
      defaultData.standingJump[index] =
        (item?.standingJump?.value || item?.standingJumpValue) ?? null
      defaultData.handballThrow[index] =
        (item?.handballThrow?.value || item?.handballThrowValue) ?? null
    }
  }

  let index = -1

  // disabled edit current year
  if (inputTestResult?.dataNow) {
    const dataNow = inputTestResult.dataNow
    index = findGradeIndex(dataNow)

    if (index < 0) {
      index = findGradeIndex(nowData)
    }

    if (index >= 0) {
      defaultData._id[index] = dataNow?._id ?? null
      defaultData.height[index] = dataNow?.sizeTest?.height ?? null
      defaultData.weight[index] = dataNow?.sizeTest?.weight ?? null
      defaultData.grip[index] =
        (dataNow?.grip?.value || dataNow?.gripValue) ?? null
      defaultData.sitUps[index] =
        (dataNow?.sitUps?.value || dataNow?.sitUpsValue) ?? null
      defaultData.bending[index] =
        (dataNow?.bending?.value || dataNow?.bendingValue) ?? null
      defaultData.sideJump[index] =
        (dataNow?.sideJump?.value || dataNow?.sideJumpValue) ?? null
      defaultData.shuttleRun[index] =
        (dataNow?.shuttleRun?.value || dataNow?.shuttleRunValue) ?? null
      defaultData.enduranceRun[index] =
        (dataNow?.enduranceRun?.value || dataNow?.enduranceRunValue) ?? null
      defaultData.sprintRun[index] =
        (dataNow?.sprintRun?.value || dataNow?.sprintRunValue) ?? null
      defaultData.standingJump[index] =
        (dataNow?.standingJump?.value || dataNow?.standingJumpValue) ?? null
      defaultData.handballThrow[index] =
        (dataNow?.handballThrow?.value || dataNow?.handballThrowValue) ?? null

      // active all tab old result
      for (let i = 0; i < index; i++) {
        defaultData.state[i] = !!isEdit
      }
    }
  } else {
    index = findGradeIndex(nowData)
    // active all tab old result
    if (index >= 0) {
      for (let i = 0; i < index; i++) {
        defaultData.state[i] = !!isEdit
      }
    }
  }

  return defaultData
}
