export const CaretDownIcon = (
  <svg
    width="22"
    height="6"
    viewBox="0 0 22 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6L0.607697 -1.9576e-06L21.3923 -1.40549e-07L11 6Z"
      fill="#0097FF"
    />
  </svg>
)
