import axios from 'axios'
import { createContext, useEffect, useState } from 'react'

type AuthValue = {
  currentUser: boolean
  isAuthChecked: boolean
  isTokenChecked: boolean
  isRegistered: boolean
  schoolLevel: string
  setSchoolLevel: (val: string) => void
  setAccessToken: (x: string) => void
}
const initialState: AuthValue = {
  currentUser: true,
  isAuthChecked: true,
  isTokenChecked: true,
  isRegistered: true,
  schoolLevel: '',
  setSchoolLevel: (x: string) => {},
  setAccessToken: (x: string) => {},
}

export const AuthContext = createContext<AuthValue>(initialState)

export const LOCAL_STORAGE_EMAIL_FOR_SIGN_IN_KEY = 'sign-in-email'
export const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'x-auth-token-2024-04'
export const AUTH_TOKEN_KEY = 'x-auth-token'
export const SCHOOL_LEVEL = 'school-level'

export const login = async () => {
  const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  // axios.interceptors.request.use(
  //   function (config) {
  //     config.headers.common = {
  //       ...(config.headers.common as any),
  //       ...(authToken
  //         ? { [AUTH_TOKEN_KEY]: `Bearer ${authToken}` }
  //         : {}),
  //     }
  //     return config
  //   },
  //   function (error) {
  //     return Promise.reject(error)
  //   },
  // )
  axios.defaults.headers.common[AUTH_TOKEN_KEY] = authToken
    ? `Bearer ${authToken}`
    : ''
}

export const AuthProvider = ({ children }) => {
  const [isRegistered, setIsRegistered] = useState(true)
  const [schoolLevel, setSchoolLevel] = useState('') // B, C, D

  const setAccessToken = (token: string) => {
    window.localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, token)
    // axios.interceptors.request.use(
    //   function (config) {
    //     config.headers.common = {
    //       ...(config.headers.common as any),
    //       ...(token
    //         ? { [AUTH_TOKEN_KEY]: `Bearer ${token}` }
    //         : {}),
    //     }
    //     return config
    //   },
    //   function (error) {
    //     return Promise.reject(error)
    //   },
    // )
    axios.defaults.headers.common[AUTH_TOKEN_KEY] = token
      ? `Bearer ${token}`
      : ''

    setIsRegistered(true)
  }

  const onSetSchoolLevel = (level: string) => {
    if (schoolLevel !== level) {
      window.localStorage.setItem(SCHOOL_LEVEL, level)
      setSchoolLevel(level)
    }
  }

  useEffect(() => {
    const authToken = window.localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
    const level = window.localStorage.getItem(SCHOOL_LEVEL)
    setSchoolLevel(level || '')

    if (authToken) {
      // axios.interceptors.request.use(
      //   function (config) {
      //     config.headers.common = {
      //       ...(config.headers.common as any),
      //       ...(authToken
      //         ? { [AUTH_TOKEN_KEY]: `Bearer ${authToken}` }
      //         : {}),
      //     }
      //     return config
      //   },
      //   function (error) {
      //     return Promise.reject(error)
      //   },
      // )
      axios.defaults.headers.common[AUTH_TOKEN_KEY] = authToken
        ? `Bearer ${authToken}`
        : ''
    }
  }, [])

  const value: AuthValue = {
    currentUser: true,
    isAuthChecked: true,
    isTokenChecked: true,
    isRegistered,
    schoolLevel,
    setSchoolLevel: onSetSchoolLevel,
    setAccessToken,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
