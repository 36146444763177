import { StudentQuestionExtraText } from '../types'
import { allQuestions2024 } from './all-questions-2024'

const customizedExtra25SchoolNames = [
  "宇都宮市立中央小学校",
  "宇都宮市立東小学校",
  "宇都宮市立西小学校",
  "宇都宮市立簗瀬小学校",
  "宇都宮市立西原小学校",
  "宇都宮市立戸祭小学校",
  "宇都宮市立今泉小学校",
  "宇都宮市立昭和小学校",
  "宇都宮市立陽南小学校",
  "宇都宮市立桜小学校",
  "宇都宮市立錦小学校",
  "宇都宮市立細谷小学校",
  "宇都宮市立峰小学校",
  "宇都宮市立富士見小学校",
  "宇都宮市立泉が丘小学校",
  "宇都宮市立平石中央小学校",
  "宇都宮市立平石北小学校",
  "宇都宮市立清原中央小学校",
  "宇都宮市立清原南小学校",
  "宇都宮市立清原北小学校",
  "宇都宮市立清原東小学校",
  "宇都宮市立横川中央小学校",
  "宇都宮市立横川東小学校",
  "宇都宮市立横川西小学校",
  "宇都宮市立瑞穂野北小学校",
  "宇都宮市立瑞穂野南小学校",
  "宇都宮市立豊郷中央小学校",
  "宇都宮市立豊郷南小学校",
  "宇都宮市立豊郷北小学校",
  "宇都宮市立国本中央小学校",
  "宇都宮市立国本西小学校",
  "宇都宮市立城山中央小学校",
  "宇都宮市立明保小学校",
  "宇都宮市立城山西小学校",
  "宇都宮市立城山東小学校",
  "宇都宮市立富屋小学校",
  "宇都宮市立篠井小学校",
  "宇都宮市立姿川中央小学校",
  "宇都宮市立姿川第一小学校",
  "宇都宮市立姿川第二小学校",
  "宇都宮市立雀宮中央小学校",
  "宇都宮市立雀宮東小学校",
  "宇都宮市立雀宮南小学校",
  "宇都宮市立緑が丘小学校",
  "宇都宮市立宮の原小学校",
  "宇都宮市立御幸小学校",
  "宇都宮市立宝木小学校",
  "宇都宮市立城東小学校",
  "宇都宮市立陽東小学校",
  "宇都宮市立海道小学校",
  "宇都宮市立石井小学校",
  "宇都宮市立御幸が原小学校",
  "宇都宮市立五代小学校",
  "宇都宮市立陽光小学校",
  "宇都宮市立瑞穂台小学校",
  "宇都宮市立晃宝小学校",
  "宇都宮市立新田小学校",
  "宇都宮市立西が岡小学校",
  "宇都宮市立上戸祭小学校",
  "宇都宮市立上河内東小学校",
  "宇都宮市立上河内西小学校",
  "宇都宮市立上河内中央小学校",
  "宇都宮市立岡本小学校",
  "宇都宮市立白沢小学校",
  "宇都宮市立田原小学校",
  "宇都宮市立岡本西小学校",
  "宇都宮市立岡本北小学校",
  "宇都宮市立田原西小学校",
  "宇都宮市立ゆいの杜小学校",
  "宇都宮市立一条中学校",
  "宇都宮市立陽北中学校",
  "宇都宮市立旭中学校",
  "宇都宮市立陽南中学校",
  "宇都宮市立陽西中学校",
  "宇都宮市立星が丘中学校",
  "宇都宮市立陽東中学校",
  "宇都宮市立泉が丘中学校",
  "宇都宮市立宮の原中学校",
  "宇都宮市立清原中学校",
  "宇都宮市立横川中学校",
  "宇都宮市立瑞穂野中学校",
  "宇都宮市立豊郷中学校",
  "宇都宮市立国本中学校",
  "宇都宮市立城山中学校",
  "宇都宮市立姿川中学校",
  "宇都宮市立雀宮中学校",
  "宇都宮市立晃陽中学校",
  "宇都宮市立鬼怒中学校",
  "宇都宮市立宝木中学校",
  "宇都宮市立若松原中学校",
  "宇都宮市立上河内中学校",
  "宇都宮市立古里中学校",
  "宇都宮市立田原中学校",
  "宇都宮市立河内中学校",
  '福井県ALPHAデモ学校（小学校）',
  '山梨県ALPHAデモ学校（小学校）'
]

export const getExtraQuestion = (schoolName: string, question: {
  extra?: Record<string, StudentQuestionExtraText>,
  extra25?: Record<string, StudentQuestionExtraText>
}) => {
  return customizedExtra25SchoolNames.includes(schoolName)
    ? question.extra25
    : question.extra
}

export const allQuestions = allQuestions2024
