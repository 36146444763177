import { Button, List, Row } from 'antd'
import _ from 'lodash'
import { JSXElementConstructor, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import type { QuestionnaireV3Result } from '@alpha/core'
import { useAlphaStore } from '~/context'
import { useStudentQuestionnaireResultApiQuery } from '~/lib/hooks/hooks'
import isJapanese from '~/utils/isJapanese'
import { getCurrentPrefectureQuestions } from '~/utils/questions'
import { allQuestions, getExtraQuestion } from '~/utils/questions/all-questions'
import { AnswerType } from '~/utils/types'
import type { StudentMeSchool } from '~/utils/types/api/students'
import { Rt } from '../common/Rt'
import { Dashboard } from '../layout/Dashboard'
import { TabIndex } from '../navGraph/DashboardNavGraphItem'
import type { StudentProcessingQuestion } from './question/utils/types'

const formatDataQuestion = (
  text: string | ReactElement<any, string | JSXElementConstructor<any>>,
  kanji: any[],
) => {
  const data = []
  let textCheck = text
  kanji.forEach((value: { kanji: any }, index: number) => {
    if (textCheck) {
      const arrayText = textCheck.split(value.kanji)
      if (arrayText.length) {
        switch (arrayText.length) {
          case 1:
            if (arrayText[0] === '') {
              data.push({ ...value, type: 'kanji' })
            } else {
              data.push({ kanji: arrayText[0], type: 'text' })
            }
            textCheck = ''
            break
          case 2:
            if (arrayText[0] === '') {
              data.push({ ...value, type: 'kanji' })
              textCheck = arrayText[1]
            } else {
              data.push({ kanji: arrayText[0], type: 'text' })
              data.push({ ...value, type: 'kanji' })
              textCheck = arrayText[1]
            }
            break
          default:
            if (arrayText[0] === '') {
              data.push({ ...value, type: 'kanji' })
              textCheck = textCheck.replace(value.kanji, '')
            } else {
              data.push({ kanji: arrayText[0], type: 'text' })
              data.push({ ...value, type: 'kanji' })
              textCheck = textCheck.replace(arrayText[0] + value.kanji, '')
            }
            break
        }
      }
      if (kanji.length === index + 1 && textCheck) {
        data.push({ kanji: textCheck, type: 'text' })
      }
    }
  })
  return data
}

const COLORS = [
  '#036EB8',
  '#F97A3C',
  '#808080',
  '#FFBC31',
  '#91C3E5',
  '#6AAB4E',
] as const

/**
 * Path: /questionnaire_result
 */
const QuestionnaireResultPage = () => {
  const { t, i18n } = useTranslation()
  const history = useHistory()

  const [questionData, setQuestionData] = useState<StudentProcessingQuestion[]>(
    [],
  )
  const [answeredQuestions, setAnsweredQuestions] =
    useState<QuestionnaireV3Result | null>(null)
  const [answeredGraph, setAnsweredGraph] = useState(null)
  const [isElementarySchool, setIsElementarySchool] = useState(false)
  const [school, setSchool] = useState<StudentMeSchool | null>(null)

  const { data: resultData } = useStudentQuestionnaireResultApiQuery()
  const { me: meApiData } = useAlphaStore()

  const isUsingJpRuby = isElementarySchool && isJapanese(i18n)

  useEffect(() => {
    if (!resultData || !meApiData) return

    const school = meApiData.school
    const questionnaireV3 = resultData?.data?.survey
    const _isElementarySchool = school?.schoolCategoryCode === 'B1'
    const _answeredQuestions = questionnaireV3 ?? {}
    setAnsweredQuestions(_answeredQuestions)
    setAnsweredGraph(resultData?.data?.graph)
    setSchool(school)
    setIsElementarySchool(_isElementarySchool)
  }, [resultData, meApiData])

  const [listQuestion, setListQuestion] = useState([])

  // Get questions
  useEffect(() => {
    if (school?.prefectureCode === undefined) return

    const questionList = getCurrentPrefectureQuestions(
      school.prefectureCode,
      school._id,
      isElementarySchool,
    )

    setListQuestion(questionList)
  }, [school, isElementarySchool])

  useEffect(() => {
    if (listQuestion.length === 0) return

    /**
     * To update this prefecture question list.
     */
    const prefectureQuestionObject = {}

    for (const question of allQuestions) {
      if (listQuestion.includes(question.id)) {
        // question is modified later, so copy question information to new object
        prefectureQuestionObject[question.id] = _.cloneDeep(question)
      }
    }

    /**
     * To reorder questions
     */
    let prefectureQuestionDetails: StudentProcessingQuestion[] = []

    for (const allQuestionId of listQuestion) {
      prefectureQuestionDetails.push(prefectureQuestionObject[allQuestionId])
    }

    prefectureQuestionDetails = prefectureQuestionDetails.map(
      (value, index) => {
        value._id = value.id as number
        value.id = `q${index + 1}`

        const question = `Q${index + 1} : ${value.question}`

        if (!isElementarySchool) {
          const options = {}

          Object.keys(value.options).forEach((option) => {
            options[value.options[option]] = option
          })

          value.options = options
          value.question = question

          return value
        }

        if (school.prefectureCode === 8 && index === 7) {
          value.question =
            '1日にどのくらいテレビを見ますか（テレビゲームを含みます）'
          value.kanjiQuestion = [
            { kanji: '日', hina: 'にち' },
            { kanji: '見', hina: 'み' },
            { kanji: '含', hina: 'ふく' },
          ]
        }

        if (value.kanjiQuestion.length) {
          const questionKan = formatDataQuestion(
            value.question,
            value.kanjiQuestion,
          )

          value.question = (
            <>
              {`Q${index + 1} : `}
              {questionKan.map((value, index) => {
                if (value.type === 'kanji') {
                  return (
                    <ruby key={index} className="font-black">
                      {value.kanji}
                      <rt className="text-xxs font-normal">{value.hina}</rt>
                    </ruby>
                  )
                }
                // if (value.type === 'text') {
                return value.kanji
                // }
              })}
            </>
          )
        } else {
          value.question = `Q${index + 1} : ${value.question}`
        }
        if (value.kanjiOption) {
          const optionsKan = {}

          Object.keys(value.options).forEach((option) => {
            if (value.kanjiOption[value.options[option]].length) {
              const optionKan = formatDataQuestion(
                option,
                value.kanjiOption[value.options[option]],
              )
              optionsKan[value.options[option]] = (
                <>
                  {optionKan.map((value, index) => {
                    if (value.type === 'kanji') {
                      return (
                        <ruby key={index} className="font-black">
                          {value.kanji}
                          <rt className="text-xxs font-normal">{value.hina}</rt>
                        </ruby>
                      )
                    }
                    // if (value.type === 'text') {
                    return value.kanji
                    // }
                  })}
                </>
              )
            } else {
              optionsKan[value.options[option]] = <>{option}</>
            }
          })
          value.options = optionsKan
        }

        return value
      },
    )

    setQuestionData(prefectureQuestionDetails)
    setSchool(school)
  }, [listQuestion, isElementarySchool, school])

  const changeActive = (question: StudentProcessingQuestion) => {
    const graph = answeredGraph?.find(
      (graph: { questionnaireV3: string | number }) =>
        graph.questionnaireV3 === question?.id,
    )

    const dataGraph = []

    Object.keys(question?.options).map((option, index) => {
      const value = graph?.answers?.length
        ? graph?.answers?.find(
            (graph: { answer: number }) => graph.answer === Number(option),
          )
        : ''

      dataGraph.push({
        name: question?.options[option],
        value: value ? value.count : 0,
        color: COLORS[index % 6],
      })
    })
  }

  useEffect(() => {
    if (answeredQuestions && questionData.length) {
      changeActive(questionData[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionData, answeredQuestions])

  const renderQuestion = (_question: StudentProcessingQuestion) => {
    let chosenAnswer = answeredQuestions[_question.id]
    if (!_question.answerType || [AnswerType.options, AnswerType.multipleSelect].includes(_question.answerType)) {
      const extraQuestion = getExtraQuestion(school.schoolName, _question)
      if (_question._id === 45) {
        chosenAnswer = extraQuestion[chosenAnswer]
          ? extraQuestion[chosenAnswer][
              !isElementarySchool ? 'name' : 'hiragana'
            ]
          : ''
      } else {
        chosenAnswer = _question.answerType === AnswerType.multipleSelect
          ? String(chosenAnswer).split(',').map(a => <><li>{_question?.options?.[a]}</li></>)
          : _question?.options?.[chosenAnswer]
      }
    }

    return (
      <List.Item
        style={{
          borderStyle: 'none',
        }}
      >
        <>
          <div className="font-black leading-7">{_question.question}</div>
          <div className="show-answer-item">
            {chosenAnswer !== undefined && chosenAnswer !== null ? (
              <span className="pl-10">
                {chosenAnswer}
                {_question.answerUnit ?? ''}
              </span>
            ) : (
              <span className="pl-10" />
            )}
          </div>
        </>
      </List.Item>
    )
  }

  return (
    <Dashboard
      selectedMenu={TabIndex.View}
      navbar={<div className="theme-nav-title">{t('アンケートの結果')}</div>}
    >
      <div className="w-full flex flex-col items-center space-y-4 mt-10 form-question px-16 py-4">
        {answeredQuestions ? (
          <>
            <Row
              align="middle"
              justify="center"
              className="mb-2 form-question-content"
              style={{ width: 1000 }}
            >
              <span className="text-lg font-black">
                {t('アンケートをみる')}
              </span>
            </Row>
            <Row
              align="middle"
              justify="center"
              className="form-question-content border-3 border-primary rounded-xl p-6"
              style={{ width: 1000 }}
            >
              <div className="show-pc">
                <List
                  className="flex flex-col items-center space-y-1"
                  itemLayout="vertical"
                  grid={{ column: 2, gutter: 30 }}
                  dataSource={questionData}
                  renderItem={(question) => renderQuestion(question)}
                  bordered={false}
                  pagination={false}
                />
              </div>
              <div className="show-sp">
                <List
                  className="flex flex-col items-center space-y-1"
                  itemLayout="vertical"
                  grid={{ column: 1, gutter: 30 }}
                  dataSource={questionData}
                  renderItem={(question) => renderQuestion(question)}
                  bordered={false}
                  pagination={false}
                />
              </div>
            </Row>
            <Row align="middle" justify="center">
              <Button
                className="w-60"
                type="primary"
                size="large"
                onClick={() => history.push('/questionnaire')}
              >
                {isUsingJpRuby ? (
                  <ruby>
                    編集
                    <Rt className="text-tiny font-black">へんしゅう</Rt>
                    する
                  </ruby>
                ) : (
                  <ruby>{t('編集する')}</ruby>
                )}
              </Button>
            </Row>
          </>
        ) : (
          <span className="text-2xl font-black">{t('回答なし')}</span>
        )}
      </div>
    </Dashboard>
  )
}

export default QuestionnaireResultPage
