import { Rule } from 'antd/lib/form'
import { TFunction } from 'i18next'

export const currentTestsImage = {
  height: '/images/height_icon.png',
  weight: '/images/weight_icon.png',
  grip: '/images/grip_icon.png',
  sitUps: '/images/sit_up_icon.png',
  bending: '/images/bending_icon.png',
  sideJump: '/images/side_jump_icon.png',
  shuttleRun: '/images/shuttle_run_icon.png',
  enduranceRun: '/images/shuttle_run_icon.png',
  sprintRun: '/images/sprint_run_icon.png',
  standingJump: '/images/standing_jump_icon.png',
  handballThrow: '/images/handball_throw_icon.png',
} as const

export const getOneDecimalPlaceRule = (
  t: TFunction<'translation', undefined>,
) => ({
  pattern: /^\d+(?:\.\d{1})?$/,
  message: t('小数点の入力は第1位までです'),
})

export const integerRule = (t: TFunction<'translation', undefined>) => ({
  pattern: /^\d+$/,
  message: t('小数点の入力はできません'),
})

export const weightRules = (t: TFunction<'translation', undefined>): Rule[] => [
  {
    type: 'number',
    min: 10,
    max: 150,
    message: t('{{min}}から{{max}}の数字を入力してください', {
      min: 10,
      max: 150,
    }),
  },
  getOneDecimalPlaceRule(t),
]

export const heightRules = (t: TFunction<'translation', undefined>): Rule[] => [
  {
    type: 'number',
    min: 80,
    max: 220,
    message: t('{{min}}から{{max}}の数字を入力してください', {
      min: 80,
      max: 220,
    }),
  },
  getOneDecimalPlaceRule(t),
]
