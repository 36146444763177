import { Modal } from 'antd'
import { useContext, useEffect, useState } from 'react'

import { AuthContext, useGradesLazyQuery } from '@alpha/core'
import { CloseOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import { useAlphaStore } from '~/context'
import {
  useLastYearResultQuery,
  useStudentTestsApiQuery,
} from '~/student-utils/api'
import { isHighOrJuniorHighSchool } from '~/utils'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import { Rt } from '../common/Rt'
import { WarnIcon } from '../icons/WarnIcon'
import { TestInputs } from '../inputs/TestInputs'
import { Dashboard } from '../layout/Dashboard'

/**
 * Path: /test
 */
const TestInputPage = () => {
  const { t, i18n } = useTranslation()

  const auth = useContext(AuthContext)
  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isJapanese(i18n)
  const isUsingJpRuby = isLevelB && isUsingJp

  const [isShowModal, setIsShowModal] = useState<boolean>(false)

  const { me: meData, isLoading: meDataLoading } = useAlphaStore()
  const { data: examsData, loading: examsDataLoading } =
    useStudentTestsApiQuery()
  const { data: lastYearResult, loading: lastYearResultLoading } =
    useLastYearResultQuery()

  const [getGrades] = useGradesLazyQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (!meData?.school) {
      return
    }

    getGrades({
      variables: {
        input: {
          schoolId: meData.school._id,
          testYear: currentYear,
        },
      },
    })

    setIsShowModal(!meData.school.isStudentInputActive)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meData])

  const [title] = useState(
    <div className="theme-nav-title">
      {isUsingJpRuby ? (
        <ruby>
          本番
          <rt className="font-normal text-10-hina">ほんばん</rt>
        </ruby>
      ) : (
        t('本番')
      )}
    </div>,
  )

  return (
    <Dashboard selectedMenu={2} navbar={title}>
      <div className="p-5 show-data-test">
        {!examsDataLoading &&
          !meDataLoading &&
          !lastYearResultLoading &&
          meData?.school &&
          examsData &&
          lastYearResult && (
            <TestInputs
              hasEnduranceRun={isHighOrJuniorHighSchool(
                meData.school.schoolCategoryCode,
              )}
              student={meData}
              isStudentInputActive={meData.school.isStudentInputActive}
              testResults={examsData}
              lastYearResult={lastYearResult}
            />
          )}

        <Modal
          visible={isShowModal}
          onCancel={() => setIsShowModal(false)}
          width={900}
          closeIcon={
            <CloseOutlined className="text-base" style={{ color: '#036EB8' }} />
          }
          footer={null}
          centered={true}
          bodyStyle={{ border: '3px solid #036EB8', borderRadius: 4 }}
        >
          <div className="p-10 flex flex-col justify-center items-center text-base font-black color-red-i">
            {isUsingJp ? (
              <>
                <div className="flex">
                  {WarnIcon}
                  <span>
                    ただいま
                    <ruby>
                      測定結果<Rt className="text-tiny">そくていけっか</Rt>
                    </ruby>
                    を
                    <ruby>
                      入力<Rt className="text-tiny">にゅうりょく</Rt>
                    </ruby>
                    できません。
                  </span>
                </div>

                <span>
                  <ruby>
                    設定<Rt className="text-tiny">せってい</Rt>
                  </ruby>
                  を
                  <ruby>
                    変更<Rt className="text-tiny">へんこう</Rt>
                  </ruby>
                  したい
                  <ruby>
                    場合<Rt className="text-tiny">ばあい</Rt>
                  </ruby>
                  は
                  <ruby>
                    先生<Rt className="text-tiny">せんせい</Rt>
                  </ruby>
                  にお
                  <ruby>
                    問<Rt className="text-tiny">と</Rt>
                  </ruby>
                  い
                  <ruby>
                    合<Rt className="text-tiny">あ</Rt>
                  </ruby>
                  わせください。
                </span>
              </>
            ) : (
              <>
                {WarnIcon}
                <div>{t('ただいま測定結果を入力できません。')}</div>
                {t('設定を変更したい場合は先生にお問い合わせください。')}
              </>
            )}
          </div>
        </Modal>
      </div>
    </Dashboard>
  )
}

export default TestInputPage
