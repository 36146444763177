import './RegisterUserPage.css'

import {
  AuthContext,
  QrCodeStudentQuery,
  useQrCodeStudentLazyQuery,
  useStudentLinkSchoolMutation,
} from '@alpha/core'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import { Button, Form, Input, Row, Select, message } from 'antd'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import QRReader from 'react-qr-reader'
import { useHistory } from 'react-router-dom'

import { handleQRReaderError } from '~/student-utils/logger'
import { getListData } from '~/student-utils/string'
import Api from '~/utils/api'
import { currentYear } from '~/utils/constants'
import isJapanese from '~/utils/isJapanese'
import CompletionLayout from '../layout/CompletionLayout'
import { LoadingSpin } from '../loading-spin'

let inputtedCode: string | null = null

const RegisterUserPage = () => {
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const auth = useContext(AuthContext)

  const isLevelB = auth.schoolLevel === 'B'
  const isUsingJp = isLevelB && isJapanese(i18n)

  const [studentData, setStudentData] = useState<
    null | QrCodeStudentQuery['qrCodeStudent']['data']
  >(null)
  const [linkComplete, setLinkComplete] = useState(false)
  const [editUser, setEditUser] = useState(false)

  const [getStudent, { data, error }] = useQrCodeStudentLazyQuery({
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  })
  const [linkSchool, { loading }] = useStudentLinkSchoolMutation()

  const [form] = Form.useForm()

  useEffect(() => {
    inputtedCode = null
  }, [])

  useEffect(() => {
    if (error) {
      console.error('useQrCodeStudentLazyQuery - error:', error)
      message.error(t('エラーが発生しました。'))
      return
    }

    if (!data) return

    if (data.qrCodeStudent?.error === 'USER_NOT_FOUND') {
      message.error(t('エラー： USER_NOT_FOUND!'))
      return
    } else if (data.qrCodeStudent?.error) {
      message.error(t('エラーが発生しました。'))
      console.error('data.qrCodeStudent?.error', data.qrCodeStudent.error)
      return
    }

    const _studentData = data.qrCodeStudent?.data
    if (_studentData) {
      setStudentData(_studentData)

      formEdit.setFieldsValue({
        yearBirth: _studentData.attributes?.yearBirth,
        monthBirth: _studentData.attributes?.monthBirth,
        dayBirth: _studentData.attributes?.dayBirth,
      })
    }
  }, [data, error])

  const onFinish = async (invitationCode: string) => {
    try {
      const linkVariables = {
        input: {
          invitationCode,
        },
      }
      const res = await linkSchool({ variables: linkVariables })
      if (res.errors) {
        message.error(
          `${t('エラーが発生しました。')} [${res.errors.join(',')}]`,
        )
        return
      }

      const { studentLinkSchool } = res.data
      if (!studentLinkSchool?.token) {
        message.error(
          `${t('エラーが発生しました。')} [${studentLinkSchool?.error}]`,
        )
        return
      }
      if (studentLinkSchool?.token) {
        auth.setAccessToken(studentLinkSchool.token)
      }
      message.success(t('登録しました。'))
      setLinkComplete(true)

      setTimeout(() => {
        history.push('/movie')
      }, 2000)
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${err}]`)
    }
  }

  const onFormSubmit = (values: { invitationCode: string }) => {
    const currCode = values.invitationCode
    if (!currCode || currCode === inputtedCode) return

    inputtedCode = currCode

    getStudent({
      variables: { input: { qrCode: currCode } },
    })

    auth.setSchoolLevel(currCode.length >= 2 ? currCode[1] : '')
  }

  const handleScan = (scannedCode: string) => {
    if (scannedCode && scannedCode !== inputtedCode) {
      form.setFieldsValue({ invitationCode: scannedCode })
      inputtedCode = scannedCode

      getStudent({
        variables: { input: { qrCode: scannedCode } },
      })

      auth.setSchoolLevel(scannedCode.length >= 2 ? scannedCode[1] : '')
    }
  }

  const handleReturn = () => {
    form.setFieldsValue({ invitationCode: null })
    inputtedCode = null
    setStudentData(null)
  }

  const checkUser = async (invitationCode: string) => {
    if (
      studentData?.attributes?.yearBirth &&
      studentData.attributes.monthBirth &&
      studentData.attributes.dayBirth
    ) {
      await onFinish(invitationCode)
    } else {
      try {
        const linkVariables = {
          input: {
            invitationCode,
          },
        }
        const res = await linkSchool({ variables: linkVariables })
        if (res.errors) {
          message.error(
            `${t('エラーが発生しました。')} [${res.errors.join(',')}]`,
          )
          return
        }
        const { studentLinkSchool } = res.data
        if (!studentLinkSchool?.token) {
          message.error(
            `${t('エラーが発生しました。')} [${studentLinkSchool?.error}]`,
          )
          return
        }
        if (studentLinkSchool?.token) {
          auth.setAccessToken(studentLinkSchool.token)
          setTokenUrl(studentLinkSchool.token)
        }
      } catch (err) {
        message.error(`${t('エラーが発生しました。')} [${err}]`)
      }

      setEditUser(true)
    }
  }

  const QRCodeForm = (
    <div>
      <div className="flex justify-center items-center">
        <QRReader
          delay={300}
          onError={handleQRReaderError}
          onScan={handleScan}
          style={{
            width: '87%',
            backgroundColor: '#0d2784',
          }}
        />
      </div>
      <Form
        form={form}
        layout="vertical"
        initialValues={{ invitationCode: null }}
        onFinish={onFormSubmit}
        style={{ margin: '0 12px' }}
      >
        <div className="mt-6 items-center ">
          <span style={{ color: 'red' }}>*</span>
          <label className="dark:text-white font-black">
            {t('サインインコード')}
          </label>
        </div>
        <div>
          <Form.Item
            name="invitationCode"
            rules={[
              {
                required: true,
                message: t('サインインコードを入力して下さい。'),
              },
            ]}
          >
            <Input.Password
              type="password"
              className="h-10 bg-gray-150 mt-1"
              disabled={loading}
              iconRender={(visible) =>
                visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
              }
            />
          </Form.Item>
        </div>
        <Row justify="center" className="mt-4">
          <Button
            type="primary"
            className="w-60"
            size="large"
            htmlType="submit"
            loading={loading}
          >
            {t('次へ')}
          </Button>
        </Row>
      </Form>

      <div className="text-base notice text-center">
        {t(
          'セキュリティ強化のため、2024年7月24日（水）にサインインコードを変更いたしました。',
        )}
        <br />
        {t('新しいサインインコードは担任の先生に確認してください。')}
        <br />
        {t('*2024年7月24日（水）以前のサインインコードは使用できません。')}
      </div>
    </div>
  )

  const studentAttr = studentData?.attributes
  const schoolName = studentAttr?.schoolName

  const QRCodeConfirmView = (
    <div className="bg-gray-150 py-20 modal-mypage mx-auto">
      <div className="space-y-9">
        <div className="text-xl font-bold text-center">
          {t('あなたですか？')}
        </div>
        <div className="show-pc">
          <div className="space-y-7 px-40 pr-20">
            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black">
                  {t('名前')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">なまえ</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">
                {t('フルネーム', {
                  familyName: studentAttr?.familyName,
                  givenName: studentAttr?.givenName,
                })}
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div className="flex-1 text-left" style={{ minWidth: 50 }}>
                <ruby className="font-black">
                  {t('学校名')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がっこうめい</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">{schoolName}</div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('学年')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がくねん</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">{studentAttr?.schoolGrade}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">{t('クラス')}</ruby>
                <span className="text-lg">{studentAttr?.schoolClass}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('出席番号')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">しゅっせきばんごう</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">
                  {studentAttr?.schoolAttendanceNumber}
                </span>
              </div>
            </div>
          </div>
          <div className="space-x-4 flex items-center justify-center">
            <div>
              <Button
                type="primary"
                className="w-60"
                size="large"
                onClick={() => checkUser(inputtedCode)}
              >
                {t('はい')}
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                className="w-60"
                size="large"
                onClick={handleReturn}
              >
                {t('いいえ')}
              </Button>
            </div>
          </div>
        </div>
        <div className="show-sp">
          <div className="space-y-7 px-4">
            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black">
                  {t('名前')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">なまえ</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">
                {studentAttr?.familyName && (
                  <div>
                    {studentAttr?.familyName} {studentAttr?.givenName}
                  </div>
                )}
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div className="flex-1 text-left" style={{ minWidth: 50 }}>
                <ruby className="font-black">
                  {t('学校名')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がっこうめい</rt>
                  ) : null}
                </ruby>
              </div>
              <div className="text-lg text-center">{schoolName}</div>
              <div className="flex-1" />
            </div>
            <div className="flex">
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('学年')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">がくねん</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">{studentAttr?.schoolGrade}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">クラス</ruby>
                <span className="text-lg">{studentAttr?.schoolClass}</span>
              </div>
              <div className="flex-1 text-left">
                <ruby className="font-black mr-5">
                  {t('出席番号')}
                  {isUsingJp ? (
                    <rt className="text-xxs font-black">しゅっせきばんごう</rt>
                  ) : null}
                </ruby>
                <span className="text-lg">
                  {studentAttr?.schoolAttendanceNumber}
                </span>
              </div>
            </div>
          </div>
          <div className="space-x-4 flex items-center justify-center mt-3">
            <div>
              <Button
                type="primary"
                className="w-30"
                size="large"
                onClick={() => checkUser(inputtedCode)}
              >
                {t('はい')}
              </Button>
            </div>
            <div>
              <Button
                type="primary"
                className="w-30"
                size="large"
                onClick={handleReturn}
              >
                {t('いいえ')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const [formEdit] = Form.useForm()

  const listYear = getListData(1970, currentYear, false)
  const listMonth = getListData(1, 12)
  const [listDay, setListDay] = useState(getListData(1, 31))
  const [loadingSubmit, setLoading] = useState<boolean>(false)
  const [tokenUrl, setTokenUrl] = useState('')

  const onEdit = async () => {
    setLoading(true)
    const submitData = formEdit.getFieldsValue()
    const apiUrl = '/alpha/v1/student/updateStudentMe/'

    try {
      await Api.post(apiUrl, submitData, {
        headers: {
          'x-auth-token': 'Bearer ' + tokenUrl,
        },
      })

      await onFinish(inputtedCode)
    } catch (err) {
      message.error(`${t('エラーが発生しました。')} [${apiUrl}]`, 5)
      console.error(`${apiUrl} error:`, err)
    }

    setLoading(false)
  }

  useEffect(() => {
    if (studentData?._id) {
      formEdit.setFieldsValue({
        yearBirth: studentData.attributes?.yearBirth,
        monthBirth: studentData.attributes?.monthBirth,
        dayBirth: studentData.attributes?.dayBirth,
      })

      handleChange()
    }
  }, [studentData?._id])

  const handleChange = () => {
    const data = formEdit.getFieldsValue()
    if (data?.yearBirth && data?.monthBirth) {
      const date = new Date(data?.yearBirth, data?.monthBirth, 0).getDate()

      setListDay(getListData(1, date))
      if (data?.dayBirth) {
        if (data?.dayBirth > date) {
          formEdit.setFieldsValue({
            yearBirth: data?.yearBirth,
            monthBirth: data?.monthBirth,
            dayBirth: '',
          })
        }
      }
    }
  }

  return (
    <div
      className="w-full h-screen flex justify-center overflow-hidden register-page"
      style={{
        background: 'url(/images/SignInBackground.png) no-repeat bottom',
        backgroundSize: 'contain',
      }}
    >
      {linkComplete ? (
        <div className="flex flex-col relative">
          <div className="w-full text-center absolute mt-11">
            <ruby className="text-2xl font-black">
              {t('本人確認完了')}
              {isUsingJp ? (
                <rt className="text-10px">ほんにんかくにんかんりょう</rt>
              ) : null}
            </ruby>
          </div>
          <div className="flex-1">
            <CompletionLayout message={t('ようこそ！')} />
          </div>
          <div className="" />
        </div>
      ) : (
        <div className="pt-11" style={{ width: studentData ? 800 : 400 }}>
          <h1 className="pb-4 text-center">
            {studentData ? (
              <ruby className="text-2xl font-black">
                {t('本人確認')}
                {isUsingJp ? (
                  <rt className="text-10px">ほんにんかくにん</rt>
                ) : null}
              </ruby>
            ) : (
              <label className="text-2xl font-black">{t('サインイン')}</label>
            )}
          </h1>

          {editUser ? (
            <div className="bg-gray-150 py-20 modal-mypage mx-auto">
              {loadingSubmit ? (
                <LoadingSpin />
              ) : (
                <>
                  <div className="show-pc">
                    <div className="space-y-9 formEditUser">
                      <div className="text-xl font-bold text-center">
                        {isUsingJp ? (
                          <span>
                            <ruby>
                              生年月日
                              <rt>せいねんがっぴ</rt>
                            </ruby>
                            <ruby>
                              を入力して
                              <rt style={{ paddingLeft: 10, marginRight: 30 }}>
                                にゅうりょく
                              </rt>
                            </ruby>
                            ください
                          </span>
                        ) : (
                          t('生年月日を入力してください。')
                        )}
                      </div>
                      <div>
                        <Form
                          form={formEdit}
                          initialValues={{
                            yearBirth: '',
                            monthBirth: '',
                            dayBirth: '',
                          }}
                        >
                          <div className="space-x-4 flex items-center justify-center">
                            <div className="relative">
                              <span
                                className="inputDateLabel"
                                style={isUsingJp ? { marginTop: -6 } : {}}
                              >
                                {isUsingJp ? (
                                  <ruby>
                                    生年月日
                                    <rt className="text-tiny font-black">
                                      せいねんがっぴ
                                    </rt>
                                  </ruby>
                                ) : (
                                  t('生年月日')
                                )}
                              </span>
                              <Form.Item name="yearBirth">
                                <Select
                                  style={{ width: 120 }}
                                  onChange={handleChange}
                                  options={listYear}
                                />
                              </Form.Item>
                            </div>
                            <span className="mx-2 textDate">
                              {isUsingJp ? (
                                <ruby>
                                  年<rt>ねん</rt>
                                </ruby>
                              ) : (
                                t('年')
                              )}
                            </span>
                            <Form.Item name="monthBirth">
                              <Select
                                style={{ width: 120 }}
                                onChange={handleChange}
                                options={listMonth}
                              />
                            </Form.Item>
                            <span className="mx-2 textDate">
                              {isUsingJp ? (
                                <ruby>
                                  月<rt>がつ</rt>
                                </ruby>
                              ) : (
                                t('月')
                              )}
                            </span>
                            <Form.Item name="dayBirth">
                              <Select
                                style={{ width: 120 }}
                                onChange={handleChange}
                                options={listDay}
                              />
                            </Form.Item>
                            <span className="mx-2 textDate">
                              {isUsingJp ? (
                                <ruby>
                                  日<rt>にち</rt>
                                </ruby>
                              ) : (
                                t('日')
                              )}
                            </span>
                          </div>
                          <div className="space-x-4 flex items-center justify-center mt-6">
                            <Form.Item>
                              <Button
                                type="primary"
                                className="w-30"
                                onClick={onEdit}
                              >
                                {isUsingJp ? (
                                  <ruby>
                                    登録
                                    <rt>とうろく</rt>
                                  </ruby>
                                ) : (
                                  t('登録')
                                )}
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                  <div className="show-sp">
                    <div className="space-y-9 formEditUser">
                      <div className="text-xl font-bold text-center">
                        {t('生年月日を入力してください。')}
                      </div>
                      <div>
                        <Form
                          form={formEdit}
                          initialValues={{
                            yearBirth: '',
                            monthBirth: '',
                            dayBirth: '',
                          }}
                        >
                          <div className="flex align-center item-end justify-center">
                            <div className="relative">
                              <span className="inputDateLabel">
                                {t('生年月日')}
                              </span>
                              <Form.Item name="yearBirth">
                                <Select
                                  style={{ width: 100 }}
                                  onChange={handleChange}
                                  options={listYear}
                                />
                              </Form.Item>
                            </div>

                            <span className="mx-2 textDate">{t('年')}</span>
                            <Form.Item name="monthBirth">
                              <Select
                                style={{ width: 60 }}
                                onChange={handleChange}
                                options={listMonth}
                              />
                            </Form.Item>
                            <span className="mx-2 textDate">{t('月')}</span>
                            <Form.Item name="dayBirth">
                              <Select
                                style={{ width: 60 }}
                                onChange={handleChange}
                                options={listDay}
                              />
                            </Form.Item>
                            <span className="mx-2 textDate">{t('日')}</span>
                          </div>
                          <div className="space-x-4 flex items-center justify-center mt-6">
                            <Form.Item>
                              <Button
                                type="primary"
                                className="w-30"
                                onClick={onEdit}
                              >
                                {t('登録')}
                              </Button>
                            </Form.Item>
                          </div>
                        </Form>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : studentData ? (
            QRCodeConfirmView
          ) : (
            QRCodeForm
          )}
        </div>
      )}
    </div>
  )
}

export default RegisterUserPage
