import { Form, InputNumber, message } from 'antd'
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import Api from '~/utils/api'
import { lastMonth, thisYear } from '~/utils/constants'
import { menuButton } from '../shared/utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
)

/**
 * TODO: Remove dateTime, activatedTab input
 */
export const Grip = ({
  userId,
  dateTime,
  activatedTab,
  tabKey,
  isUsingJpRuby,
}) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()

  const [active, setActive] = useState<number>(0)
  const [gripData, setGripData] = useState([])
  const [graphMonth, setGraphMonth] = useState<any>([])
  const [graphYear, setGraphYear] = useState<any>([])
  const [month, setMonth] = useState<number>(lastMonth + 1)
  const [year, setYear] = useState<number>(thisYear)
  const [sportsValue, setSportValues] = useState<any>()
  const [disabled, setDisabled] = useState<boolean>(true)

  const [submitSuccess, setSubmitSuccess] = useState<boolean>(false)
  const lineChart = useRef<HTMLDivElement>(null)
  const [daysInMonth, setDaysInMonth] = useState<any>([])
  const [monthsWithYear, setMonthsWithYear] = useState<any>([])

  useEffect(() => {
    const monthDay = []
    const dateMonth = new Date(year, month, 0).getDate()
    for (let x = 1; x <= dateMonth; x++) {
      monthDay.push({
        date: month + '/' + x,
        key: x,
      })
    }
    setDaysInMonth(monthDay)
  }, [month, year])
  useEffect(() => {
    const yearMonth = []
    for (let x = 1; x <= 12; x++) {
      yearMonth.push({
        date: year + '/' + x,
        key: x,
      })
    }
    setMonthsWithYear(yearMonth)
  }, [year])

  const getValueData = async () => {
    const apiUrl = `/alpha/v1/student/getValueTrainingSport/${userId}?trainingSport=${tabKey}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setSportValues(dataTraining.data)
    }
  }

  const getGripData = async () => {
    const apiUrl = `/alpha/v1/student/gripStrengthInputHistory/${userId}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGripData(dataTraining.data)
    }
  }

  const getGripMonth = async () => {
    const selectedDate = `${year}-${month}-15`

    const apiUrl = `/alpha/v1/student/gripStrengthDataGraphMonth/${userId}/?date=${selectedDate}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGraphMonth(dataTraining.data)
    }
  }

  const getGripYear = async () => {
    const selectedDate = `${year}-${month}-15`

    const apiUrl = `/alpha/v1/student/gripStrengthDataGraphYear/${userId}/?date=${selectedDate}`
    const { data: dataTraining } = await Api.get(apiUrl)
    if (dataTraining.data) {
      setGraphYear(dataTraining.data)
    }
  }

  useEffect(() => {
    if (!activatedTab) {
      setActive(0)
      form.resetFields()
      form.setFieldsValue({
        left1: sportsValue?.left1 || '',
        left2: sportsValue?.left2 || '',
        right1: sportsValue?.right1 || '',
        right2: sportsValue?.right2 || '',
      })
      if (sportsValue?.studentID) {
        setDisabled(false)
      } else setDisabled(true)
    }
  }, [activatedTab])

  useEffect(() => {
    if (sportsValue) {
      form.setFieldsValue({
        left1: sportsValue.left1 || '',
        left2: sportsValue.left2 || '',
        right1: sportsValue.right1 || '',
        right2: sportsValue.right2 || '',
      })
      setDisabled(false)
    }
  }, [sportsValue])

  useEffect(() => {
    if (userId) {
      getGripData()
      getGripMonth()
      getGripYear()
      getValueData()
    }
  }, [active, userId, month, year, tabKey])

  const onClickLeftArrow = async () => {
    if (active === 0) {
      setMonth(month < 2 ? 12 : month - 1)
      setYear(month < 2 ? year - 1 : year)
    } else {
      setYear(year - 1)
    }
  }

  const onClickRightArrow = async () => {
    if (active === 0) {
      setMonth(month > 11 ? 1 : month + 1)
      setYear(month > 11 ? year + 1 : year)
    } else {
      setYear(year + 1)
    }
  }

  const onFinish = async (data: any) => {
    const submitData = {
      ...data,
      left1: data.left1 || 0,
      left2: data.left2 || 0,
      right1: data.right1 || 0,
      right2: data.right2 || 0,
      studentID: userId,
      date: dateTime,
    }

    try {
      const apiUrl = '/alpha/v1/student/gripStrengthInput/'
      await Api.post(apiUrl, submitData)
      setSubmitSuccess(true)
      getGripData()
      getGripMonth()
      getGripYear()
      getValueData()
      setTimeout(() => {
        setSubmitSuccess(false)
      }, 5000)
    } catch (err) {
      console.error('/alpha/v1/student/ - test subject', err)
      message.error(t('エラーが発生しました。'))
    }
    form.resetFields()
  }

  const graphRecordLastestMonth = graphMonth?.dataLastest?.length
    ? graphMonth?.dataLastest?.map((item: any) => item.value)
    : 0
  const graphRecordLastestYear = graphYear?.dataLastest?.length
    ? graphYear?.dataLastest?.map((item: any) => item.value)
    : 0
  const graphRecordMaxMonth = graphMonth?.dataMax?.length
    ? graphMonth?.dataMax?.map((item: any) => item.maxValue)
    : 0
  const graphRecordMaxYear = graphYear?.dataMax?.length
    ? graphYear?.dataMax?.map((item: any) => item.maxValue)
    : 0
  const dataChartMonth: any = {}

  const dataChartYear: any = {}

  graphMonth?.dataGraph?.forEach((item: any) => {
    dataChartMonth[moment(item.date).format('M/D')] = item.value
  })

  const parseGraphYear = graphYear?.dataGraph
    ?.filter((item: any) => item.value !== 0)
    .map((val: any) => ({
      ...val,
      date: moment(val.date).format('YYYY/M'),
    }))

  const arrGraphYear = _.map(_.groupBy(parseGraphYear, 'date'), (g) =>
    _.maxBy(g, 'value'),
  )

  arrGraphYear?.forEach((item: any) => {
    dataChartYear[item.date] = item.value
  })

  const daysInMonthLabel = daysInMonth.map((item) => item.date)

  const monthsInYearLabel = monthsWithYear.map((item) => item.date)

  const monthGraph = daysInMonth.map((item) => dataChartMonth[item.date] || 0)

  const yearGraph = monthsWithYear.map((item) => dataChartYear[item.date] || 0)

  const data: any = {
    labels: active === 0 ? daysInMonthLabel : monthsInYearLabel,
    datasets: [
      {
        data: active === 0 ? monthGraph : yearGraph,
        borderColor: '#036EB8',
        backgroundColor: '#036EB8',
      },
    ],
  }

  const items = [
    {
      label: isUsingJpRuby ? (
        <span>
          <ruby>
            <span>左</span>
            <rt className="font-normal text-xxs">ひだり</rt>
          </ruby>
          {'(1回目)'}
        </span>
      ) : (
        t('左（1回目）')
      ),
      name: 'left1',
      suffix: 'kg',
      rules: [
        {
          type: 'number',
          min: 0,
          max: 99,
          message: t('{{min}}から{{max}}の数字を入力してください', {
            min: 0,
            max: 99,
          }),
        },
      ],
    },
    {
      label: isUsingJpRuby ? (
        <span>
          <ruby>
            <span>右</span>
            <rt className="font-normal text-xxs">みぎ</rt>
          </ruby>
          {'(1回目)'}
        </span>
      ) : (
        t('右（1回目）')
      ),
      name: 'right1',
      suffix: 'kg',
      rules: [
        {
          type: 'number',
          min: 0,
          max: 99,
          message: t('{{min}}から{{max}}の数字を入力してください', {
            min: 0,
            max: 99,
          }),
        },
      ],
    },
    {
      label: isUsingJpRuby ? (
        <span>
          <ruby>
            <span>左</span>
            <rt className="font-normal text-xxs">ひだり</rt>
          </ruby>
          {'(2回目)'}
        </span>
      ) : (
        t('左（2回目）')
      ),
      name: 'left2',
      suffix: 'kg',
      rules: [
        {
          type: 'number',
          min: 0,
          max: 99,
          message: t('{{min}}から{{max}}の数字を入力してください', {
            min: 0,
            max: 99,
          }),
        },
      ],
    },
    {
      label: isUsingJpRuby ? (
        <span>
          <ruby>
            <span>右</span>
            <rt className="font-normal text-xxs">みぎ</rt>
          </ruby>
          {'(2回目)'}
        </span>
      ) : (
        t('右（2回目）')
      ),
      name: 'right2',
      suffix: 'kg',
      rules: [
        {
          type: 'number',
          min: 0,
          max: 99,
          message: t('{{min}}から{{max}}の数字を入力してください', {
            min: 0,
            max: 99,
          }),
        },
      ],
    },
  ]

  const selectedMonth = month - 1
  const selectedYear = year

  const onValuesChange = (a: any, total: any) => {
    if (
      (total.left1 !== null && total.left1 !== undefined) ||
      (total.left2 !== null && total.left2 !== undefined) ||
      (total.right1 !== null && total.right1 !== undefined) ||
      (total.right2 !== null && total.right2 !== undefined)
    ) {
      setDisabled(false)
    } else setDisabled(true)
  }

  const kgOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        position: 'average',
        yAlign: 'bottom',
        backgroundColor: '#fff',
        bodyColor: '#036EB8',
        borderColor: '#FFC833',
        borderWidth: 3,
        cornerRadius: 5,
        displayColors: false,
        caretPadding: 5,
        caretSize: 10,
        padding: 15,
        callbacks: {
          title: () => null,
          label: (context?: any) =>
            `${context.label} ${context.formattedValue}kg`,
        },
      },
    },
    scales: {
      y: {
        grace: gripData?.length ? '30%' : 0,
        max: gripData?.length ? null : 100,
        beginAtZero: true,
        grid: {
          borderDash: [1, 1],
          drawTicks: false,
          color: (context: any) => {
            if (context.tick.value === 0) {
              return 'rgba(0, 0, 0, 0)'
            }
            return 'rgba(0, 0, 0, 0.1)'
          },
        },
        position: 'left',
        ticks: {
          color: '#C4C4C4',
          padding: 8,
          font: {
            size: 8,
            weight: 400,
          },
        },
      },
      x: {
        offset: true,
        grid: {
          display: false,
        },
        ticks: {
          padding: 20,
          color: '#C4C4C4',
          font: {
            size: 8,
            weight: 400,
          },
        },
      },
    },
  }

  return (
    <div>
      <Form form={form} onValuesChange={onValuesChange} onFinish={onFinish}>
        <div className="trainingWrapper">
          <div className="inputContainer">
            <div className="inputHeader">
              {isUsingJpRuby ? (
                <span>
                  <ruby>
                    <span>測定</span>
                    <rt className="font-normal text-xxs">そくてい</rt>
                  </ruby>
                  を
                  <ruby>
                    <span>記録</span>
                    <rt className="font-normal text-xxs">きろく</rt>
                  </ruby>
                  する
                </span>
              ) : (
                t('測定を記録する')
              )}
            </div>
            {submitSuccess && (
              <div className="inputStatusButton">
                <span>{t('登録完了')}</span>
              </div>
            )}
            <div className="inputBody">
              <div className="container">
                {items.map((item, idx) => (
                  <div key={idx} className="flex flex-col">
                    <span className="itemLabel">{item.label}</span>
                    <div className="flex">
                      <Form.Item
                        style={{ maxWidth: '88px' }}
                        className="mb-0-f"
                        name={item.name}
                        rules={item.rules as any}
                      >
                        <InputNumber
                          className="border-primary"
                          type="number"
                          inputMode="decimal"
                          onKeyDown={(e) => {
                            if (e.key === '-' || e.key === '+') {
                              e.preventDefault()
                            }
                          }}
                        />
                      </Form.Item>
                      {item.suffix && (
                        <div className="textSuffix">
                          <span>{item.suffix}</span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="inputFooter">
              <Form.Item>
                <button type="submit" disabled={disabled}>
                  {sportsValue?.studentID ? (
                    isUsingJpRuby ? (
                      <ruby>
                        修正
                        <rt className="text-tiny">しゅうせい</rt>
                      </ruby>
                    ) : (
                      t('修正')
                    )
                  ) : isUsingJpRuby ? (
                    <ruby>
                      登録
                      <rt className="text-tiny">とうろく</rt>
                    </ruby>
                  ) : (
                    t('登録')
                  )}
                </button>
              </Form.Item>
            </div>
          </div>

          <div className="chartContainer">
            <div className="chartHeader">{t('グラフ')}</div>
            <div className="chartDescription">
              <div className="chartLeftContent">
                {menuButton.map((button, idx: number) => (
                  <div
                    className="switchButton"
                    key={idx}
                    style={idx === active ? { background: '#fff' } : {}}
                    onClick={() => setActive(idx)}
                    role="presentation"
                  >
                    <span>{t(button)}</span>
                  </div>
                ))}
              </div>
              <div className="chartRightContent">
                <div className="headerContent">
                  <span>
                    {isUsingJpRuby ? (
                      <ruby>
                        <span>最新記録</span>
                        <rt className="font-normal text-xxs">さいしんきろく</rt>
                      </ruby>
                    ) : (
                      t('最新記録')
                    )}
                  </span>
                  <span>
                    {isUsingJpRuby ? (
                      <ruby>
                        <span>最高記録</span>
                        <rt className="font-normal text-xxs">さいこうきろく</rt>
                      </ruby>
                    ) : (
                      t('最高記録')
                    )}
                  </span>
                </div>
                <div className="bodyContent">
                  <span>{`${
                    active === 0
                      ? graphRecordLastestMonth
                      : graphRecordLastestYear
                  }kg`}</span>
                  <span>{`${
                    active === 0 ? graphRecordMaxMonth : graphRecordMaxYear
                  }kg`}</span>
                </div>
              </div>
            </div>

            <div className="lineChart">
              <Line
                ref={lineChart}
                height="300px"
                data={data}
                options={kgOptions as any}
              />
              {(active === 0
                ? selectedMonth >= lastMonth
                : selectedYear > thisYear) && (
                <div
                  onClick={onClickLeftArrow}
                  className="leftArrow"
                  role="presentation"
                >
                  <img
                    className="imageChangeDate"
                    width={12}
                    height={12}
                    src="assets/images/prev-icon.svg"
                    alt=""
                  />
                </div>
              )}
              {(active === 0
                ? selectedMonth < lastMonth
                : selectedYear < thisYear) && (
                <div
                  id="rightClickArrow"
                  onClick={onClickRightArrow}
                  className="rightArrow"
                  role="presentation"
                >
                  <img
                    className="imageChangeDate"
                    width={12}
                    height={12}
                    src="assets/images/next-icon.svg"
                    alt=""
                  />
                </div>
              )}
            </div>
          </div>

          <div className="listContainer">
            <div className="listHeader">
              <span>
                <ruby>
                  {t('測定日')}
                  {isUsingJpRuby ? <rt>そくていび</rt> : null}
                </ruby>
              </span>
              <span>
                <ruby>
                  {t('記録')}
                  {isUsingJpRuby ? <rt>記録</rt> : null}
                </ruby>
              </span>
            </div>
            {gripData.map((data, index: number) => (
              <div key={index} className="listContent">
                <span>{moment(data.date).format('M/D')}</span>
                <span>{`${data.value}kg`}</span>
              </div>
            ))}
          </div>
        </div>
      </Form>
    </div>
  )
}
