import { IPrefectureAverage } from './prefectureCodes'

const prefectureAverage2022: Record<
  string,
  { male: IPrefectureAverage[]; female: IPrefectureAverage[] }
> = {
  三重県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.63,
        sitUps: 10.56,
        bending: 25.96,
        sideJump: 26.37,
        shuttleRun: 16.52,
        enduranceRun: null,
        sprintRun: 11.96,
        standingJump: 111.38,
        handballThrow: 7.94,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.91,
        sitUps: 13.41,
        bending: 27.72,
        sideJump: 31.19,
        shuttleRun: 26.72,
        enduranceRun: null,
        sprintRun: 10.88,
        standingJump: 125.19,
        handballThrow: 11.26,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.11,
        sitUps: 15.13,
        bending: 30,
        sideJump: 33.91,
        shuttleRun: 33.15,
        enduranceRun: null,
        sprintRun: 10.39,
        standingJump: 134.2,
        handballThrow: 14.34,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.06,
        sitUps: 16.49,
        bending: 31.39,
        sideJump: 37.6,
        shuttleRun: 39.29,
        enduranceRun: null,
        sprintRun: 9.93,
        standingJump: 143.62,
        handballThrow: 17.38,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.59,
        sitUps: 18.71,
        bending: 34.35,
        sideJump: 41.6,
        shuttleRun: 47.88,
        enduranceRun: null,
        sprintRun: 9.49,
        standingJump: 151.63,
        handballThrow: 20.72,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.22,
        sitUps: 19.98,
        bending: 35.91,
        sideJump: 44.04,
        shuttleRun: 54.63,
        enduranceRun: null,
        sprintRun: 9.17,
        standingJump: 160.8,
        handballThrow: 23.78,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.38,
        sitUps: 22.55,
        bending: 39.78,
        sideJump: 48.68,
        shuttleRun: 63.07,
        enduranceRun: 448.81,
        sprintRun: 8.76,
        standingJump: 180.77,
        handballThrow: 17.68,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.36,
        sitUps: 25.39,
        bending: 44.21,
        sideJump: 52.54,
        shuttleRun: 79.34,
        enduranceRun: 408.85,
        sprintRun: 8.07,
        standingJump: 197.6,
        handballThrow: 20.46,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.55,
        sitUps: 27.65,
        bending: 48.12,
        sideJump: 55.84,
        shuttleRun: 87.58,
        enduranceRun: 394.62,
        sprintRun: 7.66,
        standingJump: 212.76,
        handballThrow: 23.27,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.25,
        sitUps: 27.57,
        bending: 46.81,
        sideJump: 56.03,
        shuttleRun: 79.29,
        enduranceRun: 388.27,
        sprintRun: 7.74,
        standingJump: 214.18,
        handballThrow: 22.9,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.29,
        sitUps: 28.93,
        bending: 49.36,
        sideJump: 57.56,
        shuttleRun: 84.37,
        enduranceRun: 370.07,
        sprintRun: 7.42,
        standingJump: 221.39,
        handballThrow: 24.26,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.09,
        sitUps: 30.01,
        bending: 50.92,
        sideJump: 58.76,
        shuttleRun: 85.79,
        enduranceRun: 365.31,
        sprintRun: 7.35,
        standingJump: 226.76,
        handballThrow: 25.29,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.25,
        sitUps: 10.33,
        bending: 28.35,
        sideJump: 25.93,
        shuttleRun: 14.93,
        enduranceRun: null,
        sprintRun: 12.27,
        standingJump: 105.63,
        handballThrow: 5.63,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.83,
        sitUps: 12.89,
        bending: 31.13,
        sideJump: 30.07,
        shuttleRun: 20.51,
        enduranceRun: null,
        sprintRun: 11.25,
        standingJump: 118.91,
        handballThrow: 7.44,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.55,
        sitUps: 14.58,
        bending: 33.55,
        sideJump: 32.71,
        shuttleRun: 24.99,
        enduranceRun: null,
        sprintRun: 10.71,
        standingJump: 12.52,
        handballThrow: 9.57,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.52,
        sitUps: 15.79,
        bending: 35.38,
        sideJump: 35.88,
        shuttleRun: 29.78,
        enduranceRun: null,
        sprintRun: 10.23,
        standingJump: 137.5,
        handballThrow: 11.19,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.13,
        sitUps: 17.49,
        bending: 38.36,
        sideJump: 40.24,
        shuttleRun: 37.59,
        enduranceRun: null,
        sprintRun: 9.78,
        standingJump: 144.19,
        handballThrow: 13.26,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.94,
        sitUps: 18.4,
        bending: 40.35,
        sideJump: 41.67,
        shuttleRun: 42.1,
        enduranceRun: null,
        sprintRun: 9.46,
        standingJump: 151.43,
        handballThrow: 14.97,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 20.74,
        sitUps: 19.48,
        bending: 42.75,
        sideJump: 45.06,
        shuttleRun: 45.36,
        enduranceRun: 321.18,
        sprintRun: 9.31,
        standingJump: 162.51,
        handballThrow: 10.99,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.21,
        sitUps: 21.03,
        bending: 46.14,
        sideJump: 46.96,
        shuttleRun: 51.85,
        enduranceRun: 298.85,
        sprintRun: 8.95,
        standingJump: 167.81,
        handballThrow: 12.78,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.87,
        sitUps: 22.68,
        bending: 48.69,
        sideJump: 48.71,
        shuttleRun: 55.34,
        enduranceRun: 301.61,
        sprintRun: 8.88,
        standingJump: 172.66,
        handballThrow: 13.95,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.14,
        sitUps: 22.55,
        bending: 47.67,
        sideJump: 48.31,
        shuttleRun: 44.83,
        enduranceRun: 317.04,
        sprintRun: 9.03,
        standingJump: 171.71,
        handballThrow: 13.28,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.03,
        sitUps: 22.85,
        bending: 47.93,
        sideJump: 48.5,
        shuttleRun: 47.47,
        enduranceRun: 311.63,
        sprintRun: 8.96,
        standingJump: 170.86,
        handballThrow: 13.71,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.31,
        sitUps: 23.29,
        bending: 49.49,
        sideJump: 47.41,
        shuttleRun: 47.2,
        enduranceRun: 311.17,
        sprintRun: 8.96,
        standingJump: 174.13,
        handballThrow: 14.19,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  京都府: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.65,
        sitUps: 12.16,
        bending: 25,
        sideJump: 26.83,
        shuttleRun: 20.17,
        enduranceRun: null,
        sprintRun: 11.39,
        standingJump: 116.53,
        handballThrow: 8.47,
        totalScore: null,
        height: 117.14,
        weight: 21.71,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.28,
        sitUps: 14.31,
        bending: 26.91,
        sideJump: 30.83,
        shuttleRun: 29.37,
        enduranceRun: null,
        sprintRun: 10.61,
        standingJump: 127.33,
        handballThrow: 11.82,
        totalScore: null,
        height: 122.9,
        weight: 24.14,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.19,
        sitUps: 15.98,
        bending: 28.69,
        sideJump: 33.29,
        shuttleRun: 35.32,
        enduranceRun: null,
        sprintRun: 10.61,
        standingJump: 127.33,
        handballThrow: 11.82,
        totalScore: null,
        height: 128.7,
        weight: 27.54,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.89,
        sitUps: 17.7,
        bending: 30.3,
        sideJump: 37.29,
        shuttleRun: 45.69,
        enduranceRun: null,
        sprintRun: 9.64,
        standingJump: 146.23,
        handballThrow: 18.65,
        totalScore: null,
        height: 134.17,
        weight: 30.86,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.14,
        sitUps: 19.34,
        bending: 32.32,
        sideJump: 40.69,
        shuttleRun: 51.22,
        enduranceRun: null,
        sprintRun: 9.19,
        standingJump: 154.83,
        handballThrow: 22.62,
        totalScore: null,
        height: 139.9,
        weight: 34.61,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.56,
        sitUps: 21.33,
        bending: 33.49,
        sideJump: 43.89,
        shuttleRun: 59.92,
        enduranceRun: null,
        sprintRun: 8.82,
        standingJump: 165.76,
        handballThrow: 25.44,
        totalScore: null,
        height: 145.83,
        weight: 38.84,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.9,
        sitUps: 22.87,
        bending: 38.05,
        sideJump: 48.59,
        shuttleRun: 68.86,
        enduranceRun: null,
        sprintRun: 8.46,
        standingJump: 182.66,
        handballThrow: 17.82,
        totalScore: null,
        height: 153.91,
        weight: 44.65,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.32,
        sitUps: 26.1,
        bending: 42.04,
        sideJump: 52.45,
        shuttleRun: 84.53,
        enduranceRun: null,
        sprintRun: 7.85,
        standingJump: 199.28,
        handballThrow: 20.3,
        totalScore: null,
        height: 160.9,
        weight: 49.35,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.22,
        sitUps: 28.52,
        bending: 46.23,
        sideJump: 56.05,
        shuttleRun: 94,
        enduranceRun: null,
        sprintRun: 7.44,
        standingJump: 213,
        handballThrow: 22.73,
        totalScore: null,
        height: 160.9,
        weight: 49.35,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.54,
        sitUps: 27.75,
        bending: 46.6,
        sideJump: 55.16,
        shuttleRun: 85.48,
        enduranceRun: null,
        sprintRun: 7.37,
        standingJump: 219.03,
        handballThrow: 24.01,
        totalScore: null,
        height: 168.49,
        weight: 58.9,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.4,
        sitUps: 29.17,
        bending: 49.81,
        sideJump: 56.57,
        shuttleRun: 92.16,
        enduranceRun: null,
        sprintRun: 7.25,
        standingJump: 224.08,
        handballThrow: 24.98,
        totalScore: null,
        height: 169.81,
        weight: 60.65,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.62,
        sitUps: 30.42,
        bending: 50.58,
        sideJump: 57.89,
        shuttleRun: 94.81,
        enduranceRun: null,
        sprintRun: 7.16,
        standingJump: 228.09,
        handballThrow: 25.65,
        totalScore: null,
        height: 170.62,
        weight: 61.94,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.05,
        sitUps: 11.52,
        bending: 27.22,
        sideJump: 25.76,
        shuttleRun: 17.14,
        enduranceRun: null,
        sprintRun: 11.8,
        standingJump: 108.63,
        handballThrow: 5.76,
        totalScore: null,
        height: 108.63,
        weight: 20.91,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.66,
        sitUps: 13.8,
        bending: 30.2,
        sideJump: 29.47,
        shuttleRun: 23.56,
        enduranceRun: null,
        sprintRun: 10.91,
        standingJump: 120.41,
        handballThrow: 7.64,
        totalScore: null,
        height: 121.56,
        weight: 23.39,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.25,
        sitUps: 15.56,
        bending: 31.42,
        sideJump: 31.67,
        shuttleRun: 27.76,
        enduranceRun: null,
        sprintRun: 10.49,
        standingJump: 128.48,
        handballThrow: 9.99,
        totalScore: null,
        height: 128.07,
        weight: 27.17,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.18,
        sitUps: 17.04,
        bending: 33.71,
        sideJump: 36.9,
        shuttleRun: 35.69,
        enduranceRun: null,
        sprintRun: 9.93,
        standingJump: 138.45,
        handballThrow: 12.79,
        totalScore: null,
        height: 134.39,
        weight: 30.34,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.92,
        sitUps: 18.49,
        bending: 36.7,
        sideJump: 38.61,
        shuttleRun: 41.36,
        enduranceRun: null,
        sprintRun: 9.46,
        standingJump: 147.66,
        handballThrow: 14.74,
        totalScore: null,
        height: 141.84,
        weight: 35.83,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.28,
        sitUps: 19.46,
        bending: 38.32,
        sideJump: 41.74,
        shuttleRun: 46.98,
        enduranceRun: null,
        sprintRun: 9.17,
        standingJump: 155.34,
        handballThrow: 16.73,
        totalScore: null,
        height: 147.4,
        weight: 39.45,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.69,
        sitUps: 20.39,
        bending: 42.77,
        sideJump: 45.1,
        shuttleRun: 50.28,
        enduranceRun: null,
        sprintRun: 8.99,
        standingJump: 164.79,
        handballThrow: 11.89,
        totalScore: null,
        height: 152.11,
        weight: 44.35,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.75,
        sitUps: 22.33,
        bending: 45.17,
        sideJump: 47.64,
        shuttleRun: 59.04,
        enduranceRun: null,
        sprintRun: 8.73,
        standingJump: 172.09,
        handballThrow: 13.21,
        totalScore: null,
        height: 155.29,
        weight: 46.74,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.08,
        sitUps: 23.8,
        bending: 47.13,
        sideJump: 48.88,
        shuttleRun: 60.23,
        enduranceRun: null,
        sprintRun: 8.62,
        standingJump: 175.71,
        handballThrow: 13.61,
        totalScore: null,
        height: 156.75,
        weight: 49.08,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.81,
        sitUps: 22.07,
        bending: 46.47,
        sideJump: 47.64,
        shuttleRun: 52.24,
        enduranceRun: null,
        sprintRun: 8.76,
        standingJump: 172.51,
        handballThrow: 14.13,
        totalScore: null,
        height: 157.26,
        weight: 51.2,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.74,
        sitUps: 23.16,
        bending: 47.52,
        sideJump: 47.97,
        shuttleRun: 55.67,
        enduranceRun: null,
        sprintRun: 8.81,
        standingJump: 174.34,
        handballThrow: 14.34,
        totalScore: null,
        height: 157.8,
        weight: 51.75,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.75,
        sitUps: 23.46,
        bending: 49.15,
        sideJump: 48.92,
        shuttleRun: 54.84,
        enduranceRun: null,
        sprintRun: 8.86,
        standingJump: 174.31,
        handballThrow: 14.91,
        totalScore: null,
        height: 158.16,
        weight: 53.21,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  佐賀県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.89,
        sitUps: 10.64,
        bending: 28.1,
        sideJump: 25.97,
        shuttleRun: 16.93,
        enduranceRun: null,
        sprintRun: 11.69,
        standingJump: 113.17,
        handballThrow: 8.35,
        totalScore: 29.72,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.41,
        sitUps: 12.94,
        bending: 28.58,
        sideJump: 29.88,
        shuttleRun: 27.34,
        enduranceRun: null,
        sprintRun: 10.89,
        standingJump: 124.04,
        handballThrow: 11.29,
        totalScore: 36.23,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.07,
        sitUps: 14.94,
        bending: 30.8,
        sideJump: 33.36,
        shuttleRun: 33.91,
        enduranceRun: null,
        sprintRun: 10.28,
        standingJump: 134.9,
        handballThrow: 14.72,
        totalScore: 42.09,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.03,
        sitUps: 17.03,
        bending: 32.28,
        sideJump: 37.42,
        shuttleRun: 40.95,
        enduranceRun: null,
        sprintRun: 9.76,
        standingJump: 142.65,
        handballThrow: 18.4,
        totalScore: 47.68,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.15,
        sitUps: 18.47,
        bending: 34.23,
        sideJump: 40.67,
        shuttleRun: 49.13,
        enduranceRun: null,
        sprintRun: 9.35,
        standingJump: 151.47,
        handballThrow: 21.71,
        totalScore: 52.85,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.92,
        sitUps: 20.48,
        bending: 35.91,
        sideJump: 43.87,
        shuttleRun: 56.68,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 161.48,
        handballThrow: 25.12,
        totalScore: 58.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.09,
        sitUps: 22.68,
        bending: 40.1,
        sideJump: 48.38,
        shuttleRun: 65.78,
        enduranceRun: 446.37,
        sprintRun: 8.67,
        standingJump: 182.27,
        handballThrow: 18.13,
        totalScore: 34.11,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.7,
        sitUps: 25.68,
        bending: 4446,
        sideJump: 51.89,
        shuttleRun: 82.94,
        enduranceRun: 399.81,
        sprintRun: 7.99,
        standingJump: 201.72,
        handballThrow: 21,
        totalScore: 42.26,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.8,
        sitUps: 28.02,
        bending: 47.84,
        sideJump: 54.88,
        shuttleRun: 91.95,
        enduranceRun: 374.74,
        sprintRun: 7.57,
        standingJump: 214.46,
        handballThrow: 23.66,
        totalScore: 49.21,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.7,
        sitUps: 27.49,
        bending: 47.44,
        sideJump: 55.32,
        shuttleRun: 84.78,
        enduranceRun: 392.7,
        sprintRun: 7.44,
        standingJump: 218.95,
        handballThrow: 23.62,
        totalScore: 49.56,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.69,
        sitUps: 29.24,
        bending: 49.52,
        sideJump: 57.41,
        shuttleRun: 91.93,
        enduranceRun: 378.65,
        sprintRun: 7.26,
        standingJump: 225.74,
        handballThrow: 24.79,
        totalScore: 53.97,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.22,
        sitUps: 31.1,
        bending: 52.3,
        sideJump: 58.41,
        shuttleRun: 95.17,
        enduranceRun: 367.71,
        sprintRun: 7.14,
        standingJump: 229.44,
        handballThrow: 26.17,
        totalScore: 57.04,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.31,
        sitUps: 10.22,
        bending: 30.08,
        sideJump: 25.23,
        shuttleRun: 14.63,
        enduranceRun: null,
        sprintRun: 12.01,
        standingJump: 105.09,
        handballThrow: 5.55,
        totalScore: 29.46,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.78,
        sitUps: 12.86,
        bending: 30.94,
        sideJump: 28.79,
        shuttleRun: 21.44,
        enduranceRun: null,
        sprintRun: 11.16,
        standingJump: 116.11,
        handballThrow: 7.24,
        totalScore: 36.58,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.42,
        sitUps: 14.55,
        bending: 33.68,
        sideJump: 32.6,
        shuttleRun: 26.57,
        enduranceRun: null,
        sprintRun: 10.55,
        standingJump: 126.9,
        handballThrow: 9.44,
        totalScore: 42.91,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.49,
        sitUps: 16.4,
        bending: 35.73,
        sideJump: 35.61,
        shuttleRun: 32.18,
        enduranceRun: null,
        sprintRun: 10.05,
        standingJump: 135.04,
        handballThrow: 11.65,
        totalScore: 48.73,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.12,
        sitUps: 17.98,
        bending: 38.31,
        sideJump: 38.99,
        shuttleRun: 38.55,
        enduranceRun: null,
        sprintRun: 9.56,
        standingJump: 145.68,
        handballThrow: 13.84,
        totalScore: 54.79,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.75,
        sitUps: 18.61,
        bending: 39.87,
        sideJump: 41.06,
        shuttleRun: 43.32,
        enduranceRun: null,
        sprintRun: 9.31,
        standingJump: 150.06,
        handballThrow: 15.51,
        totalScore: 58.59,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.56,
        sitUps: 19.11,
        bending: 43.12,
        sideJump: 44.25,
        shuttleRun: 46.34,
        enduranceRun: 321.53,
        sprintRun: 9.25,
        standingJump: 162.69,
        handballThrow: 11.32,
        totalScore: 42.04,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.88,
        sitUps: 21.7,
        bending: 46.13,
        sideJump: 46.33,
        shuttleRun: 54.62,
        enduranceRun: 291.56,
        sprintRun: 8.95,
        standingJump: 171.06,
        handballThrow: 12.91,
        totalScore: 48.52,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.69,
        sitUps: 23.17,
        bending: 48.88,
        sideJump: 48.23,
        shuttleRun: 57.74,
        enduranceRun: 306.52,
        sprintRun: 8.79,
        standingJump: 175.82,
        handballThrow: 13.95,
        totalScore: 52.53,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.16,
        sitUps: 21.62,
        bending: 47.05,
        sideJump: 46.99,
        shuttleRun: 48.04,
        enduranceRun: 321.16,
        sprintRun: 8.93,
        standingJump: 169.38,
        handballThrow: 13.26,
        totalScore: 48.83,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.36,
        sitUps: 23.08,
        bending: 49.5,
        sideJump: 48.28,
        shuttleRun: 52.69,
        enduranceRun: 293.56,
        sprintRun: 8.82,
        standingJump: 173.8,
        handballThrow: 13.76,
        totalScore: 52.17,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.66,
        sitUps: 23.85,
        bending: 50.31,
        sideJump: 48.6,
        shuttleRun: 51.11,
        enduranceRun: 306.51,
        sprintRun: 8.86,
        standingJump: 172.37,
        handballThrow: 14.05,
        totalScore: 52.6,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  兵庫県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.74,
        sitUps: 10.8,
        bending: 25.28,
        sideJump: 26.92,
        shuttleRun: 16.35,
        enduranceRun: null,
        sprintRun: 11.59,
        standingJump: 115.55,
        handballThrow: 7.62,
        totalScore: 29.3,
        height: 116.42,
        weight: 21.07,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.49,
        sitUps: 13.43,
        bending: 27.06,
        sideJump: 30.14,
        shuttleRun: 25.59,
        enduranceRun: null,
        sprintRun: 10.68,
        standingJump: 126.62,
        handballThrow: 10.73,
        totalScore: 36.48,
        height: 122.82,
        weight: 24.02,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.13,
        sitUps: 15.04,
        bending: 28.56,
        sideJump: 33.35,
        shuttleRun: 32.79,
        enduranceRun: null,
        sprintRun: 10.14,
        standingJump: 135.37,
        handballThrow: 14.02,
        totalScore: 41.82,
        height: 128.29,
        weight: 26.83,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.75,
        sitUps: 16.97,
        bending: 30.22,
        sideJump: 36.41,
        shuttleRun: 40.49,
        enduranceRun: null,
        sprintRun: 9.7,
        standingJump: 144.68,
        handballThrow: 17.42,
        totalScore: 47.2,
        height: 133.63,
        weight: 30.14,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.02,
        sitUps: 18.9,
        bending: 32.95,
        sideJump: 40.91,
        shuttleRun: 49.2,
        enduranceRun: null,
        sprintRun: 9.34,
        standingJump: 153.28,
        handballThrow: 21.2,
        totalScore: 53.34,
        height: 139.27,
        weight: 34.1,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.94,
        sitUps: 20.86,
        bending: 34.89,
        sideJump: 44.76,
        shuttleRun: 57.37,
        enduranceRun: null,
        sprintRun: 8.91,
        standingJump: 163.02,
        handballThrow: 24.57,
        totalScore: 59.07,
        height: 145.97,
        weight: 38.59,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.46,
        sitUps: 22.35,
        bending: 38.81,
        sideJump: 48.79,
        shuttleRun: 65.85,
        enduranceRun: null,
        sprintRun: 8.42,
        standingJump: 180.83,
        handballThrow: 17,
        totalScore: 33.16,
        height: 153.67,
        weight: 43.83,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.91,
        sitUps: 25.46,
        bending: 42.5,
        sideJump: 52.49,
        shuttleRun: 79.77,
        enduranceRun: null,
        sprintRun: 7.84,
        standingJump: 198.35,
        handballThrow: 19.93,
        totalScore: 41.32,
        height: 160.94,
        weight: 49.03,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.6,
        sitUps: 27.92,
        bending: 45.48,
        sideJump: 54.62,
        shuttleRun: 88.97,
        enduranceRun: null,
        sprintRun: 7.48,
        standingJump: 213.05,
        handballThrow: 22.53,
        totalScore: 47.78,
        height: 165.84,
        weight: 53.33,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.05,
        sitUps: 27.92,
        bending: 48.09,
        sideJump: 56.48,
        shuttleRun: 83.63,
        enduranceRun: null,
        sprintRun: 7.46,
        standingJump: 219.15,
        handballThrow: 23.29,
        totalScore: 49.86,
        height: 169.05,
        weight: 58.26,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 37.99,
        sitUps: 29.73,
        bending: 50.93,
        sideJump: 57.92,
        shuttleRun: 89.24,
        enduranceRun: null,
        sprintRun: 7.28,
        standingJump: 226.07,
        handballThrow: 24.73,
        totalScore: 53.94,
        height: 170.12,
        weight: 59.44,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 39.99,
        sitUps: 31.64,
        bending: 52.15,
        sideJump: 59.28,
        shuttleRun: 90.44,
        enduranceRun: null,
        sprintRun: 7.15,
        standingJump: 229.77,
        handballThrow: 25.7,
        totalScore: 56.64,
        height: 170.92,
        weight: 61.05,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.28,
        sitUps: 10.63,
        bending: 27.67,
        sideJump: 25.91,
        shuttleRun: 13.84,
        enduranceRun: null,
        sprintRun: 11.94,
        standingJump: 107.16,
        handballThrow: 5.2,
        totalScore: 29.13,
        height: 115.65,
        weight: 20.73,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.94,
        sitUps: 12.79,
        bending: 29.89,
        sideJump: 29.14,
        shuttleRun: 19.93,
        enduranceRun: null,
        sprintRun: 11.05,
        standingJump: 117.65,
        handballThrow: 6.99,
        totalScore: 36.5,
        height: 121.83,
        weight: 23.48,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.61,
        sitUps: 14.65,
        bending: 31.88,
        sideJump: 32.11,
        shuttleRun: 25.03,
        enduranceRun: null,
        sprintRun: 10.52,
        standingJump: 127.88,
        handballThrow: 8.94,
        totalScore: 42.23,
        height: 127.62,
        weight: 26.5,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.23,
        sitUps: 16.42,
        bending: 34.12,
        sideJump: 35.11,
        shuttleRun: 31.09,
        enduranceRun: null,
        sprintRun: 10.05,
        standingJump: 137.19,
        handballThrow: 11.02,
        totalScore: 48.35,
        height: 134.02,
        weight: 30.07,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.86,
        sitUps: 17.81,
        bending: 36.69,
        sideJump: 39,
        shuttleRun: 37.99,
        enduranceRun: null,
        sprintRun: 9.67,
        standingJump: 146.04,
        handballThrow: 13.09,
        totalScore: 54.16,
        height: 140.91,
        weight: 34.44,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.89,
        sitUps: 19.31,
        bending: 39.88,
        sideJump: 41.92,
        shuttleRun: 44.47,
        enduranceRun: null,
        sprintRun: 9.24,
        standingJump: 154.31,
        handballThrow: 15.16,
        totalScore: 59.74,
        height: 147.53,
        weight: 39.22,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.47,
        sitUps: 19.33,
        bending: 42.45,
        sideJump: 44.94,
        shuttleRun: 47.42,
        enduranceRun: null,
        sprintRun: 9.09,
        standingJump: 163.62,
        handballThrow: 11.05,
        totalScore: 42.6,
        height: 152.33,
        weight: 43.21,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.39,
        sitUps: 21.44,
        bending: 44.57,
        sideJump: 46.7,
        shuttleRun: 54.06,
        enduranceRun: null,
        sprintRun: 8.81,
        standingJump: 167.86,
        handballThrow: 12.3,
        totalScore: 47.65,
        height: 154.97,
        weight: 46.32,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.91,
        sitUps: 23.14,
        bending: 46.88,
        sideJump: 47.86,
        shuttleRun: 54.83,
        enduranceRun: null,
        sprintRun: 8.7,
        standingJump: 172.25,
        handballThrow: 13.38,
        totalScore: 51.17,
        height: 156.25,
        weight: 48.42,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.83,
        sitUps: 22.04,
        bending: 47.52,
        sideJump: 48.12,
        shuttleRun: 47.72,
        enduranceRun: null,
        sprintRun: 8.98,
        standingJump: 172.75,
        handballThrow: 13.15,
        totalScore: 49.15,
        height: 157.15,
        weight: 50.51,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.5,
        sitUps: 23.08,
        bending: 49.15,
        sideJump: 48.8,
        shuttleRun: 49.5,
        enduranceRun: null,
        sprintRun: 8.9,
        standingJump: 172.97,
        handballThrow: 13.77,
        totalScore: 51.49,
        height: 157.85,
        weight: 51.36,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 25.99,
        sitUps: 24.34,
        bending: 50.21,
        sideJump: 49.1,
        shuttleRun: 50.33,
        enduranceRun: null,
        sprintRun: 8.88,
        standingJump: 175.85,
        handballThrow: 13.95,
        totalScore: 52.79,
        height: 157.98,
        weight: 51.6,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  千葉県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.68,
        sitUps: 12.15,
        bending: 27.15,
        sideJump: 27.55,
        shuttleRun: 19.67,
        enduranceRun: null,
        sprintRun: 11.22,
        standingJump: 116.24,
        handballThrow: 8.64,
        totalScore: 32.05,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 11.32,
        sitUps: 14.48,
        bending: 29.03,
        sideJump: 31.22,
        shuttleRun: 27.18,
        enduranceRun: null,
        sprintRun: 10.54,
        standingJump: 126.66,
        handballThrow: 11.65,
        totalScore: 38.26,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 13.11,
        sitUps: 16.39,
        bending: 30.47,
        sideJump: 34.47,
        shuttleRun: 33.45,
        enduranceRun: null,
        sprintRun: 10.01,
        standingJump: 136.65,
        handballThrow: 14.67,
        totalScore: 43.81,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.87,
        sitUps: 17.92,
        bending: 32.43,
        sideJump: 37.96,
        shuttleRun: 39.89,
        enduranceRun: null,
        sprintRun: 9.65,
        standingJump: 144.51,
        handballThrow: 17.94,
        totalScore: 48.88,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 17.51,
        sitUps: 20.77,
        bending: 35.91,
        sideJump: 43.06,
        shuttleRun: 49.94,
        enduranceRun: null,
        sprintRun: 9.16,
        standingJump: 156.79,
        handballThrow: 21.22,
        totalScore: 56.15,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 21.47,
        sitUps: 22.43,
        bending: 38.49,
        sideJump: 46.23,
        shuttleRun: 58.11,
        enduranceRun: null,
        sprintRun: 8.69,
        standingJump: 170.59,
        handballThrow: 25.02,
        totalScore: 62.36,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.14,
        sitUps: 22.96,
        bending: 41.45,
        sideJump: 48.1,
        shuttleRun: 63.24,
        enduranceRun: null,
        sprintRun: 8.49,
        standingJump: 180.8,
        handballThrow: 16.98,
        totalScore: 33.73,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.44,
        sitUps: 25.83,
        bending: 45.95,
        sideJump: 51.65,
        shuttleRun: 78.96,
        enduranceRun: null,
        sprintRun: 7.81,
        standingJump: 198.5,
        handballThrow: 19.71,
        totalScore: 42.42,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.14,
        sitUps: 28.48,
        bending: 50.16,
        sideJump: 54.63,
        shuttleRun: 86.65,
        enduranceRun: null,
        sprintRun: 7.41,
        standingJump: 213.41,
        handballThrow: 22.53,
        totalScore: 49.79,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 35.63,
        sitUps: 27.37,
        bending: 46.63,
        sideJump: 53.59,
        shuttleRun: 80.51,
        enduranceRun: null,
        sprintRun: 7.5,
        standingJump: 212.28,
        handballThrow: 21.16,
        totalScore: 47.24,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.43,
        sitUps: 29.47,
        bending: 50.31,
        sideJump: 55.62,
        shuttleRun: 87.36,
        enduranceRun: null,
        sprintRun: 7.28,
        standingJump: 221.87,
        handballThrow: 22.76,
        totalScore: 52.46,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 39.81,
        sitUps: 31.17,
        bending: 52.85,
        sideJump: 56.51,
        shuttleRun: 88.89,
        enduranceRun: null,
        sprintRun: 7.17,
        standingJump: 226.82,
        handballThrow: 25.19,
        totalScore: 55.57,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.09,
        sitUps: 11.46,
        bending: 29.4,
        sideJump: 26.64,
        shuttleRun: 15.91,
        enduranceRun: null,
        sprintRun: 11.6,
        standingJump: 109.09,
        handballThrow: 5.99,
        totalScore: 31.84,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.74,
        sitUps: 14.2,
        bending: 31.88,
        sideJump: 30.3,
        shuttleRun: 21.84,
        enduranceRun: null,
        sprintRun: 10.79,
        standingJump: 119.02,
        handballThrow: 7.84,
        totalScore: 39.27,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.48,
        sitUps: 15.55,
        bending: 34.02,
        sideJump: 33.19,
        shuttleRun: 26.44,
        enduranceRun: null,
        sprintRun: 10.33,
        standingJump: 129.57,
        handballThrow: 9.72,
        totalScore: 44.73,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.44,
        sitUps: 17.25,
        bending: 36.67,
        sideJump: 36.61,
        shuttleRun: 32.2,
        enduranceRun: null,
        sprintRun: 9.89,
        standingJump: 137.49,
        handballThrow: 11.64,
        totalScore: 50.53,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 17.28,
        sitUps: 19.17,
        bending: 40.12,
        sideJump: 40.66,
        shuttleRun: 39.51,
        enduranceRun: null,
        sprintRun: 9.45,
        standingJump: 149.29,
        handballThrow: 13.79,
        totalScore: 57.4,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 20.21,
        sitUps: 20.02,
        bending: 43.09,
        sideJump: 43.21,
        shuttleRun: 43.17,
        enduranceRun: null,
        sprintRun: 9.13,
        standingJump: 156.16,
        handballThrow: 15.38,
        totalScore: 61.74,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.22,
        sitUps: 20.31,
        bending: 44.69,
        sideJump: 44.54,
        shuttleRun: 46.86,
        enduranceRun: null,
        sprintRun: 9.03,
        standingJump: 161.95,
        handballThrow: 11.09,
        totalScore: 43.48,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.42,
        sitUps: 22.27,
        bending: 47.4,
        sideJump: 46,
        shuttleRun: 52.43,
        enduranceRun: null,
        sprintRun: 8.8,
        standingJump: 168.86,
        handballThrow: 12.27,
        totalScore: 48.69,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.23,
        sitUps: 23.59,
        bending: 50.34,
        sideJump: 46.94,
        shuttleRun: 52.44,
        enduranceRun: null,
        sprintRun: 8.69,
        standingJump: 170.98,
        handballThrow: 13.17,
        totalScore: 51.88,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.22,
        sitUps: 22.86,
        bending: 49.16,
        sideJump: 46.34,
        shuttleRun: 48.7,
        enduranceRun: null,
        sprintRun: 8.99,
        standingJump: 166.01,
        handballThrow: 12.44,
        totalScore: 49.19,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.84,
        sitUps: 23.42,
        bending: 48.65,
        sideJump: 47.03,
        shuttleRun: 51.66,
        enduranceRun: null,
        sprintRun: 8.86,
        standingJump: 166,
        handballThrow: 13.3,
        totalScore: 50.63,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.36,
        sitUps: 23.87,
        bending: 50.52,
        sideJump: 46.47,
        shuttleRun: 50.38,
        enduranceRun: null,
        sprintRun: 8.89,
        standingJump: 169.06,
        handballThrow: 13.71,
        totalScore: 51.59,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  和歌山県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.95,
        sitUps: 11.23,
        bending: 26.85,
        sideJump: 27.32,
        shuttleRun: 16.83,
        enduranceRun: null,
        sprintRun: 11.6,
        standingJump: 113.8,
        handballThrow: 7.85,
        totalScore: 29.92,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.57,
        sitUps: 13.9,
        bending: 28.23,
        sideJump: 31.08,
        shuttleRun: 26.27,
        enduranceRun: null,
        sprintRun: 10.75,
        standingJump: 124.25,
        handballThrow: 10.99,
        totalScore: 36.89,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.51,
        sitUps: 15.75,
        bending: 30.28,
        sideJump: 34.4,
        shuttleRun: 32.69,
        enduranceRun: null,
        sprintRun: 10.23,
        standingJump: 134.05,
        handballThrow: 14.52,
        totalScore: 42.67,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.33,
        sitUps: 17.84,
        bending: 32.12,
        sideJump: 38.43,
        shuttleRun: 39.93,
        enduranceRun: null,
        sprintRun: 9.73,
        standingJump: 142.51,
        handballThrow: 17.84,
        totalScore: 48.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.46,
        sitUps: 19.38,
        bending: 34.13,
        sideJump: 41.58,
        shuttleRun: 46.35,
        enduranceRun: null,
        sprintRun: 9.39,
        standingJump: 151.27,
        handballThrow: 21.08,
        totalScore: 53.22,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.34,
        sitUps: 21.22,
        bending: 36.03,
        sideJump: 44.82,
        shuttleRun: 53.36,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 161.99,
        handballThrow: 24.62,
        totalScore: 58.81,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.71,
        sitUps: 23.17,
        bending: 40.77,
        sideJump: 48.78,
        shuttleRun: 60.24,
        enduranceRun: 441.34,
        sprintRun: 8.53,
        standingJump: 178.6,
        handballThrow: 17.53,
        totalScore: 32.78,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.98,
        sitUps: 26.24,
        bending: 44.61,
        sideJump: 52.78,
        shuttleRun: 77.71,
        enduranceRun: 407.42,
        sprintRun: 7.92,
        standingJump: 197.53,
        handballThrow: 20.74,
        totalScore: 41.6,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.65,
        sitUps: 28.45,
        bending: 48.67,
        sideJump: 55.63,
        shuttleRun: 84.84,
        enduranceRun: 390.51,
        sprintRun: 7.56,
        standingJump: 210.56,
        handballThrow: 22.97,
        totalScore: 48.15,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.78,
        sitUps: 28.02,
        bending: 47.72,
        sideJump: 55.86,
        shuttleRun: 81.04,
        enduranceRun: 404.75,
        sprintRun: 7.49,
        standingJump: 216.27,
        handballThrow: 23.18,
        totalScore: 47.98,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.3,
        sitUps: 29.78,
        bending: 50.24,
        sideJump: 57.35,
        shuttleRun: 86.23,
        enduranceRun: 386.66,
        sprintRun: 7.33,
        standingJump: 220.87,
        handballThrow: 24.47,
        totalScore: 51.82,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.77,
        sitUps: 30.9,
        bending: 51.37,
        sideJump: 58.13,
        shuttleRun: 86.46,
        enduranceRun: 384.24,
        sprintRun: 7.21,
        standingJump: 225.86,
        handballThrow: 25.38,
        totalScore: 54.26,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.47,
        sitUps: 11.16,
        bending: 29.2,
        sideJump: 26.71,
        shuttleRun: 14.8,
        enduranceRun: null,
        sprintRun: 11.93,
        standingJump: 107.26,
        handballThrow: 5.59,
        totalScore: 30.51,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.18,
        sitUps: 13.64,
        bending: 31.01,
        sideJump: 30.73,
        shuttleRun: 21.5,
        enduranceRun: null,
        sprintRun: 11.08,
        standingJump: 117.48,
        handballThrow: 7.37,
        totalScore: 37.83,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.86,
        sitUps: 15.53,
        bending: 33.41,
        sideJump: 33.43,
        shuttleRun: 25.93,
        enduranceRun: null,
        sprintRun: 10.56,
        standingJump: 126.81,
        handballThrow: 9.3,
        totalScore: 43.78,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.87,
        sitUps: 17.28,
        bending: 35.67,
        sideJump: 37.29,
        shuttleRun: 31.35,
        enduranceRun: null,
        sprintRun: 10.07,
        standingJump: 135.9,
        handballThrow: 11.38,
        totalScore: 49.81,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.24,
        sitUps: 18.44,
        bending: 38.24,
        sideJump: 40,
        shuttleRun: 38.04,
        enduranceRun: null,
        sprintRun: 9.66,
        standingJump: 144.27,
        handballThrow: 13.46,
        totalScore: 55.03,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.36,
        sitUps: 19.72,
        bending: 40.75,
        sideJump: 42.43,
        shuttleRun: 42.32,
        enduranceRun: null,
        sprintRun: 9.32,
        standingJump: 151.42,
        handballThrow: 15.32,
        totalScore: 59.89,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.23,
        sitUps: 19.98,
        bending: 43.73,
        sideJump: 45.11,
        shuttleRun: 43.52,
        enduranceRun: 324.53,
        sprintRun: 9.14,
        standingJump: 161.46,
        handballThrow: 11.23,
        totalScore: 42.23,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.13,
        sitUps: 21.91,
        bending: 43.73,
        sideJump: 45.11,
        shuttleRun: 43.52,
        enduranceRun: 324.53,
        sprintRun: 9.14,
        standingJump: 161.46,
        handballThrow: 11.23,
        totalScore: 42.23,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.87,
        sitUps: 23.46,
        bending: 49.29,
        sideJump: 48.05,
        shuttleRun: 51.96,
        enduranceRun: 306.25,
        sprintRun: 8.81,
        standingJump: 170.56,
        handballThrow: 13.8,
        totalScore: 51.02,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.18,
        sitUps: 22.07,
        bending: 47.88,
        sideJump: 47.5,
        shuttleRun: 47.03,
        enduranceRun: 325.06,
        sprintRun: 9.02,
        standingJump: 167.59,
        handballThrow: 13.22,
        totalScore: 47.68,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.29,
        sitUps: 23.32,
        bending: 49.15,
        sideJump: 48.21,
        shuttleRun: 49.57,
        enduranceRun: 315.14,
        sprintRun: 8.97,
        standingJump: 168.73,
        handballThrow: 13.78,
        totalScore: 49.93,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.56,
        sitUps: 23.5,
        bending: 50.18,
        sideJump: 48.11,
        shuttleRun: 48.65,
        enduranceRun: 317.9,
        sprintRun: 8.96,
        standingJump: 168.76,
        handballThrow: 14.04,
        totalScore: 50.37,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  埼玉県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.04,
        sitUps: 12.4,
        bending: 27.4,
        sideJump: 28.06,
        shuttleRun: 21.45,
        enduranceRun: null,
        sprintRun: 11.54,
        standingJump: 117.12,
        handballThrow: 7.8,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.71,
        sitUps: 15.16,
        bending: 29.2,
        sideJump: 31.86,
        shuttleRun: 29.97,
        enduranceRun: null,
        sprintRun: 10.72,
        standingJump: 127.48,
        handballThrow: 10.55,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.37,
        sitUps: 17.25,
        bending: 30.99,
        sideJump: 34.9,
        shuttleRun: 37.11,
        enduranceRun: null,
        sprintRun: 10.16,
        standingJump: 137.32,
        handballThrow: 13.5,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.13,
        sitUps: 19.11,
        bending: 33,
        sideJump: 39.1,
        shuttleRun: 43.76,
        enduranceRun: null,
        sprintRun: 9.77,
        standingJump: 145.65,
        handballThrow: 16.38,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.28,
        sitUps: 20.84,
        bending: 35.29,
        sideJump: 42.98,
        shuttleRun: 51.23,
        enduranceRun: null,
        sprintRun: 9.36,
        standingJump: 154.48,
        handballThrow: 19.48,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.13,
        sitUps: 22.61,
        bending: 37.84,
        sideJump: 45.9,
        shuttleRun: 58.45,
        enduranceRun: null,
        sprintRun: 8.96,
        standingJump: 165.64,
        handballThrow: 22.73,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.03,
        sitUps: 24.88,
        bending: 42.91,
        sideJump: 48.4,
        shuttleRun: 69.6,
        enduranceRun: 425,
        sprintRun: 8.56,
        standingJump: 184.26,
        handballThrow: 17.6,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.65,
        sitUps: 28.23,
        bending: 47.86,
        sideJump: 51.89,
        shuttleRun: 79.03,
        enduranceRun: 394.21,
        sprintRun: 7.92,
        standingJump: 202.45,
        handballThrow: 21.1,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.41,
        sitUps: 30.81,
        bending: 51.74,
        sideJump: 55.15,
        shuttleRun: 85.98,
        enduranceRun: 375.06,
        sprintRun: 7.5,
        standingJump: 216.09,
        handballThrow: 23.93,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.36,
        sitUps: 29.41,
        bending: 48.96,
        sideJump: 56.66,
        shuttleRun: 81.28,
        enduranceRun: 376.35,
        sprintRun: 7.5,
        standingJump: 220.27,
        handballThrow: 23.23,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.46,
        sitUps: 31.22,
        bending: 50.53,
        sideJump: 57.79,
        shuttleRun: 84.58,
        enduranceRun: 364.93,
        sprintRun: 7.3,
        standingJump: 227,
        handballThrow: 24.69,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.04,
        sitUps: 32.31,
        bending: 51.95,
        sideJump: 58.85,
        shuttleRun: 86.99,
        enduranceRun: 361.27,
        sprintRun: 7.2,
        standingJump: 230.87,
        handballThrow: 25.82,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.59,
        sitUps: 11.96,
        bending: 29.88,
        sideJump: 26.95,
        shuttleRun: 18.26,
        enduranceRun: null,
        sprintRun: 11.82,
        standingJump: 109.81,
        handballThrow: 5.64,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.21,
        sitUps: 14.8,
        bending: 32.3,
        sideJump: 30.61,
        shuttleRun: 24.37,
        enduranceRun: null,
        sprintRun: 11.02,
        standingJump: 120.37,
        handballThrow: 7.41,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.81,
        sitUps: 16.71,
        bending: 34.57,
        sideJump: 33.54,
        shuttleRun: 29.55,
        enduranceRun: null,
        sprintRun: 10.44,
        standingJump: 130.4,
        handballThrow: 9.23,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.72,
        sitUps: 18.5,
        bending: 37.27,
        sideJump: 37.41,
        shuttleRun: 35.46,
        enduranceRun: null,
        sprintRun: 10,
        standingJump: 139.8,
        handballThrow: 11.17,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.26,
        sitUps: 20.08,
        bending: 40.29,
        sideJump: 41.05,
        shuttleRun: 42.45,
        enduranceRun: null,
        sprintRun: 9.55,
        standingJump: 149.33,
        handballThrow: 13.17,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.04,
        sitUps: 21.16,
        bending: 43.25,
        sideJump: 43.51,
        shuttleRun: 47.22,
        enduranceRun: null,
        sprintRun: 9.21,
        standingJump: 157.22,
        handballThrow: 15.03,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.65,
        sitUps: 22.14,
        bending: 48.4,
        sideJump: 44.91,
        shuttleRun: 52.56,
        enduranceRun: 303.18,
        sprintRun: 9.05,
        standingJump: 167.33,
        handballThrow: 11.64,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 24.12,
        sitUps: 24.84,
        bending: 51.89,
        sideJump: 46.82,
        shuttleRun: 56.2,
        enduranceRun: 290.89,
        sprintRun: 8.76,
        standingJump: 173.98,
        handballThrow: 13.39,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.79,
        sitUps: 26.59,
        bending: 55.15,
        sideJump: 48.19,
        shuttleRun: 58.16,
        enduranceRun: 289.79,
        sprintRun: 8.62,
        standingJump: 177.05,
        handballThrow: 14.72,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.47,
        sitUps: 24.57,
        bending: 50.33,
        sideJump: 49.2,
        shuttleRun: 54.49,
        enduranceRun: 290.7,
        sprintRun: 8.82,
        standingJump: 175.52,
        handballThrow: 13.73,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.34,
        sitUps: 25.78,
        bending: 51.2,
        sideJump: 49.43,
        shuttleRun: 55.87,
        enduranceRun: 285.99,
        sprintRun: 8.74,
        standingJump: 177.29,
        handballThrow: 14.26,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.69,
        sitUps: 26.3,
        bending: 52.05,
        sideJump: 49.74,
        shuttleRun: 55.92,
        enduranceRun: 286.98,
        sprintRun: 8.74,
        standingJump: 177.82,
        handballThrow: 14.55,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  大分県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.26,
        sitUps: 12.39,
        bending: 27.92,
        sideJump: 27.42,
        shuttleRun: 19.21,
        enduranceRun: null,
        sprintRun: 11.76,
        standingJump: 117.39,
        handballThrow: 8.68,
        totalScore: 31.69,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.86,
        sitUps: 14.96,
        bending: 29.95,
        sideJump: 31.37,
        shuttleRun: 29.17,
        enduranceRun: null,
        sprintRun: 10.75,
        standingJump: 128.62,
        handballThrow: 12.22,
        totalScore: 38.97,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.57,
        sitUps: 16.85,
        bending: 31.88,
        sideJump: 35.78,
        shuttleRun: 37.6,
        enduranceRun: null,
        sprintRun: 10.24,
        standingJump: 138.46,
        handballThrow: 15.53,
        totalScore: 44.81,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.85,
        sitUps: 18.9,
        bending: 34.27,
        sideJump: 40.04,
        shuttleRun: 45.19,
        enduranceRun: null,
        sprintRun: 9.74,
        standingJump: 146.96,
        handballThrow: 19.32,
        totalScore: 50.91,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.89,
        sitUps: 20.27,
        bending: 36.21,
        sideJump: 43.43,
        shuttleRun: 51.07,
        enduranceRun: null,
        sprintRun: 9.44,
        standingJump: 154.05,
        handballThrow: 22.61,
        totalScore: 55.45,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.86,
        sitUps: 22.11,
        bending: 37.73,
        sideJump: 45.91,
        shuttleRun: 58.14,
        enduranceRun: null,
        sprintRun: 9.05,
        standingJump: 164.2,
        handballThrow: 25.86,
        totalScore: 60.37,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.64,
        sitUps: 24.28,
        bending: 43.11,
        sideJump: 49.93,
        shuttleRun: 66.84,
        enduranceRun: null,
        sprintRun: 8.61,
        standingJump: 184.56,
        handballThrow: 18.91,
        totalScore: 36.23,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.66,
        sitUps: 26.32,
        bending: 44.53,
        sideJump: 52.87,
        shuttleRun: 79.98,
        enduranceRun: null,
        sprintRun: 7.98,
        standingJump: 200.58,
        handballThrow: 21.9,
        totalScore: 43.36,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.96,
        sitUps: 29.2,
        bending: 49.15,
        sideJump: 56.74,
        shuttleRun: 89.27,
        enduranceRun: null,
        sprintRun: 7.53,
        standingJump: 216.16,
        handballThrow: 24.83,
        totalScore: 51.04,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.54,
        sitUps: 28.05,
        bending: 45.35,
        sideJump: 54.21,
        shuttleRun: 80.55,
        enduranceRun: null,
        sprintRun: 7.61,
        standingJump: 215.64,
        handballThrow: 22.98,
        totalScore: 47.63,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.75,
        sitUps: 29.94,
        bending: 47.62,
        sideJump: 56.33,
        shuttleRun: 87.51,
        enduranceRun: null,
        sprintRun: 7.35,
        standingJump: 222.65,
        handballThrow: 24.4,
        totalScore: 51.88,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.53,
        sitUps: 30.92,
        bending: 48.69,
        sideJump: 57,
        shuttleRun: 89.11,
        enduranceRun: null,
        sprintRun: 7.29,
        standingJump: 227.11,
        handballThrow: 25.28,
        totalScore: 54.01,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.76,
        sitUps: 12.16,
        bending: 29.76,
        sideJump: 26.87,
        shuttleRun: 17.01,
        enduranceRun: null,
        sprintRun: 12.01,
        standingJump: 110.67,
        handballThrow: 6.26,
        totalScore: 32.32,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.25,
        sitUps: 14.44,
        bending: 32.12,
        sideJump: 30.65,
        shuttleRun: 23.33,
        enduranceRun: null,
        sprintRun: 11.05,
        standingJump: 120.61,
        handballThrow: 8.21,
        totalScore: 39.57,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.59,
        sitUps: 16.29,
        bending: 34.84,
        sideJump: 34.41,
        shuttleRun: 29.75,
        enduranceRun: null,
        sprintRun: 10.52,
        standingJump: 130.41,
        handballThrow: 10.24,
        totalScore: 45.66,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.15,
        sitUps: 18.03,
        bending: 37.47,
        sideJump: 37.89,
        shuttleRun: 35.6,
        enduranceRun: null,
        sprintRun: 10,
        standingJump: 139.77,
        handballThrow: 12.57,
        totalScore: 51.98,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.61,
        sitUps: 19.23,
        bending: 40.11,
        sideJump: 41.39,
        shuttleRun: 41.49,
        enduranceRun: null,
        sprintRun: 9.65,
        standingJump: 147.66,
        handballThrow: 14.75,
        totalScore: 57.22,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.44,
        sitUps: 20.31,
        bending: 42.61,
        sideJump: 43.3,
        shuttleRun: 45.24,
        enduranceRun: null,
        sprintRun: 9.38,
        standingJump: 153.96,
        handballThrow: 16.31,
        totalScore: 61.33,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.83,
        sitUps: 20.33,
        bending: 45.48,
        sideJump: 45.72,
        shuttleRun: 47.37,
        enduranceRun: null,
        sprintRun: 9.17,
        standingJump: 165.72,
        handballThrow: 12.03,
        totalScore: 45.09,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.77,
        sitUps: 21.61,
        bending: 47.16,
        sideJump: 46.9,
        shuttleRun: 52.13,
        enduranceRun: null,
        sprintRun: 8.92,
        standingJump: 169.23,
        handballThrow: 13.18,
        totalScore: 49.18,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.61,
        sitUps: 23.25,
        bending: 50.21,
        sideJump: 48.91,
        shuttleRun: 54.7,
        enduranceRun: null,
        sprintRun: 8.78,
        standingJump: 174.52,
        handballThrow: 14.47,
        totalScore: 53.32,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.09,
        sitUps: 21.37,
        bending: 46.9,
        sideJump: 47.25,
        shuttleRun: 46.89,
        enduranceRun: null,
        sprintRun: 9.02,
        standingJump: 169.22,
        handballThrow: 13.19,
        totalScore: 48.18,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.81,
        sitUps: 22.06,
        bending: 48,
        sideJump: 47.61,
        shuttleRun: 48.56,
        enduranceRun: null,
        sprintRun: 8.98,
        standingJump: 170.6,
        handballThrow: 13.8,
        totalScore: 49.62,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.37,
        sitUps: 22.68,
        bending: 48.54,
        sideJump: 47.48,
        shuttleRun: 48.78,
        enduranceRun: null,
        sprintRun: 8.98,
        standingJump: 170.6,
        handballThrow: 13.8,
        totalScore: 50.23,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  宮城県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.12,
        sitUps: 11.47,
        bending: 27.04,
        sideJump: 26.54,
        shuttleRun: 17.58,
        enduranceRun: null,
        sprintRun: 11.8,
        standingJump: 110.57,
        handballThrow: 7.89,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.66,
        sitUps: 13.76,
        bending: 27.82,
        sideJump: 30.16,
        shuttleRun: 25.56,
        enduranceRun: null,
        sprintRun: 10.98,
        standingJump: 121.7,
        handballThrow: 10.95,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.33,
        sitUps: 15.37,
        bending: 29.79,
        sideJump: 33.19,
        shuttleRun: 31.19,
        enduranceRun: null,
        sprintRun: 10.43,
        standingJump: 130.59,
        handballThrow: 14.08,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.11,
        sitUps: 16.9,
        bending: 31.68,
        sideJump: 36.79,
        shuttleRun: 36.99,
        enduranceRun: null,
        sprintRun: 10,
        standingJump: 138.05,
        handballThrow: 17.39,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.2,
        sitUps: 18.52,
        bending: 33.78,
        sideJump: 40.62,
        shuttleRun: 43.03,
        enduranceRun: null,
        sprintRun: 9.65,
        standingJump: 147.23,
        handballThrow: 20.53,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.53,
        sitUps: 20.56,
        bending: 35.75,
        sideJump: 43.98,
        shuttleRun: 50.72,
        enduranceRun: null,
        sprintRun: 9.21,
        standingJump: 159.16,
        handballThrow: 24.34,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.99,
        sitUps: 22.61,
        bending: 40.54,
        sideJump: 47.99,
        shuttleRun: 61.32,
        enduranceRun: 457.55,
        sprintRun: 8.7,
        standingJump: 179.56,
        handballThrow: 16.73,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.5,
        sitUps: 26.17,
        bending: 45.02,
        sideJump: 51.26,
        shuttleRun: 75.28,
        enduranceRun: 433.03,
        sprintRun: 8.08,
        standingJump: 196.08,
        handballThrow: 19.7,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.04,
        sitUps: 28.2,
        bending: 48.89,
        sideJump: 54.5,
        shuttleRun: 83.63,
        enduranceRun: 408.54,
        sprintRun: 7.66,
        standingJump: 209.91,
        handballThrow: 22.23,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.6,
        sitUps: 28.2,
        bending: 48.89,
        sideJump: 56.82,
        shuttleRun: 79.92,
        enduranceRun: 402.17,
        sprintRun: 7.54,
        standingJump: 218.26,
        handballThrow: 22.89,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.59,
        sitUps: 29.53,
        bending: 50.87,
        sideJump: 57.56,
        shuttleRun: 84.87,
        enduranceRun: 394.32,
        sprintRun: 7.39,
        standingJump: 223.37,
        handballThrow: 24.41,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.37,
        sitUps: 30.68,
        bending: 51.83,
        sideJump: 58.46,
        shuttleRun: 84.71,
        enduranceRun: 403.06,
        sprintRun: 7.34,
        standingJump: 226.88,
        handballThrow: 25.23,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.63,
        sitUps: 11.26,
        bending: 28.96,
        sideJump: 25.95,
        shuttleRun: 15.09,
        enduranceRun: null,
        sprintRun: 12.07,
        standingJump: 103.96,
        handballThrow: 5.45,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.02,
        sitUps: 13.13,
        bending: 30.35,
        sideJump: 29.22,
        shuttleRun: 20.38,
        enduranceRun: null,
        sprintRun: 11.3,
        standingJump: 113.73,
        handballThrow: 7.18,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.79,
        sitUps: 15.06,
        bending: 32.91,
        sideJump: 32.11,
        shuttleRun: 24.97,
        enduranceRun: null,
        sprintRun: 10.68,
        standingJump: 123.55,
        handballThrow: 9.15,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.76,
        sitUps: 16.5,
        bending: 35.67,
        sideJump: 35.67,
        shuttleRun: 29.37,
        enduranceRun: null,
        sprintRun: 10.2,
        standingJump: 131.91,
        handballThrow: 11.11,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.3,
        sitUps: 18.03,
        bending: 38.06,
        sideJump: 39.07,
        shuttleRun: 35.65,
        enduranceRun: null,
        sprintRun: 9.8,
        standingJump: 141.58,
        handballThrow: 13.02,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.09,
        sitUps: 18.7,
        bending: 40.34,
        sideJump: 41.35,
        shuttleRun: 39.1,
        enduranceRun: null,
        sprintRun: 9.49,
        standingJump: 148.36,
        handballThrow: 14.68,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.05,
        sitUps: 19.17,
        bending: 43.32,
        sideJump: 43.97,
        shuttleRun: 43.04,
        enduranceRun: 324.7,
        sprintRun: 9.29,
        standingJump: 160.19,
        handballThrow: 10.27,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.01,
        sitUps: 21.42,
        bending: 46.15,
        sideJump: 45.5,
        shuttleRun: 48.91,
        enduranceRun: 314.05,
        sprintRun: 9.07,
        standingJump: 164.15,
        handballThrow: 11.74,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.61,
        sitUps: 23.33,
        bending: 48.87,
        sideJump: 46.74,
        shuttleRun: 51.22,
        enduranceRun: 316.34,
        sprintRun: 8.91,
        standingJump: 167.78,
        handballThrow: 12.79,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.93,
        sitUps: 22.09,
        bending: 48.42,
        sideJump: 47.9,
        shuttleRun: 46.04,
        enduranceRun: 309.38,
        sprintRun: 9.03,
        standingJump: 169.39,
        handballThrow: 12.69,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.67,
        sitUps: 22.97,
        bending: 49.32,
        sideJump: 48.14,
        shuttleRun: 45.82,
        enduranceRun: 308.1,
        sprintRun: 9.02,
        standingJump: 169.95,
        handballThrow: 13.2,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.26,
        sitUps: 23.82,
        bending: 50.2,
        sideJump: 48.68,
        shuttleRun: 45.8,
        enduranceRun: 312.33,
        sprintRun: 9.04,
        standingJump: 171.53,
        handballThrow: 13.67,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  宮崎県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.72,
        sitUps: 10.99,
        bending: 27.57,
        sideJump: 27.66,
        shuttleRun: 18.02,
        enduranceRun: null,
        sprintRun: 11.75,
        standingJump: 113.81,
        handballThrow: 8.44,
        totalScore: 30.27,
        height: 116.57,
        weight: 21.75,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.32,
        sitUps: 13.49,
        bending: 28.29,
        sideJump: 31.35,
        shuttleRun: 26.4,
        enduranceRun: null,
        sprintRun: 10.87,
        standingJump: 125.77,
        handballThrow: 11.71,
        totalScore: 36.9,
        height: 122.48,
        weight: 24.65,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.03,
        sitUps: 15.71,
        bending: 30.78,
        sideJump: 34.78,
        shuttleRun: 33.76,
        enduranceRun: null,
        sprintRun: 10.28,
        standingJump: 134.59,
        handballThrow: 14.85,
        totalScore: 42.77,
        height: 128.05,
        weight: 28.09,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.92,
        sitUps: 17.32,
        bending: 32.25,
        sideJump: 38.08,
        shuttleRun: 40.54,
        enduranceRun: null,
        sprintRun: 9.84,
        standingJump: 143.02,
        handballThrow: 18.41,
        totalScore: 47.93,
        height: 133.59,
        weight: 31.86,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.17,
        sitUps: 19.31,
        bending: 34.49,
        sideJump: 41.75,
        shuttleRun: 48.81,
        enduranceRun: null,
        sprintRun: 9.55,
        standingJump: 152.16,
        handballThrow: 21.64,
        totalScore: 53.34,
        height: 139.21,
        weight: 35.8,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.44,
        sitUps: 21.02,
        bending: 36.68,
        sideJump: 44.79,
        shuttleRun: 55.74,
        enduranceRun: null,
        sprintRun: 9.1,
        standingJump: 161.77,
        handballThrow: 25.27,
        totalScore: 58.99,
        height: 145.74,
        weight: 40.46,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.37,
        sitUps: 23.38,
        bending: 42.02,
        sideJump: 48.84,
        shuttleRun: 65.75,
        enduranceRun: 451.59,
        sprintRun: 8.72,
        standingJump: 181.57,
        handballThrow: 18.43,
        totalScore: 34.6,
        height: 153.67,
        weight: 46.03,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.84,
        sitUps: 26.37,
        bending: 45.67,
        sideJump: 52.31,
        shuttleRun: 81.52,
        enduranceRun: 409.66,
        sprintRun: 8.04,
        standingJump: 199.92,
        handballThrow: 21.52,
        totalScore: 43.07,
        height: 160.44,
        weight: 50.8,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.7,
        sitUps: 28.56,
        bending: 49.96,
        sideJump: 55.1,
        shuttleRun: 90.43,
        enduranceRun: 400.01,
        sprintRun: 7.61,
        standingJump: 215.86,
        handballThrow: 24.48,
        totalScore: 50.35,
        height: 165.2,
        weight: 55.3,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.96,
        sitUps: 28.19,
        bending: 50.47,
        sideJump: 57,
        shuttleRun: 84.11,
        enduranceRun: 415.58,
        sprintRun: 7.42,
        standingJump: 222.54,
        handballThrow: 25.5,
        totalScore: 52.12,
        height: 167.88,
        weight: 59.41,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 40.11,
        sitUps: 29.73,
        bending: 52.47,
        sideJump: 58.12,
        shuttleRun: 90.44,
        enduranceRun: 370.91,
        sprintRun: 7.24,
        standingJump: 228.33,
        handballThrow: 27.08,
        totalScore: 55.98,
        height: 169.15,
        weight: 60.48,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.83,
        sitUps: 30.69,
        bending: 53.59,
        sideJump: 59.05,
        shuttleRun: 94.41,
        enduranceRun: 369.77,
        sprintRun: 7.17,
        standingJump: 230.94,
        handballThrow: 28.05,
        totalScore: 58,
        height: 169.82,
        weight: 62.51,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: 40.06,
        sitUps: 23.39,
        bending: 39.94,
        sideJump: 48.56,
        shuttleRun: 51.82,
        enduranceRun: null,
        sprintRun: 7.88,
        standingJump: 212.17,
        handballThrow: 25.22,
        totalScore: 42.5,
        height: 168.18,
        weight: 68.93,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.23,
        sitUps: 10.65,
        bending: 29.3,
        sideJump: 27.09,
        shuttleRun: 15.32,
        enduranceRun: null,
        sprintRun: 12.06,
        standingJump: 105.91,
        handballThrow: 5.68,
        totalScore: 30.16,
        height: 115.64,
        weight: 21.28,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.74,
        sitUps: 12.96,
        bending: 30.6,
        sideJump: 30.41,
        shuttleRun: 20.82,
        enduranceRun: null,
        sprintRun: 11.17,
        standingJump: 116.4,
        handballThrow: 7.47,
        totalScore: 36.97,
        height: 121.59,
        weight: 24.13,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.38,
        sitUps: 15.04,
        bending: 33.46,
        sideJump: 33.74,
        shuttleRun: 26.39,
        enduranceRun: null,
        sprintRun: 10.49,
        standingJump: 126.08,
        handballThrow: 9.51,
        totalScore: 43.47,
        height: 127.64,
        weight: 27.36,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.33,
        sitUps: 16.69,
        bending: 35.59,
        sideJump: 36.76,
        shuttleRun: 31.89,
        enduranceRun: null,
        sprintRun: 10.06,
        standingJump: 136.37,
        handballThrow: 11.84,
        totalScore: 49.32,
        height: 134,
        weight: 31.29,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.98,
        sitUps: 18.26,
        bending: 38.07,
        sideJump: 40.26,
        shuttleRun: 39.22,
        enduranceRun: null,
        sprintRun: 9.7,
        standingJump: 144.89,
        handballThrow: 14.05,
        totalScore: 55.11,
        height: 141.17,
        weight: 35.99,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.03,
        sitUps: 19.25,
        bending: 40.61,
        sideJump: 42.28,
        shuttleRun: 43.08,
        enduranceRun: null,
        sprintRun: 9.32,
        standingJump: 150.69,
        handballThrow: 15.65,
        totalScore: 59.63,
        height: 147.48,
        weight: 40.96,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.55,
        sitUps: 20,
        bending: 43.68,
        sideJump: 44.66,
        shuttleRun: 46.94,
        enduranceRun: 325.91,
        sprintRun: 9.25,
        standingJump: 162.05,
        handballThrow: 11.52,
        totalScore: 43.12,
        height: 151.87,
        weight: 44.88,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.67,
        sitUps: 21.76,
        bending: 46.18,
        sideJump: 46.26,
        shuttleRun: 53.27,
        enduranceRun: 302.71,
        sprintRun: 9,
        standingJump: 167.86,
        handballThrow: 12.75,
        totalScore: 48.19,
        height: 154.27,
        weight: 47.81,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.19,
        sitUps: 22.85,
        bending: 48.34,
        sideJump: 47.6,
        shuttleRun: 55.23,
        enduranceRun: 309.81,
        sprintRun: 8.87,
        standingJump: 172.14,
        handballThrow: 13.92,
        totalScore: 51.52,
        height: 155.53,
        weight: 50.01,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.6,
        sitUps: 21.74,
        bending: 48.92,
        sideJump: 47.86,
        shuttleRun: 49.25,
        enduranceRun: 325.91,
        sprintRun: 8.92,
        standingJump: 172.09,
        handballThrow: 14.05,
        totalScore: 50.65,
        height: 156.21,
        weight: 51.53,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.56,
        sitUps: 22.71,
        bending: 50.31,
        sideJump: 48.56,
        shuttleRun: 52.35,
        enduranceRun: 300.18,
        sprintRun: 8.85,
        standingJump: 174.76,
        handballThrow: 15,
        totalScore: 53.17,
        height: 156.77,
        weight: 52.33,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 27.08,
        sitUps: 23.37,
        bending: 51.23,
        sideJump: 48.68,
        shuttleRun: 52.1,
        enduranceRun: 301.56,
        sprintRun: 8.86,
        standingJump: 173.88,
        handballThrow: 15.22,
        totalScore: 53.64,
        height: 157.18,
        weight: 53.14,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: 27.71,
        sitUps: 14.33,
        bending: 40.57,
        sideJump: 42.2,
        shuttleRun: 29.67,
        enduranceRun: null,
        sprintRun: 9.54,
        standingJump: 161.43,
        handballThrow: 13.57,
        totalScore: 41.4,
        height: 157.25,
        weight: 51.58,
      },
    ],
  },
  山口県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.8,
        sitUps: 10.9,
        bending: 26,
        sideJump: 25.5,
        shuttleRun: 17.1,
        enduranceRun: null,
        sprintRun: 11.6,
        standingJump: 113.9,
        handballThrow: 7.4,
        totalScore: 28.5,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.8,
        sitUps: 12,
        bending: 26.4,
        sideJump: 27.5,
        shuttleRun: 25.8,
        enduranceRun: null,
        sprintRun: 10.9,
        standingJump: 121.3,
        handballThrow: 10.2,
        totalScore: 33.9,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 1.6,
        sitUps: 14.8,
        bending: 28.3,
        sideJump: 31,
        shuttleRun: 32.9,
        enduranceRun: null,
        sprintRun: 10.3,
        standingJump: 133.8,
        handballThrow: 13.7,
        totalScore: 40.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.1,
        sitUps: 16.9,
        bending: 30.3,
        sideJump: 35.6,
        shuttleRun: 41.3,
        enduranceRun: null,
        sprintRun: 9.9,
        standingJump: 140.7,
        handballThrow: 16.6,
        totalScore: 45.6,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.4,
        sitUps: 17.6,
        bending: 32.5,
        sideJump: 38.6,
        shuttleRun: 45.6,
        enduranceRun: null,
        sprintRun: 9.6,
        standingJump: 148.9,
        handballThrow: 19.5,
        totalScore: 50.5,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18,
        sitUps: 19.9,
        bending: 33.7,
        sideJump: 42.1,
        shuttleRun: 56.3,
        enduranceRun: null,
        sprintRun: 9.1,
        standingJump: 158.5,
        handballThrow: 22.8,
        totalScore: 56.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.2,
        sitUps: 22.2,
        bending: 38.1,
        sideJump: 48.4,
        shuttleRun: 64.2,
        enduranceRun: 425,
        sprintRun: 8.6,
        standingJump: 176.1,
        handballThrow: 16.6,
        totalScore: 31.9,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.4,
        sitUps: 24.8,
        bending: 41,
        sideJump: 51.5,
        shuttleRun: 79.2,
        enduranceRun: 399.1,
        sprintRun: 8,
        standingJump: 191.4,
        handballThrow: 19.1,
        totalScore: 39.3,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.6,
        sitUps: 27.9,
        bending: 46.3,
        sideJump: 55.4,
        shuttleRun: 90.3,
        enduranceRun: 396.6,
        sprintRun: 7.5,
        standingJump: 208.3,
        handballThrow: 22.2,
        totalScore: 47.5,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.3,
        sitUps: 28.3,
        bending: 47.3,
        sideJump: 56.5,
        shuttleRun: 88.2,
        enduranceRun: 380.7,
        sprintRun: 7.5,
        standingJump: 213.1,
        handballThrow: 22.2,
        totalScore: 49.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 40.1,
        sitUps: 30.4,
        bending: 49.5,
        sideJump: 58.2,
        shuttleRun: 98.8,
        enduranceRun: 359.7,
        sprintRun: 7.3,
        standingJump: 220.7,
        handballThrow: 23.9,
        totalScore: 54,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.6,
        sitUps: 32.2,
        bending: 51.6,
        sideJump: 59.7,
        shuttleRun: 100,
        enduranceRun: 368,
        sprintRun: 7.2,
        standingJump: 226.6,
        handballThrow: 24.7,
        totalScore: 56.8,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.2,
        sitUps: 10.7,
        bending: 27.4,
        sideJump: 24.7,
        shuttleRun: 15.2,
        enduranceRun: null,
        sprintRun: 11.9,
        standingJump: 106.8,
        handballThrow: 5.2,
        totalScore: 29,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.2,
        sitUps: 12,
        bending: 28.9,
        sideJump: 27.1,
        shuttleRun: 21.2,
        enduranceRun: null,
        sprintRun: 11.1,
        standingJump: 113.1,
        handballThrow: 6.7,
        totalScore: 34.7,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11,
        sitUps: 14.2,
        bending: 31.2,
        sideJump: 30.1,
        shuttleRun: 27.1,
        enduranceRun: null,
        sprintRun: 10.6,
        standingJump: 124.6,
        handballThrow: 8.8,
        totalScore: 41,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 12.7,
        sitUps: 15.9,
        bending: 33.6,
        sideJump: 33.7,
        shuttleRun: 32.8,
        enduranceRun: null,
        sprintRun: 10.2,
        standingJump: 133.3,
        handballThrow: 10.6,
        totalScore: 46.5,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.2,
        sitUps: 17.1,
        bending: 36.5,
        sideJump: 37.9,
        shuttleRun: 41.3,
        enduranceRun: null,
        sprintRun: 9.7,
        standingJump: 144.5,
        handballThrow: 13.1,
        totalScore: 53.7,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 17.9,
        sitUps: 18.1,
        bending: 38.4,
        sideJump: 40.2,
        shuttleRun: 45.7,
        enduranceRun: null,
        sprintRun: 9.4,
        standingJump: 150.3,
        handballThrow: 15,
        totalScore: 57.9,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 20.9,
        sitUps: 19.3,
        bending: 41.5,
        sideJump: 45,
        shuttleRun: 48.3,
        enduranceRun: 295.9,
        sprintRun: 9.1,
        standingJump: 160.7,
        handballThrow: 10.8,
        totalScore: 54,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23,
        sitUps: 21.9,
        bending: 44.4,
        sideJump: 47.1,
        shuttleRun: 54.8,
        enduranceRun: 290.9,
        sprintRun: 8.9,
        standingJump: 163.9,
        handballThrow: 12.2,
        totalScore: 47.4,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.9,
        sitUps: 23.4,
        bending: 46,
        sideJump: 48,
        shuttleRun: 57.5,
        enduranceRun: 302.8,
        sprintRun: 8.8,
        standingJump: 169.7,
        handballThrow: 13.1,
        totalScore: 50.9,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 15.7,
        sitUps: 22.9,
        bending: 47.2,
        sideJump: 48.2,
        shuttleRun: 51.8,
        enduranceRun: 301.7,
        sprintRun: 9,
        standingJump: 168.9,
        handballThrow: 12.4,
        totalScore: 49.5,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.5,
        sitUps: 24.2,
        bending: 48.9,
        sideJump: 48.7,
        shuttleRun: 56.3,
        enduranceRun: 305.7,
        sprintRun: 8.9,
        standingJump: 171.5,
        handballThrow: 13.2,
        totalScore: 52,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 27.1,
        sitUps: 24.9,
        bending: 49.6,
        sideJump: 49.2,
        shuttleRun: 55.6,
        enduranceRun: 302.8,
        sprintRun: 8.9,
        standingJump: 172.6,
        handballThrow: 13.4,
        totalScore: 52.8,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  山形県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.05,
        sitUps: 11.59,
        bending: 26.68,
        sideJump: 27.09,
        shuttleRun: 19.36,
        enduranceRun: null,
        sprintRun: 11.91,
        standingJump: 114.14,
        handballThrow: 8.42,
        totalScore: 30.23,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.77,
        sitUps: 14.28,
        bending: 27.76,
        sideJump: 31.73,
        shuttleRun: 28.28,
        enduranceRun: null,
        sprintRun: 10.99,
        standingJump: 125.19,
        handballThrow: 11.74,
        totalScore: 37.26,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.59,
        sitUps: 15.79,
        bending: 29.92,
        sideJump: 34.19,
        shuttleRun: 34.07,
        enduranceRun: null,
        sprintRun: 10.43,
        standingJump: 135.12,
        handballThrow: 14.68,
        totalScore: 42.39,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.46,
        sitUps: 17.28,
        bending: 31.15,
        sideJump: 38.24,
        shuttleRun: 40.26,
        enduranceRun: null,
        sprintRun: 10.09,
        standingJump: 142.47,
        handballThrow: 17.84,
        totalScore: 47.22,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.48,
        sitUps: 18.86,
        bending: 32.84,
        sideJump: 41.97,
        shuttleRun: 47.67,
        enduranceRun: null,
        sprintRun: 9.68,
        standingJump: 151.62,
        handballThrow: 21.1,
        totalScore: 52.43,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.5,
        sitUps: 20.86,
        bending: 34.57,
        sideJump: 44.7,
        shuttleRun: 55.46,
        enduranceRun: null,
        sprintRun: 9.29,
        standingJump: 162.19,
        handballThrow: 24.99,
        totalScore: 57.78,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.44,
        sitUps: 22.18,
        bending: 40.26,
        sideJump: 47.43,
        shuttleRun: 63.33,
        enduranceRun: 446.68,
        sprintRun: 8.7,
        standingJump: 180.64,
        handballThrow: 17.38,
        totalScore: 32.58,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.78,
        sitUps: 25.33,
        bending: 44.36,
        sideJump: 50.37,
        shuttleRun: 77.95,
        enduranceRun: 407.29,
        sprintRun: 8.05,
        standingJump: 197.29,
        handballThrow: 20.59,
        totalScore: 41,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.31,
        sitUps: 27.97,
        bending: 47.75,
        sideJump: 53.5,
        shuttleRun: 85.91,
        enduranceRun: 389.61,
        sprintRun: 7.64,
        standingJump: 221.46,
        handballThrow: 23.02,
        totalScore: 47.83,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.26,
        sitUps: 28.04,
        bending: 47.2,
        sideJump: 55.38,
        shuttleRun: 84.82,
        enduranceRun: 412.53,
        sprintRun: 7.52,
        standingJump: 217.68,
        handballThrow: 23.5,
        totalScore: 49.12,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.54,
        sitUps: 29.82,
        bending: 49.86,
        sideJump: 57.11,
        shuttleRun: 89.23,
        enduranceRun: 406.79,
        sprintRun: 7.41,
        standingJump: 223.07,
        handballThrow: 24.75,
        totalScore: 52.81,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.23,
        sitUps: 30.76,
        bending: 51.43,
        sideJump: 58.11,
        shuttleRun: 88.67,
        enduranceRun: 407.04,
        sprintRun: 7.3,
        standingJump: 228.06,
        handballThrow: 26.09,
        totalScore: 55.27,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.52,
        sitUps: 11.6,
        bending: 28.85,
        sideJump: 26.42,
        shuttleRun: 16.72,
        enduranceRun: null,
        sprintRun: 12.19,
        standingJump: 107.51,
        handballThrow: 5.86,
        totalScore: 30.8,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.07,
        sitUps: 13.77,
        bending: 30.51,
        sideJump: 30.77,
        shuttleRun: 22.93,
        enduranceRun: null,
        sprintRun: 11.24,
        standingJump: 117.51,
        handballThrow: 7.73,
        totalScore: 37.9,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.88,
        sitUps: 15.08,
        bending: 32.98,
        sideJump: 33.56,
        shuttleRun: 27.92,
        enduranceRun: null,
        sprintRun: 10.67,
        standingJump: 128.36,
        handballThrow: 9.87,
        totalScore: 43.76,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14,
        sitUps: 17.04,
        bending: 34.9,
        sideJump: 37.27,
        shuttleRun: 33.85,
        enduranceRun: null,
        sprintRun: 10.19,
        standingJump: 137.85,
        handballThrow: 12.11,
        totalScore: 49.92,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.57,
        sitUps: 18.33,
        bending: 37.26,
        sideJump: 40.99,
        shuttleRun: 41.24,
        enduranceRun: null,
        sprintRun: 9.72,
        standingJump: 146.96,
        handballThrow: 14.52,
        totalScore: 55.87,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.08,
        sitUps: 19.14,
        bending: 39.03,
        sideJump: 42.8,
        shuttleRun: 45.8,
        enduranceRun: null,
        sprintRun: 9.43,
        standingJump: 152.75,
        handballThrow: 16.1,
        totalScore: 59.63,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.15,
        sitUps: 18.71,
        bending: 43.64,
        sideJump: 44.01,
        shuttleRun: 47.21,
        enduranceRun: 307.33,
        sprintRun: 9.28,
        standingJump: 159.74,
        handballThrow: 11.21,
        totalScore: 41.76,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.51,
        sitUps: 21.18,
        bending: 46.4,
        sideJump: 45.2,
        shuttleRun: 52.63,
        enduranceRun: 297.68,
        sprintRun: 8.96,
        standingJump: 165.23,
        handballThrow: 12.77,
        totalScore: 47.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.17,
        sitUps: 22.97,
        bending: 49.25,
        sideJump: 46.51,
        shuttleRun: 54.78,
        enduranceRun: 294.15,
        sprintRun: 8.83,
        standingJump: 169.39,
        handballThrow: 13.7,
        totalScore: 50.91,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25,
        sitUps: 22.58,
        bending: 47.71,
        sideJump: 47.81,
        shuttleRun: 52.08,
        enduranceRun: 328,
        sprintRun: 8.98,
        standingJump: 168.54,
        handballThrow: 13.66,
        totalScore: 49.78,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.62,
        sitUps: 23.47,
        bending: 49.27,
        sideJump: 48.15,
        shuttleRun: 50.74,
        enduranceRun: 320.35,
        sprintRun: 8.98,
        standingJump: 169.54,
        handballThrow: 13.88,
        totalScore: 50.88,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.28,
        sitUps: 24.26,
        bending: 50.79,
        sideJump: 48.49,
        shuttleRun: 51.49,
        enduranceRun: 325.27,
        sprintRun: 8.89,
        standingJump: 170.72,
        handballThrow: 14.43,
        totalScore: 52.61,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  山梨県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.67,
        sitUps: 11.21,
        bending: 26.02,
        sideJump: 26,
        shuttleRun: 16.03,
        enduranceRun: null,
        sprintRun: 11.79,
        standingJump: 112.31,
        handballThrow: 7.61,
        totalScore: 27.98,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.44,
        sitUps: 13.93,
        bending: 27.55,
        sideJump: 29.53,
        shuttleRun: 23.34,
        enduranceRun: null,
        sprintRun: 10.89,
        standingJump: 123.5,
        handballThrow: 10.54,
        totalScore: 34.41,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.16,
        sitUps: 15.55,
        bending: 29.98,
        sideJump: 32.91,
        shuttleRun: 29.82,
        enduranceRun: null,
        sprintRun: 10.31,
        standingJump: 131.55,
        handballThrow: 13.68,
        totalScore: 39.35,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.98,
        sitUps: 17.06,
        bending: 31.31,
        sideJump: 36.62,
        shuttleRun: 35.5,
        enduranceRun: null,
        sprintRun: 9.92,
        standingJump: 140.23,
        handballThrow: 16.57,
        totalScore: 43.77,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.95,
        sitUps: 19.28,
        bending: 32.56,
        sideJump: 40.08,
        shuttleRun: 41.74,
        enduranceRun: null,
        sprintRun: 9.54,
        standingJump: 148.67,
        handballThrow: 19.33,
        totalScore: 48.3,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.98,
        sitUps: 20.82,
        bending: 34.42,
        sideJump: 42.93,
        shuttleRun: 49.58,
        enduranceRun: null,
        sprintRun: 9.13,
        standingJump: 165.94,
        handballThrow: 23.06,
        totalScore: 53.08,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.23,
        sitUps: 22.83,
        bending: 39.85,
        sideJump: 47.39,
        shuttleRun: 63.01,
        enduranceRun: null,
        sprintRun: 8.69,
        standingJump: 179.26,
        handballThrow: 16.81,
        totalScore: 29.59,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 30.23,
        sitUps: 26.17,
        bending: 44.13,
        sideJump: 51.56,
        shuttleRun: 79.54,
        enduranceRun: null,
        sprintRun: 7.95,
        standingJump: 199.71,
        handballThrow: 20.41,
        totalScore: 37.36,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.56,
        sitUps: 28.06,
        bending: 47.3,
        sideJump: 54.5,
        shuttleRun: 85.93,
        enduranceRun: null,
        sprintRun: 7.56,
        standingJump: 212.47,
        handballThrow: 23.09,
        totalScore: 42.73,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.57,
        sitUps: 28.26,
        bending: 47.46,
        sideJump: 56.72,
        shuttleRun: 84.68,
        enduranceRun: null,
        sprintRun: 7.45,
        standingJump: 221.23,
        handballThrow: 22.43,
        totalScore: 49.47,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.71,
        sitUps: 29.18,
        bending: 49.24,
        sideJump: 57.41,
        shuttleRun: 88.22,
        enduranceRun: null,
        sprintRun: 7.32,
        standingJump: 226.07,
        handballThrow: 23.54,
        totalScore: 51.84,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.65,
        sitUps: 30.71,
        bending: 51.58,
        sideJump: 58.58,
        shuttleRun: 88,
        enduranceRun: null,
        sprintRun: 7.27,
        standingJump: 229.5,
        handballThrow: 24.6,
        totalScore: 54.58,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.21,
        sitUps: 11.27,
        bending: 28.32,
        sideJump: 25.77,
        shuttleRun: 13.81,
        enduranceRun: null,
        sprintRun: 12.1,
        standingJump: 105.45,
        handballThrow: 5.36,
        totalScore: 28.63,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.88,
        sitUps: 13.73,
        bending: 30.35,
        sideJump: 29.03,
        shuttleRun: 18.8,
        enduranceRun: null,
        sprintRun: 11.2,
        standingJump: 116.39,
        handballThrow: 7.36,
        totalScore: 35.47,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.44,
        sitUps: 15.01,
        bending: 33.05,
        sideJump: 31.78,
        shuttleRun: 23.23,
        enduranceRun: null,
        sprintRun: 10.64,
        standingJump: 124.03,
        handballThrow: 9.32,
        totalScore: 40.49,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.47,
        sitUps: 16.86,
        bending: 35.39,
        sideJump: 35.74,
        shuttleRun: 28.32,
        enduranceRun: null,
        sprintRun: 10.13,
        standingJump: 134.39,
        handballThrow: 11.3,
        totalScore: 46.56,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.94,
        sitUps: 17.74,
        bending: 37.09,
        sideJump: 38.47,
        shuttleRun: 33.14,
        enduranceRun: null,
        sprintRun: 9.71,
        standingJump: 143.64,
        handballThrow: 13.39,
        totalScore: 51.13,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.81,
        sitUps: 18.71,
        bending: 40.27,
        sideJump: 40.96,
        shuttleRun: 37.3,
        enduranceRun: null,
        sprintRun: 9.38,
        standingJump: 150.57,
        handballThrow: 15.41,
        totalScore: 55.09,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.65,
        sitUps: 19.59,
        bending: 42.76,
        sideJump: 43.94,
        shuttleRun: 45.12,
        enduranceRun: null,
        sprintRun: 9.16,
        standingJump: 162.31,
        handballThrow: 11.24,
        totalScore: 38.91,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.7,
        sitUps: 21.49,
        bending: 45.34,
        sideJump: 45.78,
        shuttleRun: 52.07,
        enduranceRun: null,
        sprintRun: 8.87,
        standingJump: 168.04,
        handballThrow: 12.54,
        totalScore: 41.72,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.12,
        sitUps: 22.5,
        bending: 46.89,
        sideJump: 46.65,
        shuttleRun: 52.51,
        enduranceRun: null,
        sprintRun: 8.76,
        standingJump: 171.15,
        handballThrow: 13.69,
        totalScore: 43.98,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.85,
        sitUps: 21.86,
        bending: 47.3,
        sideJump: 48.48,
        shuttleRun: 49.99,
        enduranceRun: null,
        sprintRun: 8.9,
        standingJump: 172.66,
        handballThrow: 12.78,
        totalScore: 49.45,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.41,
        sitUps: 22.52,
        bending: 47.93,
        sideJump: 48.67,
        shuttleRun: 50.5,
        enduranceRun: null,
        sprintRun: 8.88,
        standingJump: 172.82,
        handballThrow: 13.24,
        totalScore: 50.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.92,
        sitUps: 23.49,
        bending: 49.92,
        sideJump: 48.78,
        shuttleRun: 48.59,
        enduranceRun: null,
        sprintRun: 8.93,
        standingJump: 172.58,
        handballThrow: 13.67,
        totalScore: 51.1,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  岐阜県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.85,
        sitUps: 11.19,
        bending: 25.27,
        sideJump: 25.74,
        shuttleRun: 16.5,
        enduranceRun: null,
        sprintRun: 11.74,
        standingJump: 113.27,
        handballThrow: 8.18,
        totalScore: 29.19,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.3,
        sitUps: 13.29,
        bending: 26.86,
        sideJump: 29.46,
        shuttleRun: 22.01,
        enduranceRun: null,
        sprintRun: 10.93,
        standingJump: 124.13,
        handballThrow: 10.83,
        totalScore: 34.89,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.19,
        sitUps: 15.84,
        bending: 30.15,
        sideJump: 33.07,
        shuttleRun: 30.49,
        enduranceRun: null,
        sprintRun: 10.25,
        standingJump: 132.44,
        handballThrow: 14.34,
        totalScore: 41.57,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.66,
        sitUps: 16.75,
        bending: 32.04,
        sideJump: 35.69,
        shuttleRun: 34.19,
        enduranceRun: null,
        sprintRun: 9.93,
        standingJump: 139.46,
        handballThrow: 17.39,
        totalScore: 45.57,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.21,
        sitUps: 18.59,
        bending: 33.01,
        sideJump: 40.48,
        shuttleRun: 41.62,
        enduranceRun: null,
        sprintRun: 9.51,
        standingJump: 149.08,
        handballThrow: 20.78,
        totalScore: 51.29,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.87,
        sitUps: 20.41,
        bending: 36.36,
        sideJump: 43.52,
        shuttleRun: 50.49,
        enduranceRun: null,
        sprintRun: 9.11,
        standingJump: 160.32,
        handballThrow: 23.42,
        totalScore: 57.16,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.27,
        sitUps: 22.17,
        bending: 39.34,
        sideJump: 47.57,
        shuttleRun: 60.67,
        enduranceRun: 448.48,
        sprintRun: 8.66,
        standingJump: 180.95,
        handballThrow: 17.18,
        totalScore: 32,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.71,
        sitUps: 25.48,
        bending: 44.05,
        sideJump: 51.59,
        shuttleRun: 75.32,
        enduranceRun: 420.83,
        sprintRun: 7.99,
        standingJump: 199.17,
        handballThrow: 20.4,
        totalScore: 41.03,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.25,
        sitUps: 27.43,
        bending: 47.9,
        sideJump: 54.85,
        shuttleRun: 81.66,
        enduranceRun: 399.3,
        sprintRun: 7.61,
        standingJump: 214.22,
        handballThrow: 22.58,
        totalScore: 47.5,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.63,
        sitUps: 28.5,
        bending: 49.18,
        sideJump: 57.47,
        shuttleRun: 86.05,
        enduranceRun: 391.31,
        sprintRun: 7.37,
        standingJump: 221.8,
        handballThrow: 24.11,
        totalScore: 51.37,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.11,
        sitUps: 30.26,
        bending: 51.1,
        sideJump: 59.16,
        shuttleRun: 90.94,
        enduranceRun: 383.28,
        sprintRun: 7.21,
        standingJump: 228.07,
        handballThrow: 25.44,
        totalScore: 55.14,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.55,
        sitUps: 31.5,
        bending: 53.47,
        sideJump: 59.93,
        shuttleRun: 92.73,
        enduranceRun: 379.28,
        sprintRun: 7.11,
        standingJump: 232.07,
        handballThrow: 26.52,
        totalScore: 57.63,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.3,
        sitUps: 11.1,
        bending: 27.39,
        sideJump: 25.18,
        shuttleRun: 14.03,
        enduranceRun: null,
        sprintRun: 12.06,
        standingJump: 105.86,
        handballThrow: 5.76,
        totalScore: 29.39,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.76,
        sitUps: 12.95,
        bending: 29.94,
        sideJump: 28.56,
        shuttleRun: 17.69,
        enduranceRun: null,
        sprintRun: 11.19,
        standingJump: 117.48,
        handballThrow: 7.77,
        totalScore: 35.96,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.42,
        sitUps: 14.98,
        bending: 32.81,
        sideJump: 31.53,
        shuttleRun: 23.76,
        enduranceRun: null,
        sprintRun: 10.6,
        standingJump: 125.45,
        handballThrow: 9.56,
        totalScore: 42.19,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.43,
        sitUps: 16.68,
        bending: 36.1,
        sideJump: 34.29,
        shuttleRun: 27.98,
        enduranceRun: null,
        sprintRun: 10.12,
        standingJump: 135.22,
        handballThrow: 11.81,
        totalScore: 48.15,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.22,
        sitUps: 17.92,
        bending: 37.85,
        sideJump: 39.46,
        shuttleRun: 34.39,
        enduranceRun: null,
        sprintRun: 9.7,
        standingJump: 145.02,
        handballThrow: 14.08,
        totalScore: 54.4,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.93,
        sitUps: 19.06,
        bending: 40.61,
        sideJump: 41.83,
        shuttleRun: 39.94,
        enduranceRun: null,
        sprintRun: 9.37,
        standingJump: 152.93,
        handballThrow: 15.81,
        totalScore: 59.08,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.07,
        sitUps: 18.94,
        bending: 42.69,
        sideJump: 44.1,
        shuttleRun: 43.1,
        enduranceRun: 330.12,
        sprintRun: 9.24,
        standingJump: 163.73,
        handballThrow: 11.56,
        totalScore: 41.73,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 22.98,
        sitUps: 21.02,
        bending: 46.66,
        sideJump: 45.69,
        shuttleRun: 48.51,
        enduranceRun: 331.85,
        sprintRun: 9.01,
        standingJump: 168.37,
        handballThrow: 12.65,
        totalScore: 46.91,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.49,
        sitUps: 22.71,
        bending: 49.87,
        sideJump: 47.47,
        shuttleRun: 50.92,
        enduranceRun: 308.36,
        sprintRun: 8.87,
        standingJump: 172.93,
        handballThrow: 13.78,
        totalScore: 51.05,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.41,
        sitUps: 22.45,
        bending: 49.8,
        sideJump: 49.21,
        shuttleRun: 50.82,
        enduranceRun: 308.33,
        sprintRun: 8.82,
        standingJump: 174.57,
        handballThrow: 14.13,
        totalScore: 52.02,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.32,
        sitUps: 23.74,
        bending: 51.92,
        sideJump: 49.85,
        shuttleRun: 51.92,
        enduranceRun: 312.91,
        sprintRun: 8.79,
        standingJump: 175.7,
        handballThrow: 14.6,
        totalScore: 53.94,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.68,
        sitUps: 24.2,
        bending: 52.96,
        sideJump: 50.11,
        shuttleRun: 52.24,
        enduranceRun: 312.11,
        sprintRun: 8.79,
        standingJump: 176.2,
        handballThrow: 15,
        totalScore: 54.91,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  岡山県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.5,
        sitUps: 10.52,
        bending: 24.95,
        sideJump: 26.23,
        shuttleRun: 16.65,
        enduranceRun: null,
        sprintRun: 11.85,
        standingJump: 112.97,
        handballThrow: 7.49,
        totalScore: 28.24,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.07,
        sitUps: 13.3,
        bending: 26.8,
        sideJump: 30.39,
        shuttleRun: 25.32,
        enduranceRun: null,
        sprintRun: 10.91,
        standingJump: 126.78,
        handballThrow: 10.69,
        totalScore: 35.41,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.72,
        sitUps: 15.33,
        bending: 28.29,
        sideJump: 33.31,
        shuttleRun: 32.4,
        enduranceRun: null,
        sprintRun: 10.33,
        standingJump: 135.83,
        handballThrow: 13.93,
        totalScore: 40.83,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.54,
        sitUps: 16.96,
        bending: 30.42,
        sideJump: 37.26,
        shuttleRun: 38.84,
        enduranceRun: null,
        sprintRun: 9.85,
        standingJump: 143.7,
        handballThrow: 16.98,
        totalScore: 46.67,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.6,
        sitUps: 19.06,
        bending: 32.64,
        sideJump: 41.2,
        shuttleRun: 46.11,
        enduranceRun: null,
        sprintRun: 9.41,
        standingJump: 152.93,
        handballThrow: 20.38,
        totalScore: 51.97,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.61,
        sitUps: 21.06,
        bending: 35.32,
        sideJump: 44.93,
        shuttleRun: 53.72,
        enduranceRun: null,
        sprintRun: 9.05,
        standingJump: 164.74,
        handballThrow: 23.7,
        totalScore: 58.03,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.77,
        sitUps: 22.24,
        bending: 40.16,
        sideJump: 48.3,
        shuttleRun: 60.05,
        enduranceRun: null,
        sprintRun: 8.55,
        standingJump: 181.46,
        handballThrow: 17.64,
        totalScore: 32.15,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.1,
        sitUps: 25.02,
        bending: 43.74,
        sideJump: 51.6,
        shuttleRun: 76.02,
        enduranceRun: null,
        sprintRun: 8.01,
        standingJump: 198.18,
        handballThrow: 20.7,
        totalScore: 40.06,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.01,
        sitUps: 27.55,
        bending: 47.82,
        sideJump: 54.57,
        shuttleRun: 83.64,
        enduranceRun: null,
        sprintRun: 7.57,
        standingJump: 212.31,
        handballThrow: 23.26,
        totalScore: 46.75,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.58,
        sitUps: 28.27,
        bending: 49.85,
        sideJump: 55.86,
        shuttleRun: 82.41,
        enduranceRun: null,
        sprintRun: 7.45,
        standingJump: 221.29,
        handballThrow: 24.51,
        totalScore: 47.52,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.72,
        sitUps: 30.25,
        bending: 51.68,
        sideJump: 57.67,
        shuttleRun: 88.29,
        enduranceRun: null,
        sprintRun: 7.27,
        standingJump: 226.62,
        handballThrow: 26.32,
        totalScore: 50.9,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.03,
        sitUps: 30.89,
        bending: 52.22,
        sideJump: 58.11,
        shuttleRun: 87.52,
        enduranceRun: null,
        sprintRun: 7.19,
        standingJump: 228.43,
        handballThrow: 26.97,
        totalScore: 52.11,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.12,
        sitUps: 10.45,
        bending: 27.26,
        sideJump: 25.73,
        shuttleRun: 14.41,
        enduranceRun: null,
        sprintRun: 12.13,
        standingJump: 106.07,
        handballThrow: 5.37,
        totalScore: 28.81,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.58,
        sitUps: 12.7,
        bending: 29.44,
        sideJump: 49.38,
        shuttleRun: 20.35,
        enduranceRun: null,
        sprintRun: 11.24,
        standingJump: 118.62,
        handballThrow: 7.12,
        totalScore: 36.04,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.15,
        sitUps: 14.71,
        bending: 31.06,
        sideJump: 32.14,
        shuttleRun: 25.06,
        enduranceRun: null,
        sprintRun: 10.63,
        standingJump: 127.69,
        handballThrow: 9.1,
        totalScore: 41.93,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.01,
        sitUps: 16.33,
        bending: 33.68,
        sideJump: 35.75,
        shuttleRun: 30.31,
        enduranceRun: null,
        sprintRun: 10.13,
        standingJump: 136.77,
        handballThrow: 11.19,
        totalScore: 47.93,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.5,
        sitUps: 17.96,
        bending: 36.49,
        sideJump: 39.56,
        shuttleRun: 36.9,
        enduranceRun: null,
        sprintRun: 9.63,
        standingJump: 145.93,
        handballThrow: 13.3,
        totalScore: 54.11,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.36,
        sitUps: 19.35,
        bending: 40.07,
        sideJump: 42.38,
        shuttleRun: 41.28,
        enduranceRun: null,
        sprintRun: 9.32,
        standingJump: 154.69,
        handballThrow: 15.29,
        totalScore: 59.48,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.44,
        sitUps: 19.06,
        bending: 43.77,
        sideJump: 44.29,
        shuttleRun: 42.91,
        enduranceRun: null,
        sprintRun: 9.12,
        standingJump: 163.02,
        handballThrow: 11.27,
        totalScore: 41.15,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.49,
        sitUps: 20.85,
        bending: 46.38,
        sideJump: 46.07,
        shuttleRun: 49.42,
        enduranceRun: null,
        sprintRun: 8.88,
        standingJump: 168.76,
        handballThrow: 12.56,
        totalScore: 46.12,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.81,
        sitUps: 22.33,
        bending: 49.63,
        sideJump: 7.21,
        shuttleRun: 50.48,
        enduranceRun: null,
        sprintRun: 8.81,
        standingJump: 170.43,
        handballThrow: 13.73,
        totalScore: 49.36,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.98,
        sitUps: 22.43,
        bending: 50.41,
        sideJump: 47.57,
        shuttleRun: 47.43,
        enduranceRun: null,
        sprintRun: 8.85,
        standingJump: 173.21,
        handballThrow: 14.21,
        totalScore: 49.13,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.89,
        sitUps: 23.72,
        bending: 51.98,
        sideJump: 48.43,
        shuttleRun: 48.45,
        enduranceRun: null,
        sprintRun: 8.83,
        standingJump: 174.61,
        handballThrow: 14.86,
        totalScore: 50.92,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.29,
        sitUps: 28.02,
        bending: 52.74,
        sideJump: 48.8,
        shuttleRun: 48.57,
        enduranceRun: null,
        sprintRun: 8.86,
        standingJump: 174.9,
        handballThrow: 15.24,
        totalScore: 51.94,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  徳島県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.73,
        sitUps: 11.25,
        bending: 25.81,
        sideJump: 26.67,
        shuttleRun: 16.62,
        enduranceRun: null,
        sprintRun: 11.96,
        standingJump: 116.75,
        handballThrow: 7.95,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.07,
        sitUps: 13.5,
        bending: 29.08,
        sideJump: 29.85,
        shuttleRun: 24.77,
        enduranceRun: null,
        sprintRun: 11,
        standingJump: 126.67,
        handballThrow: 10.85,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.99,
        sitUps: 15.42,
        bending: 29.11,
        sideJump: 33.15,
        shuttleRun: 30.91,
        enduranceRun: null,
        sprintRun: 10.51,
        standingJump: 134.33,
        handballThrow: 13.94,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.92,
        sitUps: 16.4,
        bending: 31.1,
        sideJump: 36.84,
        shuttleRun: 37.29,
        enduranceRun: null,
        sprintRun: 9.99,
        standingJump: 141.11,
        handballThrow: 17.42,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.94,
        sitUps: 18.86,
        bending: 32.87,
        sideJump: 40.49,
        shuttleRun: 45.07,
        enduranceRun: null,
        sprintRun: 9.55,
        standingJump: 149.76,
        handballThrow: 20.46,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.97,
        sitUps: 20.34,
        bending: 34.54,
        sideJump: 44,
        shuttleRun: 53.05,
        enduranceRun: null,
        sprintRun: 9.07,
        standingJump: 160.59,
        handballThrow: 24,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.18,
        sitUps: 22.38,
        bending: 40.83,
        sideJump: 48.68,
        shuttleRun: 62.34,
        enduranceRun: 460.88,
        sprintRun: 8.6,
        standingJump: 180.57,
        handballThrow: 17.57,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.64,
        sitUps: 24.96,
        bending: 44.43,
        sideJump: 51.4,
        shuttleRun: 77.07,
        enduranceRun: 418.05,
        sprintRun: 8.05,
        standingJump: 197.73,
        handballThrow: 20.05,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.21,
        sitUps: 27.38,
        bending: 48.19,
        sideJump: 55.08,
        shuttleRun: 85.66,
        enduranceRun: 404.06,
        sprintRun: 7.59,
        standingJump: 212.74,
        handballThrow: 22.65,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.04,
        sitUps: 26.85,
        bending: 46.77,
        sideJump: 55.07,
        shuttleRun: 80.79,
        enduranceRun: 427.34,
        sprintRun: 7.5,
        standingJump: 215.11,
        handballThrow: 23.47,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.31,
        sitUps: 28.3,
        bending: 49.22,
        sideJump: 56.76,
        shuttleRun: 89.51,
        enduranceRun: 412.9,
        sprintRun: 7.39,
        standingJump: 221.52,
        handballThrow: 24.79,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.4,
        sitUps: 29.66,
        bending: 50.05,
        sideJump: 56.67,
        shuttleRun: 84.77,
        enduranceRun: 386.06,
        sprintRun: 7.39,
        standingJump: 222.86,
        handballThrow: 24.78,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.25,
        sitUps: 10.69,
        bending: 28.67,
        sideJump: 25.8,
        shuttleRun: 14.62,
        enduranceRun: null,
        sprintRun: 12.29,
        standingJump: 106.13,
        handballThrow: 5.59,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.56,
        sitUps: 13,
        bending: 31.62,
        sideJump: 28.02,
        shuttleRun: 19.25,
        enduranceRun: null,
        sprintRun: 11.47,
        standingJump: 117.03,
        handballThrow: 7.23,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.37,
        sitUps: 15.11,
        bending: 33.22,
        sideJump: 32.45,
        shuttleRun: 25.11,
        enduranceRun: null,
        sprintRun: 10.78,
        standingJump: 129.96,
        handballThrow: 9.4,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.49,
        sitUps: 15.95,
        bending: 34.38,
        sideJump: 35.29,
        shuttleRun: 29.17,
        enduranceRun: null,
        sprintRun: 10.23,
        standingJump: 135.42,
        handballThrow: 11.28,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.94,
        sitUps: 17.91,
        bending: 37.41,
        sideJump: 38.66,
        shuttleRun: 36.17,
        enduranceRun: null,
        sprintRun: 9.77,
        standingJump: 144.44,
        handballThrow: 13.56,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.67,
        sitUps: 18.56,
        bending: 39.73,
        sideJump: 41.37,
        shuttleRun: 40.81,
        enduranceRun: null,
        sprintRun: 9.39,
        standingJump: 151.48,
        handballThrow: 15.33,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.56,
        sitUps: 18.99,
        bending: 44.17,
        sideJump: 44.49,
        shuttleRun: 43.08,
        enduranceRun: 332.1,
        sprintRun: 9.17,
        standingJump: 162.38,
        handballThrow: 11.12,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.62,
        sitUps: 20.87,
        bending: 46.38,
        sideJump: 45.62,
        shuttleRun: 50.42,
        enduranceRun: 309.18,
        sprintRun: 8.93,
        standingJump: 168.43,
        handballThrow: 12.43,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.98,
        sitUps: 21.93,
        bending: 49.29,
        sideJump: 47.9,
        shuttleRun: 53.06,
        enduranceRun: 309.8,
        sprintRun: 8.84,
        standingJump: 172.89,
        handballThrow: 13.46,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.04,
        sitUps: 20.64,
        bending: 47.71,
        sideJump: 46.82,
        shuttleRun: 44.07,
        enduranceRun: 332.84,
        sprintRun: 9.07,
        standingJump: 166.34,
        handballThrow: 13.19,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.12,
        sitUps: 21.57,
        bending: 49.72,
        sideJump: 47.53,
        shuttleRun: 48.25,
        enduranceRun: 329.43,
        sprintRun: 9.1,
        standingJump: 167.97,
        handballThrow: 13.49,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.52,
        sitUps: 22.53,
        bending: 49.89,
        sideJump: 47.32,
        shuttleRun: 44.74,
        enduranceRun: 300.87,
        sprintRun: 9.22,
        standingJump: 166.98,
        handballThrow: 14.01,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  愛知県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 11.6,
        standingJump: 112.7,
        handballThrow: 7.6,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 10.7,
        standingJump: 126.3,
        handballThrow: 10.9,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 10.3,
        standingJump: 133.9,
        handballThrow: 13.4,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: null,
        sitUps: null,
        bending: 31.7,
        sideJump: 35.7,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 9.9,
        standingJump: 145.5,
        handballThrow: 16.5,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.1,
        sitUps: 17.9,
        bending: 34.8,
        sideJump: 40.3,
        shuttleRun: 44.2,
        enduranceRun: null,
        sprintRun: 9.5,
        standingJump: 151,
        handballThrow: 19.9,
        totalScore: 51.72,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19,
        sitUps: 20.6,
        bending: 36.2,
        sideJump: 43.3,
        shuttleRun: 53,
        enduranceRun: null,
        sprintRun: 9,
        standingJump: 162.3,
        handballThrow: 23,
        totalScore: 57.53,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24,
        sitUps: 23.2,
        bending: 39.6,
        sideJump: 48.3,
        shuttleRun: 59.5,
        enduranceRun: 444.4,
        sprintRun: 8.7,
        standingJump: 179.9,
        handballThrow: 17.7,
        totalScore: 32.55,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.8,
        sitUps: 25.8,
        bending: 44.8,
        sideJump: 52.5,
        shuttleRun: 74.1,
        enduranceRun: 410.6,
        sprintRun: 8.1,
        standingJump: 196.2,
        handballThrow: 20.1,
        totalScore: 40.36,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34,
        sitUps: 28.5,
        bending: 49.2,
        sideJump: 56,
        shuttleRun: 82.6,
        enduranceRun: 387.9,
        sprintRun: 7.6,
        standingJump: 210.6,
        handballThrow: 22.9,
        totalScore: 48.01,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.3,
        sitUps: 27.8,
        bending: 49.1,
        sideJump: 56,
        shuttleRun: 81.7,
        enduranceRun: 398.2,
        sprintRun: 7.6,
        standingJump: 214.6,
        handballThrow: 23.5,
        totalScore: 48.73,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.8,
        sitUps: 29.9,
        bending: 51.9,
        sideJump: 57.7,
        shuttleRun: 88.3,
        enduranceRun: 386.8,
        sprintRun: 7.4,
        standingJump: 221.5,
        handballThrow: 25.3,
        totalScore: 53.16,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.5,
        sitUps: 31.4,
        bending: 54,
        sideJump: 58.9,
        shuttleRun: 88.9,
        enduranceRun: 381,
        sprintRun: 7.3,
        standingJump: 226.6,
        handballThrow: 26.6,
        totalScore: 56.1,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 11.9,
        standingJump: 105.9,
        handballThrow: 5.1,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 11.1,
        standingJump: 117.3,
        handballThrow: 7,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 10.6,
        standingJump: 129.2,
        handballThrow: 8.7,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: null,
        sitUps: null,
        bending: 35.4,
        sideJump: 33.9,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: 10.2,
        standingJump: 138.1,
        handballThrow: 10.5,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.2,
        sitUps: 17.1,
        bending: 39.2,
        sideJump: 38.8,
        shuttleRun: 34.6,
        enduranceRun: null,
        sprintRun: 9.7,
        standingJump: 146,
        handballThrow: 12.8,
        totalScore: 53.79,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19,
        sitUps: 19.1,
        bending: 41.2,
        sideJump: 41.3,
        shuttleRun: 42.9,
        enduranceRun: null,
        sprintRun: 9.2,
        standingJump: 157.4,
        handballThrow: 14.8,
        totalScore: 59.52,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21,
        sitUps: 19.5,
        bending: 43,
        sideJump: 44.6,
        shuttleRun: 40.5,
        enduranceRun: 321.1,
        sprintRun: 9.3,
        standingJump: 160.1,
        handballThrow: 11,
        totalScore: 40.52,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.4,
        sitUps: 21.7,
        bending: 47.4,
        sideJump: 47.7,
        shuttleRun: 49.9,
        enduranceRun: 301.9,
        sprintRun: 8.9,
        standingJump: 169.7,
        handballThrow: 12.7,
        totalScore: 47.84,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.9,
        sitUps: 23.4,
        bending: 48.4,
        sideJump: 49,
        shuttleRun: 51.9,
        enduranceRun: 297.7,
        sprintRun: 8.8,
        standingJump: 173.1,
        handballThrow: 13.7,
        totalScore: 51.22,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.4,
        sitUps: 22.7,
        bending: 49.6,
        sideJump: 48.7,
        shuttleRun: 49.5,
        enduranceRun: 310.9,
        sprintRun: 9,
        standingJump: 169.9,
        handballThrow: 13.8,
        totalScore: 50.56,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.3,
        sitUps: 24.1,
        bending: 50.9,
        sideJump: 49.3,
        shuttleRun: 51.8,
        enduranceRun: 305.8,
        sprintRun: 8.9,
        standingJump: 171.3,
        handballThrow: 14.6,
        totalScore: 52.78,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.8,
        sitUps: 25.2,
        bending: 52.2,
        sideJump: 49.8,
        shuttleRun: 50.7,
        enduranceRun: 308,
        sprintRun: 8.9,
        standingJump: 172.7,
        handballThrow: 15.1,
        totalScore: 54.04,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  新潟県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.92,
        sitUps: 11.56,
        bending: 27.45,
        sideJump: 28.19,
        shuttleRun: 22.38,
        enduranceRun: null,
        sprintRun: 11.79,
        standingJump: 116.21,
        handballThrow: 8.34,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.79,
        sitUps: 14.14,
        bending: 29.05,
        sideJump: 32.5,
        shuttleRun: 32.06,
        enduranceRun: null,
        sprintRun: 10.79,
        standingJump: 127.92,
        handballThrow: 11.42,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.59,
        sitUps: 16.07,
        bending: 30.89,
        sideJump: 35.59,
        shuttleRun: 39.1,
        enduranceRun: null,
        sprintRun: 10.29,
        standingJump: 136.65,
        handballThrow: 14.52,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.59,
        sitUps: 17.79,
        bending: 33.07,
        sideJump: 39.81,
        shuttleRun: 45.65,
        enduranceRun: null,
        sprintRun: 9.87,
        standingJump: 144.94,
        handballThrow: 17.64,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.73,
        sitUps: 19.44,
        bending: 35.26,
        sideJump: 42.95,
        shuttleRun: 51.97,
        enduranceRun: null,
        sprintRun: 9.53,
        standingJump: 154,
        handballThrow: 20.52,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 20.07,
        sitUps: 21.31,
        bending: 37.46,
        sideJump: 46.29,
        shuttleRun: 59.33,
        enduranceRun: null,
        sprintRun: 9.11,
        standingJump: 166.37,
        handballThrow: 24.08,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.46,
        sitUps: 23.29,
        bending: 43.25,
        sideJump: 48.1,
        shuttleRun: 66.93,
        enduranceRun: null,
        sprintRun: 8.63,
        standingJump: 185.64,
        handballThrow: 17.79,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.92,
        sitUps: 26.62,
        bending: 47.26,
        sideJump: 51.5,
        shuttleRun: 80.21,
        enduranceRun: null,
        sprintRun: 7.97,
        standingJump: 202.79,
        handballThrow: 21.01,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.51,
        sitUps: 29.04,
        bending: 50.65,
        sideJump: 54.19,
        shuttleRun: 86.81,
        enduranceRun: null,
        sprintRun: 7.56,
        standingJump: 216.72,
        handballThrow: 23.81,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.57,
        sitUps: 27.38,
        bending: 48.27,
        sideJump: 54.93,
        shuttleRun: 83.34,
        enduranceRun: null,
        sprintRun: 7.57,
        standingJump: 218.3,
        handballThrow: 23.1,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.49,
        sitUps: 29.31,
        bending: 49.72,
        sideJump: 55.94,
        shuttleRun: 87.26,
        enduranceRun: null,
        sprintRun: 7.41,
        standingJump: 223.53,
        handballThrow: 24.57,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 39.87,
        sitUps: 30.58,
        bending: 49.74,
        sideJump: 56.47,
        shuttleRun: 87.21,
        enduranceRun: null,
        sprintRun: 7.33,
        standingJump: 226.56,
        handballThrow: 25.19,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: 39.58,
        sitUps: 23.95,
        bending: 47.35,
        sideJump: 48.77,
        shuttleRun: 54.69,
        enduranceRun: null,
        sprintRun: 7.99,
        standingJump: 200.89,
        handballThrow: 21.42,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.27,
        sitUps: 11.16,
        bending: 29.76,
        sideJump: 27.52,
        shuttleRun: 18.56,
        enduranceRun: null,
        sprintRun: 12.05,
        standingJump: 109.98,
        handballThrow: 5.71,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.08,
        sitUps: 14,
        bending: 31.79,
        sideJump: 31.9,
        shuttleRun: 26.01,
        enduranceRun: null,
        sprintRun: 11.08,
        standingJump: 121.2,
        handballThrow: 7.71,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.81,
        sitUps: 15.3,
        bending: 34.42,
        sideJump: 34.25,
        shuttleRun: 30.15,
        enduranceRun: null,
        sprintRun: 10.58,
        standingJump: 129.51,
        handballThrow: 9.66,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.06,
        sitUps: 17.08,
        bending: 37.05,
        sideJump: 38.57,
        shuttleRun: 36.38,
        enduranceRun: null,
        sprintRun: 10.1,
        standingJump: 138.97,
        handballThrow: 11.71,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.6,
        sitUps: 18.41,
        bending: 39.67,
        sideJump: 41.41,
        shuttleRun: 42.3,
        enduranceRun: null,
        sprintRun: 9.7,
        standingJump: 148.31,
        handballThrow: 13.71,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.41,
        sitUps: 19.66,
        bending: 42.06,
        sideJump: 43.84,
        shuttleRun: 46.21,
        enduranceRun: null,
        sprintRun: 9.34,
        standingJump: 156.27,
        handballThrow: 15.41,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.57,
        sitUps: 20.41,
        bending: 46.34,
        sideJump: 44.7,
        shuttleRun: 48.89,
        enduranceRun: null,
        sprintRun: 9.18,
        standingJump: 165.62,
        handballThrow: 11.36,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.69,
        sitUps: 22.45,
        bending: 49.62,
        sideJump: 45.89,
        shuttleRun: 52.53,
        enduranceRun: null,
        sprintRun: 8.94,
        standingJump: 170.18,
        handballThrow: 12.73,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.92,
        sitUps: 23.94,
        bending: 51.33,
        sideJump: 46.51,
        shuttleRun: 52.6,
        enduranceRun: null,
        sprintRun: 8.86,
        standingJump: 171.99,
        handballThrow: 13.62,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.27,
        sitUps: 22.2,
        bending: 49.48,
        sideJump: 47.6,
        shuttleRun: 50.51,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 171.63,
        handballThrow: 12.95,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.99,
        sitUps: 23.82,
        bending: 50.64,
        sideJump: 47.95,
        shuttleRun: 51.25,
        enduranceRun: null,
        sprintRun: 8.93,
        standingJump: 172.51,
        handballThrow: 13.48,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.36,
        sitUps: 24.59,
        bending: 51.27,
        sideJump: 47.72,
        shuttleRun: 51.25,
        enduranceRun: null,
        sprintRun: 8.93,
        standingJump: 151.99,
        handballThrow: 14.02,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: 24.85,
        sitUps: 15.25,
        bending: 45.51,
        sideJump: 39.16,
        shuttleRun: 27.48,
        enduranceRun: null,
        sprintRun: 10.31,
        standingJump: 140.97,
        handballThrow: 10.35,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  東京都: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.8,
        sitUps: 11,
        bending: 26.2,
        sideJump: 26.4,
        shuttleRun: 16.6,
        enduranceRun: null,
        sprintRun: 11.5,
        standingJump: 113.6,
        handballThrow: 7.3,
        totalScore: 29.4,
        height: 117.2,
        weight: 21.4,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.5,
        sitUps: 13.7,
        bending: 27.8,
        sideJump: 30.1,
        shuttleRun: 25.3,
        enduranceRun: null,
        sprintRun: 10.6,
        standingJump: 123.9,
        handballThrow: 10.2,
        totalScore: 36.4,
        height: 123.2,
        weight: 24.1,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.4,
        sitUps: 15.6,
        bending: 29.9,
        sideJump: 33.3,
        shuttleRun: 31.8,
        enduranceRun: null,
        sprintRun: 10.1,
        standingJump: 133.7,
        handballThrow: 13.4,
        totalScore: 42.1,
        height: 129,
        weight: 27.2,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.2,
        sitUps: 17.6,
        bending: 31.9,
        sideJump: 37.1,
        shuttleRun: 38.5,
        enduranceRun: null,
        sprintRun: 9.6,
        standingJump: 142.4,
        handballThrow: 16.6,
        totalScore: 47.7,
        height: 134.3,
        weight: 30.7,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.3,
        sitUps: 19.3,
        bending: 34.1,
        sideJump: 40.8,
        shuttleRun: 45.5,
        enduranceRun: null,
        sprintRun: 9.3,
        standingJump: 151.8,
        handballThrow: 19.8,
        totalScore: 53.1,
        height: 139.8,
        weight: 34.3,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.2,
        sitUps: 21.1,
        bending: 36.2,
        sideJump: 44.2,
        shuttleRun: 52.9,
        enduranceRun: null,
        sprintRun: 8.9,
        standingJump: 163.1,
        handballThrow: 23.2,
        totalScore: 58.9,
        height: 146.4,
        weight: 39.1,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.8,
        sitUps: 23.2,
        bending: 39.4,
        sideJump: 48.6,
        shuttleRun: 63.4,
        enduranceRun: 438,
        sprintRun: 8.5,
        standingJump: 181.7,
        handballThrow: 17.3,
        totalScore: 32.7,
        height: 154.5,
        weight: 44.5,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.8,
        sitUps: 25.8,
        bending: 42.8,
        sideJump: 51.6,
        shuttleRun: 76.8,
        enduranceRun: 397.1,
        sprintRun: 7.9,
        standingJump: 197.6,
        handballThrow: 20.2,
        totalScore: 40.5,
        height: 161.6,
        weight: 49.3,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.3,
        sitUps: 28,
        bending: 46.2,
        sideJump: 54.5,
        shuttleRun: 85.1,
        enduranceRun: 385.5,
        sprintRun: 7.5,
        standingJump: 210.9,
        handballThrow: 22.8,
        totalScore: 47.2,
        height: 166.5,
        weight: 53.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36,
        sitUps: 27.7,
        bending: 45.9,
        sideJump: 55.8,
        shuttleRun: 79.6,
        enduranceRun: 397.7,
        sprintRun: 7.5,
        standingJump: 219.3,
        handballThrow: 22.8,
        totalScore: 47.9,
        height: 169,
        weight: 56.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.2,
        sitUps: 29,
        bending: 47.7,
        sideJump: 57.1,
        shuttleRun: 86.7,
        enduranceRun: 390.1,
        sprintRun: 7.3,
        standingJump: 225,
        handballThrow: 24,
        totalScore: 51.2,
        height: 170.5,
        weight: 58.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 39.8,
        sitUps: 30.3,
        bending: 49.2,
        sideJump: 58.3,
        shuttleRun: 87,
        enduranceRun: 384,
        sprintRun: 7.3,
        standingJump: 229,
        handballThrow: 25,
        totalScore: 53.9,
        height: 171.3,
        weight: 60.7,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.2,
        sitUps: 10.7,
        bending: 28.6,
        sideJump: 25.5,
        shuttleRun: 13.7,
        enduranceRun: null,
        sprintRun: 11.8,
        standingJump: 105.7,
        handballThrow: 5.1,
        totalScore: 29.2,
        height: 116.3,
        weight: 20.9,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.9,
        sitUps: 13.2,
        bending: 30.8,
        sideJump: 29,
        shuttleRun: 19.4,
        enduranceRun: null,
        sprintRun: 11,
        standingJump: 115.7,
        handballThrow: 6.7,
        totalScore: 36.6,
        height: 122.2,
        weight: 23.5,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.7,
        sitUps: 15,
        bending: 33.5,
        sideJump: 31.7,
        shuttleRun: 23.7,
        enduranceRun: null,
        sprintRun: 10.4,
        standingJump: 125.7,
        handballThrow: 8.5,
        totalScore: 42.7,
        height: 128.2,
        weight: 26.5,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.5,
        sitUps: 17,
        bending: 35.9,
        sideJump: 35.5,
        shuttleRun: 29.2,
        enduranceRun: null,
        sprintRun: 9.9,
        standingJump: 135.3,
        handballThrow: 10.4,
        totalScore: 48.7,
        height: 134.5,
        weight: 30.1,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.1,
        sitUps: 18.4,
        bending: 38.7,
        sideJump: 39.1,
        shuttleRun: 35.4,
        enduranceRun: null,
        sprintRun: 9.5,
        standingJump: 145.2,
        handballThrow: 12.4,
        totalScore: 54.7,
        height: 141.6,
        weight: 34.7,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19,
        sitUps: 19.5,
        bending: 41.5,
        sideJump: 41.7,
        shuttleRun: 40,
        enduranceRun: null,
        sprintRun: 9.2,
        standingJump: 153.1,
        handballThrow: 14,
        totalScore: 59.6,
        height: 148.1,
        weight: 39.8,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.1,
        sitUps: 20.3,
        bending: 43.2,
        sideJump: 44.8,
        shuttleRun: 44.9,
        enduranceRun: 316.2,
        sprintRun: 9.1,
        standingJump: 163.6,
        handballThrow: 10.7,
        totalScore: 42.3,
        height: 152.8,
        weight: 43.7,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 22.9,
        sitUps: 22,
        bending: 45.3,
        sideJump: 46,
        shuttleRun: 50,
        enduranceRun: 299.3,
        sprintRun: 8.8,
        standingJump: 167.7,
        handballThrow: 12.1,
        totalScore: 46.9,
        height: 155.4,
        weight: 46.7,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.3,
        sitUps: 23.3,
        bending: 47.4,
        sideJump: 47,
        shuttleRun: 52,
        enduranceRun: 299.7,
        sprintRun: 8.8,
        standingJump: 169.9,
        handballThrow: 13.2,
        totalScore: 49.8,
        height: 157,
        weight: 48.9,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.9,
        sitUps: 23,
        bending: 47.2,
        sideJump: 48.7,
        shuttleRun: 49,
        enduranceRun: 312.6,
        sprintRun: 8.9,
        standingJump: 173.6,
        handballThrow: 13.1,
        totalScore: 49.7,
        height: 157.7,
        weight: 49.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.5,
        sitUps: 23.7,
        bending: 47.9,
        sideJump: 49.1,
        shuttleRun: 52.2,
        enduranceRun: 312.7,
        sprintRun: 8.9,
        standingJump: 174.4,
        handballThrow: 13.5,
        totalScore: 50.8,
        height: 158.2,
        weight: 50.6,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 25.9,
        sitUps: 24.1,
        bending: 48.9,
        sideJump: 49.5,
        shuttleRun: 51.1,
        enduranceRun: 312.1,
        sprintRun: 8.9,
        standingJump: 175.4,
        handballThrow: 13.9,
        totalScore: 51.6,
        height: 158.51,
        weight: 51,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  栃木県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.71,
        sitUps: 11.36,
        bending: 25.84,
        sideJump: 27.11,
        shuttleRun: 18.47,
        enduranceRun: null,
        sprintRun: 11.81,
        standingJump: 112.63,
        handballThrow: 7.74,
        totalScore: 29.49,
        height: 116.76,
        weight: 21.78,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.34,
        sitUps: 13.77,
        bending: 27.06,
        sideJump: 30.92,
        shuttleRun: 26.95,
        enduranceRun: null,
        sprintRun: 10.99,
        standingJump: 123.42,
        handballThrow: 10.83,
        totalScore: 36.1,
        height: 122.83,
        weight: 24.83,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.05,
        sitUps: 15.84,
        bending: 29.06,
        sideJump: 34.39,
        shuttleRun: 33.5,
        enduranceRun: null,
        sprintRun: 10.44,
        standingJump: 132.52,
        handballThrow: 13.9,
        totalScore: 41.7,
        height: 128.48,
        weight: 28.29,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.92,
        sitUps: 17.56,
        bending: 30.68,
        sideJump: 38,
        shuttleRun: 40.11,
        enduranceRun: null,
        sprintRun: 9.97,
        standingJump: 141.44,
        handballThrow: 17.12,
        totalScore: 46.99,
        height: 133.99,
        weight: 32.16,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.03,
        sitUps: 19.11,
        bending: 32.78,
        sideJump: 41.54,
        shuttleRun: 47.62,
        enduranceRun: null,
        sprintRun: 9.59,
        standingJump: 149.73,
        handballThrow: 20.27,
        totalScore: 52.19,
        height: 139.42,
        weight: 35.93,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.1,
        sitUps: 20.66,
        bending: 35.59,
        sideJump: 44.48,
        shuttleRun: 55.25,
        enduranceRun: null,
        sprintRun: 9.13,
        standingJump: 160.96,
        handballThrow: 23.46,
        totalScore: 57.92,
        height: 146.12,
        weight: 40.83,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.77,
        sitUps: 22.79,
        bending: 39.88,
        sideJump: 48.29,
        shuttleRun: 63.48,
        enduranceRun: null,
        sprintRun: 8.73,
        standingJump: 178.22,
        handballThrow: 16.77,
        totalScore: 32.61,
        height: 153.69,
        weight: 46.06,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.74,
        sitUps: 25.91,
        bending: 44.15,
        sideJump: 52.74,
        shuttleRun: 79.17,
        enduranceRun: null,
        sprintRun: 8,
        standingJump: 198.59,
        handballThrow: 19.99,
        totalScore: 41.9,
        height: 160.86,
        weight: 50.98,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.98,
        sitUps: 27.88,
        bending: 47.61,
        sideJump: 55.37,
        shuttleRun: 85.85,
        enduranceRun: null,
        sprintRun: 7.64,
        standingJump: 210.39,
        handballThrow: 22.39,
        totalScore: 48.1,
        height: 165.64,
        weight: 55.43,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.29,
        sitUps: 27.84,
        bending: 49.31,
        sideJump: 56.55,
        shuttleRun: 80.52,
        enduranceRun: null,
        sprintRun: 7.56,
        standingJump: 217.37,
        handballThrow: 22.67,
        totalScore: 49.27,
        height: 168.24,
        weight: 59.41,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.48,
        sitUps: 29.36,
        bending: 51.29,
        sideJump: 57.82,
        shuttleRun: 85.99,
        enduranceRun: null,
        sprintRun: 7.42,
        standingJump: 224.06,
        handballThrow: 24.16,
        totalScore: 52.89,
        height: 169.68,
        weight: 61.12,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 39.68,
        sitUps: 30.37,
        bending: 52.83,
        sideJump: 58.79,
        shuttleRun: 87.51,
        enduranceRun: null,
        sprintRun: 7.32,
        standingJump: 228.02,
        handballThrow: 24.96,
        totalScore: 55.11,
        height: 170.28,
        weight: 62.39,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.21,
        sitUps: 11.08,
        bending: 28.19,
        sideJump: 26.31,
        shuttleRun: 15.44,
        enduranceRun: null,
        sprintRun: 12.09,
        standingJump: 104.97,
        handballThrow: 5.42,
        totalScore: 29.59,
        height: 115.81,
        weight: 21.31,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.82,
        sitUps: 13.56,
        bending: 29.99,
        sideJump: 30.17,
        shuttleRun: 21.41,
        enduranceRun: null,
        sprintRun: 11.23,
        standingJump: 115.85,
        handballThrow: 7.3,
        totalScore: 36.95,
        height: 121.83,
        weight: 24.22,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.48,
        sitUps: 15.37,
        bending: 32.35,
        sideJump: 33.24,
        shuttleRun: 26.07,
        enduranceRun: null,
        sprintRun: 10.67,
        standingJump: 125.8,
        handballThrow: 9.31,
        totalScore: 42.86,
        height: 127.76,
        weight: 27.56,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.4,
        sitUps: 16.94,
        bending: 34.62,
        sideJump: 36.87,
        shuttleRun: 32.01,
        enduranceRun: null,
        sprintRun: 10.14,
        standingJump: 135.42,
        handballThrow: 11.51,
        totalScore: 48.92,
        height: 134.23,
        weight: 31.43,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.17,
        sitUps: 18.45,
        bending: 37.55,
        sideJump: 40.08,
        shuttleRun: 39.05,
        enduranceRun: null,
        sprintRun: 9.72,
        standingJump: 144.14,
        handballThrow: 13.79,
        totalScore: 54.96,
        height: 141.37,
        weight: 36.03,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.9,
        sitUps: 19.46,
        bending: 40.62,
        sideJump: 42.49,
        shuttleRun: 44.17,
        enduranceRun: null,
        sprintRun: 9.33,
        standingJump: 153.11,
        handballThrow: 15.65,
        totalScore: 59.99,
        height: 147.51,
        weight: 40.68,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.34,
        sitUps: 20,
        bending: 43.39,
        sideJump: 44.88,
        shuttleRun: 46.87,
        enduranceRun: null,
        sprintRun: 9.22,
        standingJump: 162,
        handballThrow: 11.07,
        totalScore: 42.82,
        height: 152.08,
        weight: 45.09,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.68,
        sitUps: 22.13,
        bending: 46.02,
        sideJump: 47.22,
        shuttleRun: 53.57,
        enduranceRun: null,
        sprintRun: 8.92,
        standingJump: 169.75,
        handballThrow: 12.48,
        totalScore: 48.76,
        height: 154.54,
        weight: 47.63,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.92,
        sitUps: 23.04,
        bending: 47.84,
        sideJump: 48.14,
        shuttleRun: 54.1,
        enduranceRun: null,
        sprintRun: 8.84,
        standingJump: 171.33,
        handballThrow: 13.5,
        totalScore: 51.31,
        height: 156.19,
        weight: 50.15,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.22,
        sitUps: 22.54,
        bending: 49.21,
        sideJump: 48.63,
        shuttleRun: 48.21,
        enduranceRun: null,
        sprintRun: 9.02,
        standingJump: 171.37,
        handballThrow: 13.13,
        totalScore: 50.25,
        height: 156.75,
        weight: 51.3,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.92,
        sitUps: 23.59,
        bending: 50.69,
        sideJump: 48.99,
        shuttleRun: 49.7,
        enduranceRun: null,
        sprintRun: 9.04,
        standingJump: 172.76,
        handballThrow: 13.77,
        totalScore: 51.93,
        height: 157.43,
        weight: 52.43,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.43,
        sitUps: 24.33,
        bending: 51.99,
        sideJump: 49.43,
        shuttleRun: 49.13,
        enduranceRun: null,
        sprintRun: 9.03,
        standingJump: 174.54,
        handballThrow: 13.97,
        totalScore: 53.03,
        height: 157.63,
        weight: 52.69,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  沖縄県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.82,
        sitUps: 10.25,
        bending: 26.31,
        sideJump: 27.19,
        shuttleRun: 15.99,
        enduranceRun: null,
        sprintRun: 12.01,
        standingJump: 113.72,
        handballThrow: 8.87,
        totalScore: null,
        height: 115.83,
        weight: 21.54,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.54,
        sitUps: 12.86,
        bending: 27.87,
        sideJump: 30.13,
        shuttleRun: 21.18,
        enduranceRun: null,
        sprintRun: 11.15,
        standingJump: 125.89,
        handballThrow: 12.12,
        totalScore: null,
        height: 121.67,
        weight: 24.24,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.52,
        sitUps: 15.1,
        bending: 29.23,
        sideJump: 34.13,
        shuttleRun: 30.06,
        enduranceRun: null,
        sprintRun: 10.58,
        standingJump: 136.4,
        handballThrow: 15.67,
        totalScore: null,
        height: 127.17,
        weight: 27.42,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.42,
        sitUps: 16.83,
        bending: 31.13,
        sideJump: 37.08,
        shuttleRun: 36.61,
        enduranceRun: null,
        sprintRun: 10.03,
        standingJump: 143.5,
        handballThrow: 19.63,
        totalScore: null,
        height: 132.77,
        weight: 31.21,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.73,
        sitUps: 18.48,
        bending: 33.3,
        sideJump: 40.04,
        shuttleRun: 43.54,
        enduranceRun: null,
        sprintRun: 9.59,
        standingJump: 152.1,
        handballThrow: 21.1,
        totalScore: null,
        height: 138.33,
        weight: 34.88,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.69,
        sitUps: 20.33,
        bending: 35.33,
        sideJump: 43.6,
        shuttleRun: 32.13,
        enduranceRun: null,
        sprintRun: 9.11,
        standingJump: 164.08,
        handballThrow: 26.69,
        totalScore: null,
        height: 144.87,
        weight: 39.2,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.22,
        sitUps: 22.39,
        bending: 39.1,
        sideJump: 36.83,
        shuttleRun: 61.12,
        enduranceRun: 469.19,
        sprintRun: 8.62,
        standingJump: 183.23,
        handballThrow: 18.7,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.97,
        sitUps: 25.46,
        bending: 42.8,
        sideJump: 50.29,
        shuttleRun: 76.94,
        enduranceRun: 434.54,
        sprintRun: 8.02,
        standingJump: 201.44,
        handballThrow: 22.32,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.77,
        sitUps: 27.47,
        bending: 46.96,
        sideJump: 53.22,
        shuttleRun: 83.37,
        enduranceRun: 411.71,
        sprintRun: 7.65,
        standingJump: 215.15,
        handballThrow: 25.16,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.25,
        sitUps: 26.67,
        bending: 45.85,
        sideJump: 52.68,
        shuttleRun: 74.79,
        enduranceRun: 438.6,
        sprintRun: 7.75,
        standingJump: 218.96,
        handballThrow: 24.91,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.92,
        sitUps: 28.36,
        bending: 48.44,
        sideJump: 45.48,
        shuttleRun: 81.52,
        enduranceRun: 422.87,
        sprintRun: 7.56,
        standingJump: 224.84,
        handballThrow: 26.34,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.75,
        sitUps: 29.39,
        bending: 50.78,
        sideJump: 55.89,
        shuttleRun: 83.76,
        enduranceRun: 432.48,
        sprintRun: 7.48,
        standingJump: 229.41,
        handballThrow: 27.6,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.14,
        sitUps: 10.08,
        bending: 27.62,
        sideJump: 26.45,
        shuttleRun: 14.05,
        enduranceRun: null,
        sprintRun: 12.49,
        standingJump: 105.56,
        handballThrow: 5.73,
        totalScore: null,
        height: 115.18,
        weight: 21.13,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.82,
        sitUps: 12.4,
        bending: 30.09,
        sideJump: 29.34,
        shuttleRun: 18.29,
        enduranceRun: null,
        sprintRun: 11.52,
        standingJump: 116.72,
        handballThrow: 7.54,
        totalScore: null,
        height: 121.06,
        weight: 23.84,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.7,
        sitUps: 14.26,
        bending: 31.6,
        sideJump: 32.9,
        shuttleRun: 23.54,
        enduranceRun: null,
        sprintRun: 10.87,
        standingJump: 126.65,
        handballThrow: 9.36,
        totalScore: null,
        height: 127.26,
        weight: 27.17,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.83,
        sitUps: 16.08,
        bending: 34.22,
        sideJump: 35.86,
        shuttleRun: 29.05,
        enduranceRun: null,
        sprintRun: 10.34,
        standingJump: 135.49,
        handballThrow: 11.89,
        totalScore: null,
        height: 133.94,
        weight: 31.1,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.54,
        sitUps: 17.67,
        bending: 37.24,
        sideJump: 38.66,
        shuttleRun: 35.43,
        enduranceRun: null,
        sprintRun: 9.83,
        standingJump: 144.51,
        handballThrow: 14.24,
        totalScore: null,
        height: 140.77,
        weight: 35.44,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.35,
        sitUps: 18.57,
        bending: 39.45,
        sideJump: 41.24,
        shuttleRun: 40.31,
        enduranceRun: null,
        sprintRun: 9.48,
        standingJump: 152.53,
        handballThrow: 16.25,
        totalScore: null,
        height: 146.87,
        weight: 40.41,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.45,
        sitUps: 18.95,
        bending: 42.45,
        sideJump: 43.55,
        shuttleRun: 43.09,
        enduranceRun: 342.11,
        sprintRun: 9.25,
        standingJump: 162.69,
        handballThrow: 11.94,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.56,
        sitUps: 21.45,
        bending: 45.3,
        sideJump: 46.41,
        shuttleRun: 50.37,
        enduranceRun: 324.53,
        sprintRun: 9.06,
        standingJump: 170.11,
        handballThrow: 3.7,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.21,
        sitUps: 22.63,
        bending: 48.61,
        sideJump: 47.59,
        shuttleRun: 51.62,
        enduranceRun: 321.52,
        sprintRun: 8.92,
        standingJump: 173.51,
        handballThrow: 14.91,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.07,
        sitUps: 21.52,
        bending: 46.55,
        sideJump: 46.66,
        shuttleRun: 42.42,
        enduranceRun: 344.84,
        sprintRun: 9.26,
        standingJump: 170.87,
        handballThrow: 14.34,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.96,
        sitUps: 22.46,
        bending: 48.48,
        sideJump: 47.59,
        shuttleRun: 46.04,
        enduranceRun: 343.19,
        sprintRun: 9.21,
        standingJump: 172.8,
        handballThrow: 15.03,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.5,
        sitUps: 22.99,
        bending: 50.01,
        sideJump: 47.68,
        shuttleRun: 45.79,
        enduranceRun: 342.45,
        sprintRun: 9.23,
        standingJump: 172.77,
        handballThrow: 15.23,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  滋賀県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.58,
        sitUps: 10.92,
        bending: 25.46,
        sideJump: 26.02,
        shuttleRun: 15.32,
        enduranceRun: null,
        sprintRun: 11.75,
        standingJump: 110.29,
        handballThrow: 7.56,
        totalScore: 28.16,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.29,
        sitUps: 13.79,
        bending: 27.03,
        sideJump: 29.95,
        shuttleRun: 24.09,
        enduranceRun: null,
        sprintRun: 10.89,
        standingJump: 123.41,
        handballThrow: 10.62,
        totalScore: 35.49,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.03,
        sitUps: 15.32,
        bending: 28.85,
        sideJump: 32.43,
        shuttleRun: 29.82,
        enduranceRun: null,
        sprintRun: 10.35,
        standingJump: 133.91,
        handballThrow: 13.67,
        totalScore: 41,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.93,
        sitUps: 16.42,
        bending: 30.97,
        sideJump: 36.01,
        shuttleRun: 36.75,
        enduranceRun: null,
        sprintRun: 9.87,
        standingJump: 142.85,
        handballThrow: 17.1,
        totalScore: 46.21,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.83,
        sitUps: 18.54,
        bending: 32.53,
        sideJump: 39.64,
        shuttleRun: 44.49,
        enduranceRun: null,
        sprintRun: 9.53,
        standingJump: 151.5,
        handballThrow: 20.15,
        totalScore: 51.36,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.67,
        sitUps: 20.37,
        bending: 34.44,
        sideJump: 43.1,
        shuttleRun: 52.82,
        enduranceRun: null,
        sprintRun: 9.11,
        standingJump: 161.98,
        handballThrow: 23.37,
        totalScore: 57.1,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.37,
        sitUps: 23.11,
        bending: 39.7,
        sideJump: 48.19,
        shuttleRun: 64.56,
        enduranceRun: 435.8,
        sprintRun: 8.6,
        standingJump: 184.11,
        handballThrow: 17.26,
        totalScore: 33.57,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.39,
        sitUps: 25.98,
        bending: 43.76,
        sideJump: 51.97,
        shuttleRun: 78.59,
        enduranceRun: 411.49,
        sprintRun: 8.04,
        standingJump: 199.95,
        handballThrow: 19.9,
        totalScore: 41.59,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.28,
        sitUps: 28.3,
        bending: 47.49,
        sideJump: 55.15,
        shuttleRun: 86.27,
        enduranceRun: 390.31,
        sprintRun: 7.63,
        standingJump: 214.11,
        handballThrow: 22.28,
        totalScore: 48.39,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 35.72,
        sitUps: 27.7,
        bending: 47.59,
        sideJump: 55.35,
        shuttleRun: 79.09,
        enduranceRun: 400.2,
        sprintRun: 7.57,
        standingJump: 219.14,
        handballThrow: 22.1,
        totalScore: 47.74,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 37.9,
        sitUps: 29.77,
        bending: 49.88,
        sideJump: 57.09,
        shuttleRun: 87.52,
        enduranceRun: 383.83,
        sprintRun: 7.34,
        standingJump: 225.98,
        handballThrow: 23.46,
        totalScore: 52.11,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 39.66,
        sitUps: 30.84,
        bending: 51.05,
        sideJump: 58.43,
        shuttleRun: 88.23,
        enduranceRun: 378.94,
        sprintRun: 7.25,
        standingJump: 229.48,
        handballThrow: 24.59,
        totalScore: 54.56,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.17,
        sitUps: 10.94,
        bending: 27.28,
        sideJump: 25.47,
        shuttleRun: 13.39,
        enduranceRun: null,
        sprintRun: 12.11,
        standingJump: 102.82,
        handballThrow: 5.28,
        totalScore: 28.34,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.78,
        sitUps: 13.09,
        bending: 29.34,
        sideJump: 29.02,
        shuttleRun: 18.82,
        enduranceRun: null,
        sprintRun: 11.24,
        standingJump: 115.24,
        handballThrow: 6.95,
        totalScore: 35.35,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.45,
        sitUps: 14.45,
        bending: 31.7,
        sideJump: 31.19,
        shuttleRun: 22.4,
        enduranceRun: null,
        sprintRun: 10.75,
        standingJump: 124.36,
        handballThrow: 8.8,
        totalScore: 40.89,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.33,
        sitUps: 15.56,
        bending: 34.53,
        sideJump: 33.78,
        shuttleRun: 27.98,
        enduranceRun: null,
        sprintRun: 10.18,
        standingJump: 135.23,
        handballThrow: 10.77,
        totalScore: 46.59,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.79,
        sitUps: 17.53,
        bending: 36.28,
        sideJump: 37.6,
        shuttleRun: 3.29,
        enduranceRun: null,
        sprintRun: 9.78,
        standingJump: 143.67,
        handballThrow: 12.91,
        totalScore: 52.67,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.49,
        sitUps: 18.43,
        bending: 39.22,
        sideJump: 40.3,
        shuttleRun: 40.5,
        enduranceRun: null,
        sprintRun: 9.4,
        standingJump: 151.48,
        handballThrow: 14.53,
        totalScore: 47.83,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.02,
        sitUps: 19.57,
        bending: 42.88,
        sideJump: 44.25,
        shuttleRun: 44.92,
        enduranceRun: 315.72,
        sprintRun: 9.22,
        standingJump: 164.16,
        handballThrow: 10.72,
        totalScore: 42.17,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.03,
        sitUps: 21.4,
        bending: 45.33,
        sideJump: 46.07,
        shuttleRun: 50.14,
        enduranceRun: 305.15,
        sprintRun: 9.02,
        standingJump: 168.52,
        handballThrow: 11.77,
        totalScore: 47.03,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.58,
        sitUps: 22.96,
        bending: 47.96,
        sideJump: 47.74,
        shuttleRun: 52.75,
        enduranceRun: 304.76,
        sprintRun: 8.92,
        standingJump: 173.09,
        handballThrow: 12.96,
        totalScore: 50.76,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.64,
        sitUps: 22.32,
        bending: 47.97,
        sideJump: 47.7,
        shuttleRun: 43.43,
        enduranceRun: 317.73,
        sprintRun: 8.98,
        standingJump: 172.45,
        handballThrow: 12.37,
        totalScore: 48.25,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.44,
        sitUps: 23.16,
        bending: 48.71,
        sideJump: 47.75,
        shuttleRun: 45.89,
        enduranceRun: 319.65,
        sprintRun: 9.01,
        standingJump: 172.31,
        handballThrow: 12.88,
        totalScore: 49.35,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.09,
        sitUps: 24.15,
        bending: 50.23,
        sideJump: 48.44,
        shuttleRun: 45.61,
        enduranceRun: 314.6,
        sprintRun: 9,
        standingJump: 172.99,
        handballThrow: 13.28,
        totalScore: 50.88,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  熊本県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.05,
        sitUps: 11.13,
        bending: 28.61,
        sideJump: 27.73,
        shuttleRun: 19.66,
        enduranceRun: null,
        sprintRun: 11.66,
        standingJump: 114.67,
        handballThrow: 8.42,
        totalScore: 30,
        height: 116.56,
        weight: 21.96,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.87,
        sitUps: 13.7,
        bending: 29.61,
        sideJump: 31.07,
        shuttleRun: 28.51,
        enduranceRun: null,
        sprintRun: 10.79,
        standingJump: 125.31,
        handballThrow: 11.67,
        totalScore: 37,
        height: 122.52,
        weight: 24.91,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.47,
        sitUps: 15.22,
        bending: 29.93,
        sideJump: 33.41,
        shuttleRun: 34.22,
        enduranceRun: null,
        sprintRun: 10.31,
        standingJump: 136.06,
        handballThrow: 14.68,
        totalScore: 43,
        height: 128.5,
        weight: 28.24,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.27,
        sitUps: 17.05,
        bending: 31.72,
        sideJump: 37.38,
        shuttleRun: 42.16,
        enduranceRun: null,
        sprintRun: 9.91,
        standingJump: 141.6,
        handballThrow: 17.98,
        totalScore: 50,
        height: 133.81,
        weight: 31.93,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.47,
        sitUps: 19,
        bending: 33.56,
        sideJump: 41.37,
        shuttleRun: 50.35,
        enduranceRun: null,
        sprintRun: 9.46,
        standingJump: 151.43,
        handballThrow: 21.27,
        totalScore: 52,
        height: 139.25,
        weight: 35.41,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.57,
        sitUps: 20.97,
        bending: 35.76,
        sideJump: 44.8,
        shuttleRun: 57.97,
        enduranceRun: null,
        sprintRun: 9.03,
        standingJump: 162.24,
        handballThrow: 24.88,
        totalScore: 60,
        height: 145.82,
        weight: 40.12,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.56,
        sitUps: 23.29,
        bending: 40.1,
        sideJump: 49.1,
        shuttleRun: 69.56,
        enduranceRun: 440.83,
        sprintRun: 8.6,
        standingJump: 182.53,
        handballThrow: 18.31,
        totalScore: 31.5,
        height: 160.86,
        weight: 50.44,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.92,
        sitUps: 26.31,
        bending: 44.56,
        sideJump: 53.08,
        shuttleRun: 85.09,
        enduranceRun: 401.36,
        sprintRun: 7.99,
        standingJump: 201.05,
        handballThrow: 21.31,
        totalScore: 40.5,
        height: 160.86,
        weight: 50.44,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.68,
        sitUps: 28.35,
        bending: 48.22,
        sideJump: 56.15,
        shuttleRun: 94.18,
        enduranceRun: 383.66,
        sprintRun: 7.58,
        standingJump: 215.07,
        handballThrow: 24.04,
        totalScore: 47.5,
        height: 165.72,
        weight: 55.02,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.21,
        sitUps: 28.64,
        bending: 49.2,
        sideJump: 55.99,
        shuttleRun: 83,
        enduranceRun: 395.18,
        sprintRun: 7.55,
        standingJump: 217.59,
        handballThrow: 24,
        totalScore: 47.5,
        height: 167.95,
        weight: 59.17,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.56,
        sitUps: 30.68,
        bending: 52,
        sideJump: 58.39,
        shuttleRun: 92.71,
        enduranceRun: 381.41,
        sprintRun: 7.31,
        standingJump: 225.69,
        handballThrow: 25.79,
        totalScore: 53.5,
        height: 169.38,
        weight: 60.42,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.04,
        sitUps: 31.86,
        bending: 53.48,
        sideJump: 58.88,
        shuttleRun: 93.82,
        enduranceRun: 378.21,
        sprintRun: 7.25,
        standingJump: 229.81,
        handballThrow: 26.7,
        totalScore: 54.5,
        height: 170.11,
        weight: 62.36,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.53,
        sitUps: 10.54,
        bending: 30.02,
        sideJump: 26.68,
        shuttleRun: 16.64,
        enduranceRun: null,
        sprintRun: 12,
        standingJump: 106.56,
        handballThrow: 5.63,
        totalScore: 29,
        height: 115.66,
        weight: 21.48,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.21,
        sitUps: 13.24,
        bending: 32.26,
        sideJump: 30.05,
        shuttleRun: 22.63,
        enduranceRun: null,
        sprintRun: 11.08,
        standingJump: 116.41,
        handballThrow: 7.68,
        totalScore: 36,
        height: 121.91,
        weight: 24.36,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.85,
        sitUps: 14.62,
        bending: 32.81,
        sideJump: 32.07,
        shuttleRun: 26.56,
        enduranceRun: null,
        sprintRun: 10.61,
        standingJump: 125.44,
        handballThrow: 9.32,
        totalScore: 43,
        height: 127.85,
        weight: 27.5,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.78,
        sitUps: 16.31,
        bending: 34.83,
        sideJump: 35.62,
        shuttleRun: 33.09,
        enduranceRun: null,
        sprintRun: 10.16,
        standingJump: 133.55,
        handballThrow: 11.48,
        totalScore: 51,
        height: 134.28,
        weight: 31.31,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.52,
        sitUps: 17.95,
        bending: 37.62,
        sideJump: 39.82,
        shuttleRun: 40.58,
        enduranceRun: null,
        sprintRun: 9.68,
        standingJump: 144.36,
        handballThrow: 13.72,
        totalScore: 57,
        height: 141.27,
        weight: 35.74,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.33,
        sitUps: 19.38,
        bending: 39.99,
        sideJump: 42.45,
        shuttleRun: 45.63,
        enduranceRun: null,
        sprintRun: 9.32,
        standingJump: 151.88,
        handballThrow: 15.65,
        totalScore: 62,
        height: 147.6,
        weight: 40.83,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.8,
        sitUps: 19.89,
        bending: 42.22,
        sideJump: 48.07,
        shuttleRun: 52.53,
        enduranceRun: 310.86,
        sprintRun: 8.97,
        standingJump: 17.52,
        handballThrow: 13.65,
        totalScore: 44.5,
        height: 152.13,
        weight: 44.89,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.63,
        sitUps: 21.7,
        bending: 44.61,
        sideJump: 46.92,
        shuttleRun: 57.16,
        enduranceRun: 301.04,
        sprintRun: 8.89,
        standingJump: 170.4,
        handballThrow: 13.05,
        totalScore: 50,
        height: 154.65,
        weight: 47.74,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.18,
        sitUps: 23.01,
        bending: 46.95,
        sideJump: 48.59,
        shuttleRun: 60.08,
        enduranceRun: 297.91,
        sprintRun: 8.79,
        standingJump: 174.49,
        handballThrow: 14.06,
        totalScore: 53,
        height: 156.11,
        weight: 49.9,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.09,
        sitUps: 22.62,
        bending: 48.03,
        sideJump: 48.07,
        shuttleRun: 52.53,
        enduranceRun: 310.86,
        sprintRun: 8.97,
        standingJump: 21.42,
        handballThrow: 13.65,
        totalScore: 48,
        height: 156.55,
        weight: 51.36,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.91,
        sitUps: 23.55,
        bending: 49.43,
        sideJump: 48.87,
        shuttleRun: 54.21,
        enduranceRun: 311.42,
        sprintRun: 8.9,
        standingJump: 174.96,
        handballThrow: 14.42,
        totalScore: 52,
        height: 157.3,
        weight: 52.41,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.36,
        sitUps: 24.2,
        bending: 50.87,
        sideJump: 49.25,
        shuttleRun: 57.65,
        enduranceRun: 310.28,
        sprintRun: 8.87,
        standingJump: 176.23,
        handballThrow: 14.74,
        totalScore: 51,
        height: 157.5,
        weight: 52.59,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  石川県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.42,
        sitUps: 17.68,
        bending: 33.67,
        sideJump: 39.25,
        shuttleRun: 44.52,
        enduranceRun: null,
        sprintRun: 9.78,
        standingJump: 147.31,
        handballThrow: 18.4,
        totalScore: 49.89,
        height: 134.11,
        weight: 31.63,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.69,
        sitUps: 19.55,
        bending: 36.07,
        sideJump: 43.15,
        shuttleRun: 52.46,
        enduranceRun: null,
        sprintRun: 9.42,
        standingJump: 155.04,
        handballThrow: 21.8,
        totalScore: 55.18,
        height: 139.85,
        weight: 35.62,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.79,
        sitUps: 21.38,
        bending: 38.11,
        sideJump: 46.1,
        shuttleRun: 59.92,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 166.55,
        handballThrow: 25.3,
        totalScore: 60.66,
        height: 146.54,
        weight: 40.41,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.15,
        sitUps: 22.56,
        bending: 41.2,
        sideJump: 48.36,
        shuttleRun: 65.2,
        enduranceRun: 451.93,
        sprintRun: 8.64,
        standingJump: 183.6,
        handballThrow: 17.87,
        totalScore: 33.8,
        height: 154.37,
        weight: 45.55,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.81,
        sitUps: 26.28,
        bending: 46.06,
        sideJump: 52.1,
        shuttleRun: 81.9,
        enduranceRun: 409.53,
        sprintRun: 8,
        standingJump: 202.46,
        handballThrow: 21.32,
        totalScore: 43.33,
        height: 161.42,
        weight: 50.42,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.7,
        sitUps: 28.64,
        bending: 49.79,
        sideJump: 55.69,
        shuttleRun: 88.45,
        enduranceRun: 393.69,
        sprintRun: 7.56,
        standingJump: 216.21,
        handballThrow: 24.23,
        totalScore: 50.42,
        height: 166.6,
        weight: 55,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.7,
        sitUps: 27.58,
        bending: 48.03,
        sideJump: 55.59,
        shuttleRun: 84.34,
        enduranceRun: 405.47,
        sprintRun: 7.58,
        standingJump: 217.24,
        handballThrow: 23.3,
        totalScore: 48.95,
        height: 168.98,
        weight: 58.94,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.02,
        sitUps: 29.2,
        bending: 49.97,
        sideJump: 57.07,
        shuttleRun: 90.82,
        enduranceRun: 387.2,
        sprintRun: 7.4,
        standingJump: 225.02,
        handballThrow: 24.52,
        totalScore: 52.9,
        height: 170.25,
        weight: 60.46,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.68,
        sitUps: 30.63,
        bending: 52.05,
        sideJump: 58.55,
        shuttleRun: 92.42,
        enduranceRun: 384.09,
        sprintRun: 7.29,
        standingJump: 230.13,
        handballThrow: 25.75,
        totalScore: 55.93,
        height: 171.14,
        weight: 62.7,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.88,
        sitUps: 16.81,
        bending: 37.5,
        sideJump: 37.67,
        shuttleRun: 35.89,
        enduranceRun: null,
        sprintRun: 10.02,
        standingJump: 141.95,
        handballThrow: 12.1,
        totalScore: 51.45,
        height: 134.87,
        weight: 31.03,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.4,
        sitUps: 18.68,
        bending: 40.6,
        sideJump: 41.61,
        shuttleRun: 43.62,
        enduranceRun: null,
        sprintRun: 9.57,
        standingJump: 150.65,
        handballThrow: 14.41,
        totalScore: 57.75,
        height: 141.53,
        weight: 35.31,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.28,
        sitUps: 19.72,
        bending: 43.03,
        sideJump: 43.87,
        shuttleRun: 48.25,
        enduranceRun: null,
        sprintRun: 9.24,
        standingJump: 158.36,
        handballThrow: 16.35,
        totalScore: 62.22,
        height: 148.14,
        weight: 40.25,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.33,
        sitUps: 19.07,
        bending: 45.07,
        sideJump: 44.7,
        shuttleRun: 47.77,
        enduranceRun: 317.14,
        sprintRun: 9.21,
        standingJump: 165.46,
        handballThrow: 11.58,
        totalScore: 43.61,
        height: 152.59,
        weight: 44.16,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.56,
        sitUps: 21.74,
        bending: 47.56,
        sideJump: 46.79,
        shuttleRun: 52.88,
        enduranceRun: 304.73,
        sprintRun: 8.95,
        standingJump: 170.77,
        handballThrow: 13.16,
        totalScore: 49.21,
        height: 155.58,
        weight: 47.3,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.33,
        sitUps: 23.15,
        bending: 50.67,
        sideJump: 47.74,
        shuttleRun: 52.89,
        enduranceRun: 311.99,
        sprintRun: 8.86,
        standingJump: 173.66,
        handballThrow: 13.96,
        totalScore: 52.23,
        height: 157.02,
        weight: 49.49,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.94,
        sitUps: 21.95,
        bending: 49.47,
        sideJump: 47.99,
        shuttleRun: 48.72,
        enduranceRun: 315.58,
        sprintRun: 9.03,
        standingJump: 172.06,
        handballThrow: 13.57,
        totalScore: 50.11,
        height: 157.42,
        weight: 50.68,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26,
        sitUps: 23.16,
        bending: 50.76,
        sideJump: 48.7,
        shuttleRun: 50.97,
        enduranceRun: 314.91,
        sprintRun: 8.97,
        standingJump: 173.85,
        handballThrow: 14.19,
        totalScore: 52.16,
        height: 157.95,
        weight: 51.78,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.48,
        sitUps: 23.71,
        bending: 52.42,
        sideJump: 49.43,
        shuttleRun: 51.63,
        enduranceRun: 329,
        sprintRun: 8.94,
        standingJump: 175.83,
        handballThrow: 15.72,
        totalScore: 54.49,
        height: 158.19,
        weight: 52.2,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  神奈川県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.67,
        sitUps: 11.17,
        bending: 27.79,
        sideJump: 25.31,
        shuttleRun: 16.18,
        enduranceRun: null,
        sprintRun: 11.77,
        standingJump: 111.68,
        handballThrow: 7.45,
        totalScore: 29.04,
        height: 117.05,
        weight: 21.74,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.46,
        sitUps: 13.72,
        bending: 29.09,
        sideJump: 28.44,
        shuttleRun: 23.66,
        enduranceRun: null,
        sprintRun: 10.81,
        standingJump: 123.3,
        handballThrow: 10.62,
        totalScore: 35.85,
        height: 122.98,
        weight: 24.57,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.1,
        sitUps: 15.72,
        bending: 31.12,
        sideJump: 31.14,
        shuttleRun: 29.73,
        enduranceRun: null,
        sprintRun: 10.22,
        standingJump: 132.61,
        handballThrow: 13.53,
        totalScore: 41.14,
        height: 128.59,
        weight: 27.81,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.19,
        sitUps: 17.52,
        bending: 33.34,
        sideJump: 34.34,
        shuttleRun: 35.19,
        enduranceRun: null,
        sprintRun: 9.86,
        standingJump: 140.15,
        handballThrow: 16.75,
        totalScore: 46.34,
        height: 134.08,
        weight: 31.38,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.58,
        sitUps: 19.31,
        bending: 34.9,
        sideJump: 39,
        shuttleRun: 43.5,
        enduranceRun: null,
        sprintRun: 9.45,
        standingJump: 149.05,
        handballThrow: 20.17,
        totalScore: 52.11,
        height: 139.94,
        weight: 35.21,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.52,
        sitUps: 20.86,
        bending: 37.19,
        sideJump: 41.89,
        shuttleRun: 51.76,
        enduranceRun: null,
        sprintRun: 9,
        standingJump: 161.19,
        handballThrow: 23.36,
        totalScore: 57.79,
        height: 146.43,
        weight: 39.82,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.79,
        sitUps: 22.36,
        bending: 39.07,
        sideJump: 45.86,
        shuttleRun: 61.4,
        enduranceRun: 452.56,
        sprintRun: 8.58,
        standingJump: 178,
        handballThrow: 17.5,
        totalScore: 31.95,
        height: 154.78,
        weight: 45.49,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.72,
        sitUps: 25.57,
        bending: 42.99,
        sideJump: 50.01,
        shuttleRun: 79.33,
        enduranceRun: 408.97,
        sprintRun: 7.98,
        standingJump: 193.78,
        handballThrow: 20.39,
        totalScore: 40.51,
        height: 161.7,
        weight: 50.04,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.02,
        sitUps: 27.63,
        bending: 44.57,
        sideJump: 52.71,
        shuttleRun: 87.11,
        enduranceRun: 393.01,
        sprintRun: 7.57,
        standingJump: 206.47,
        handballThrow: 22.94,
        totalScore: 46.48,
        height: 166.24,
        weight: 54.15,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 35.1,
        sitUps: 27.06,
        bending: 44.53,
        sideJump: 52.62,
        shuttleRun: 79.92,
        enduranceRun: 416.39,
        sprintRun: 7.65,
        standingJump: 212.31,
        handballThrow: 22.02,
        totalScore: 45.81,
        height: 168.7,
        weight: 57.99,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 37.48,
        sitUps: 28.31,
        bending: 45.91,
        sideJump: 54.47,
        shuttleRun: 83.55,
        enduranceRun: 419.74,
        sprintRun: 7.51,
        standingJump: 217.08,
        handballThrow: 23.18,
        totalScore: 48.97,
        height: 170.05,
        weight: 59.82,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 38.49,
        sitUps: 28.71,
        bending: 47.21,
        sideJump: 54.57,
        shuttleRun: 83.67,
        enduranceRun: 425.91,
        sprintRun: 7.46,
        standingJump: 219.78,
        handballThrow: 23.9,
        totalScore: 50.16,
        height: 170.88,
        weight: 61.7,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: 40.06,
        sitUps: 21.88,
        bending: 45.57,
        sideJump: 45.87,
        shuttleRun: 55.15,
        enduranceRun: null,
        sprintRun: 8.39,
        standingJump: 207.14,
        handballThrow: 22.1,
        totalScore: 39.39,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.13,
        sitUps: 11.19,
        bending: 29.96,
        sideJump: 24.35,
        shuttleRun: 13.54,
        enduranceRun: null,
        sprintRun: 12.08,
        standingJump: 104.14,
        handballThrow: 5.37,
        totalScore: 29.11,
        height: 116.28,
        weight: 21.27,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.75,
        sitUps: 13.18,
        bending: 31.44,
        sideJump: 27.12,
        shuttleRun: 18.19,
        enduranceRun: null,
        sprintRun: 11.12,
        standingJump: 114.51,
        handballThrow: 7.01,
        totalScore: 35.73,
        height: 122.18,
        weight: 24.04,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.4,
        sitUps: 15.07,
        bending: 33.82,
        sideJump: 29.51,
        shuttleRun: 22.21,
        enduranceRun: null,
        sprintRun: 10.55,
        standingJump: 123.78,
        handballThrow: 8.86,
        totalScore: 41.34,
        height: 128.06,
        weight: 27.05,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.49,
        sitUps: 16.74,
        bending: 36.53,
        sideJump: 32.87,
        shuttleRun: 26.1,
        enduranceRun: null,
        sprintRun: 10.08,
        standingJump: 132.69,
        handballThrow: 10.68,
        totalScore: 47.02,
        height: 134.56,
        weight: 30.85,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.25,
        sitUps: 18.21,
        bending: 39.13,
        sideJump: 36.44,
        shuttleRun: 32.42,
        enduranceRun: null,
        sprintRun: 9.69,
        standingJump: 142.19,
        handballThrow: 12.54,
        totalScore: 52.88,
        height: 141.77,
        weight: 35.36,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.06,
        sitUps: 18.84,
        bending: 41.67,
        sideJump: 38.67,
        shuttleRun: 37.59,
        enduranceRun: null,
        sprintRun: 9.34,
        standingJump: 149.64,
        handballThrow: 14,
        totalScore: 57.64,
        height: 148.02,
        weight: 40.25,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 20.61,
        sitUps: 19.08,
        bending: 42.07,
        sideJump: 41.75,
        shuttleRun: 41.99,
        enduranceRun: 332.44,
        sprintRun: 9.12,
        standingJump: 158.88,
        handballThrow: 10.71,
        totalScore: 40.03,
        height: 152.76,
        weight: 43.64,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 22.9,
        sitUps: 21.13,
        bending: 44.64,
        sideJump: 44.7,
        shuttleRun: 49.42,
        enduranceRun: 308.95,
        sprintRun: 8.87,
        standingJump: 164.31,
        handballThrow: 12.25,
        totalScore: 46.05,
        height: 155.55,
        weight: 46.51,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.15,
        sitUps: 22.23,
        bending: 46.1,
        sideJump: 45.49,
        shuttleRun: 51.79,
        enduranceRun: 308.9,
        sprintRun: 8.75,
        standingJump: 166.22,
        handballThrow: 13.49,
        totalScore: 48.86,
        height: 157.01,
        weight: 48.74,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.19,
        sitUps: 21.11,
        bending: 45.09,
        sideJump: 45.46,
        shuttleRun: 45.8,
        enduranceRun: 326.92,
        sprintRun: 9.08,
        standingJump: 164.29,
        handballThrow: 12.75,
        totalScore: 46.23,
        height: 157.34,
        weight: 50.9,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 24.74,
        sitUps: 21.9,
        bending: 46.4,
        sideJump: 45.87,
        shuttleRun: 45.92,
        enduranceRun: 321.28,
        sprintRun: 9.14,
        standingJump: 164.6,
        handballThrow: 13.21,
        totalScore: 47.06,
        height: 157.46,
        weight: 51.38,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 25.14,
        sitUps: 21.83,
        bending: 46.2,
        sideJump: 45.84,
        shuttleRun: 44.44,
        enduranceRun: 321.31,
        sprintRun: 9.27,
        standingJump: 164.15,
        handballThrow: 13.34,
        totalScore: 46.85,
        height: 158.2,
        weight: 52.25,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: 23.52,
        sitUps: 15.58,
        bending: 38.24,
        sideJump: 38.42,
        shuttleRun: 25.95,
        enduranceRun: 502.4,
        sprintRun: 9.91,
        standingJump: 145.81,
        handballThrow: 10.84,
        totalScore: 32.93,
        height: null,
        weight: null,
      },
    ],
  },
  福井県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.54,
        sitUps: 17.7,
        bending: 34.51,
        sideJump: 40.14,
        shuttleRun: 49.24,
        enduranceRun: null,
        sprintRun: 9.63,
        standingJump: 149.53,
        handballThrow: 19.07,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.82,
        sitUps: 19.97,
        bending: 36.89,
        sideJump: 43.97,
        shuttleRun: 57.16,
        enduranceRun: null,
        sprintRun: 9.3,
        standingJump: 158.69,
        handballThrow: 22.52,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 20.01,
        sitUps: 21.63,
        bending: 38.67,
        sideJump: 46.46,
        shuttleRun: 65.74,
        enduranceRun: null,
        sprintRun: 8.87,
        standingJump: 169.64,
        handballThrow: 25.72,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.42,
        sitUps: 23.17,
        bending: 42.01,
        sideJump: 49.38,
        shuttleRun: 72.24,
        enduranceRun: 423.04,
        sprintRun: 8.53,
        standingJump: 185.2,
        handballThrow: 18.72,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.77,
        sitUps: 27.12,
        bending: 46.38,
        sideJump: 53.01,
        shuttleRun: 85.02,
        enduranceRun: 383.06,
        sprintRun: 7.85,
        standingJump: 204.98,
        handballThrow: 22.37,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.53,
        sitUps: 28.97,
        bending: 49.35,
        sideJump: 55.12,
        shuttleRun: 92.9,
        enduranceRun: 368.22,
        sprintRun: 7.45,
        standingJump: 216.71,
        handballThrow: 25.26,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.22,
        sitUps: 29.65,
        bending: 49.28,
        sideJump: 56.81,
        shuttleRun: 85.37,
        enduranceRun: 388.48,
        sprintRun: 7.4,
        standingJump: 219.82,
        handballThrow: 23.84,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.87,
        sitUps: 31.17,
        bending: 51.91,
        sideJump: 58.49,
        shuttleRun: 93.06,
        enduranceRun: 378.69,
        sprintRun: 7.18,
        standingJump: 227.36,
        handballThrow: 25.65,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.38,
        sitUps: 32.55,
        bending: 52.8,
        sideJump: 59.73,
        shuttleRun: 92.35,
        enduranceRun: 372.11,
        sprintRun: 7.09,
        standingJump: 230.87,
        handballThrow: 26.85,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.93,
        sitUps: 16.87,
        bending: 37.87,
        sideJump: 38.39,
        shuttleRun: 40.32,
        enduranceRun: null,
        sprintRun: 9.88,
        standingJump: 142.71,
        handballThrow: 13.09,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.51,
        sitUps: 19.1,
        bending: 41.27,
        sideJump: 42.34,
        shuttleRun: 49,
        enduranceRun: null,
        sprintRun: 9.48,
        standingJump: 153.84,
        handballThrow: 15.24,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.43,
        sitUps: 20.38,
        bending: 42.98,
        sideJump: 44.56,
        shuttleRun: 53.68,
        enduranceRun: null,
        sprintRun: 9.12,
        standingJump: 161.25,
        handballThrow: 17.27,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.66,
        sitUps: 20.46,
        bending: 45.77,
        sideJump: 46.17,
        shuttleRun: 55.42,
        enduranceRun: 297.7,
        sprintRun: 9.03,
        standingJump: 169.3,
        handballThrow: 12.28,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.91,
        sitUps: 22.63,
        bending: 48.38,
        sideJump: 47.16,
        shuttleRun: 59.9,
        enduranceRun: 287.57,
        sprintRun: 8.78,
        standingJump: 173.28,
        handballThrow: 13.63,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.28,
        sitUps: 24.08,
        bending: 50.54,
        sideJump: 47.45,
        shuttleRun: 59.72,
        enduranceRun: 284.5,
        sprintRun: 8.64,
        standingJump: 174.83,
        handballThrow: 14.45,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.68,
        sitUps: 24.18,
        bending: 50.61,
        sideJump: 48.7,
        shuttleRun: 54.15,
        enduranceRun: 302.26,
        sprintRun: 8.8,
        standingJump: 173.69,
        handballThrow: 13.71,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.7,
        sitUps: 25.16,
        bending: 52.17,
        sideJump: 50.03,
        shuttleRun: 55.04,
        enduranceRun: 303.76,
        sprintRun: 8.72,
        standingJump: 175.5,
        handballThrow: 14.24,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 27.19,
        sitUps: 26.49,
        bending: 53.02,
        sideJump: 50.56,
        shuttleRun: 56.19,
        enduranceRun: 299.2,
        sprintRun: 8.77,
        standingJump: 176.37,
        handballThrow: 14.85,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  福岡県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.68,
        sitUps: 10.94,
        bending: 27.59,
        sideJump: 26.25,
        shuttleRun: 18.17,
        enduranceRun: null,
        sprintRun: 11.81,
        standingJump: 114.74,
        handballThrow: 8.15,
        totalScore: 29.95,
        height: 116.59,
        weight: 21.61,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.38,
        sitUps: 13.68,
        bending: 29.13,
        sideJump: 30.24,
        shuttleRun: 26.81,
        enduranceRun: null,
        sprintRun: 10.89,
        standingJump: 124.78,
        handballThrow: 11.43,
        totalScore: 36.86,
        height: 122.52,
        weight: 24,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.1,
        sitUps: 15.57,
        bending: 30.59,
        sideJump: 33.26,
        shuttleRun: 33.98,
        enduranceRun: null,
        sprintRun: 10.31,
        standingJump: 134.13,
        handballThrow: 14.72,
        totalScore: 42.36,
        height: 128.27,
        weight: 27.83,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.94,
        sitUps: 17.22,
        bending: 32.4,
        sideJump: 36.99,
        shuttleRun: 40.71,
        enduranceRun: null,
        sprintRun: 9.89,
        standingJump: 14.5,
        handballThrow: 17.98,
        totalScore: 47.53,
        height: 133.72,
        weight: 31.59,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.1,
        sitUps: 19.03,
        bending: 34.24,
        sideJump: 40.81,
        shuttleRun: 48.45,
        enduranceRun: null,
        sprintRun: 9.47,
        standingJump: 151.37,
        handballThrow: 21.5,
        totalScore: 53.11,
        height: 139.45,
        weight: 35.29,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.02,
        sitUps: 20.74,
        bending: 36.16,
        sideJump: 43.8,
        shuttleRun: 55.94,
        enduranceRun: null,
        sprintRun: 9.08,
        standingJump: 162.24,
        handballThrow: 25.06,
        totalScore: 58.49,
        height: 145.8,
        weight: 40.02,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.01,
        sitUps: 22.72,
        bending: 39.84,
        sideJump: 48.88,
        shuttleRun: 66.79,
        enduranceRun: null,
        sprintRun: 8.58,
        standingJump: 183.03,
        handballThrow: 17.96,
        totalScore: 34.22,
        height: 153.81,
        weight: 45.4,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.27,
        sitUps: 25.88,
        bending: 43.62,
        sideJump: 51.99,
        shuttleRun: 81.23,
        enduranceRun: null,
        sprintRun: 8,
        standingJump: 199.86,
        handballThrow: 20.87,
        totalScore: 42.31,
        height: 161.07,
        weight: 49.88,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.12,
        sitUps: 28.48,
        bending: 48.37,
        sideJump: 55.53,
        shuttleRun: 90.07,
        enduranceRun: null,
        sprintRun: 7.56,
        standingJump: 215.65,
        handballThrow: 23.43,
        totalScore: 50.16,
        height: 165.5,
        weight: 54.69,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.58,
        sitUps: 28.14,
        bending: 47.46,
        sideJump: 56.01,
        shuttleRun: 82.43,
        enduranceRun: null,
        sprintRun: 7.58,
        standingJump: 216.43,
        handballThrow: 23.36,
        totalScore: 49.63,
        height: 168.07,
        weight: 58.4,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.78,
        sitUps: 30.26,
        bending: 50.25,
        sideJump: 57.56,
        shuttleRun: 89.15,
        enduranceRun: null,
        sprintRun: 7.36,
        standingJump: 223.44,
        handballThrow: 24.79,
        totalScore: 54.22,
        height: 169.43,
        weight: 59.51,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.1,
        sitUps: 23.48,
        bending: 49.15,
        sideJump: 47.86,
        shuttleRun: 49.94,
        enduranceRun: null,
        sprintRun: 9,
        standingJump: 169.82,
        handballThrow: 13.55,
        totalScore: 51.43,
        height: 170.32,
        weight: 61.59,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.16,
        sitUps: 10.59,
        bending: 29.63,
        sideJump: 25.18,
        shuttleRun: 15.22,
        enduranceRun: null,
        sprintRun: 12.14,
        standingJump: 106.39,
        handballThrow: 5.51,
        totalScore: 29.64,
        height: 115.68,
        weight: 21.11,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.78,
        sitUps: 13,
        bending: 31.69,
        sideJump: 29.06,
        shuttleRun: 20.88,
        enduranceRun: null,
        sprintRun: 11.22,
        standingJump: 115.5,
        handballThrow: 7.9,
        totalScore: 36.71,
        height: 121.65,
        weight: 24,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.45,
        sitUps: 14.76,
        bending: 33.53,
        sideJump: 31.63,
        shuttleRun: 25.47,
        enduranceRun: null,
        sprintRun: 10.67,
        standingJump: 124.79,
        handballThrow: 9.2,
        totalScore: 42.34,
        height: 127.58,
        weight: 27.23,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.42,
        sitUps: 16.31,
        bending: 35.9,
        sideJump: 35.22,
        shuttleRun: 30.96,
        enduranceRun: null,
        sprintRun: 10.2,
        standingJump: 134.11,
        handballThrow: 11.18,
        totalScore: 48.15,
        height: 134.12,
        weight: 31.05,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.05,
        sitUps: 17.9,
        bending: 38.51,
        sideJump: 38.6,
        shuttleRun: 37.26,
        enduranceRun: null,
        sprintRun: 9.74,
        standingJump: 143.55,
        handballThrow: 13.35,
        totalScore: 54.21,
        height: 141.35,
        weight: 35.43,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.79,
        sitUps: 18.96,
        bending: 40.76,
        sideJump: 40.92,
        shuttleRun: 41.58,
        enduranceRun: null,
        sprintRun: 9.41,
        standingJump: 151.11,
        handballThrow: 15.07,
        totalScore: 58.71,
        height: 147.57,
        weight: 40.66,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.31,
        sitUps: 19.45,
        bending: 43.1,
        sideJump: 44.71,
        shuttleRun: 45.93,
        enduranceRun: null,
        sprintRun: 9.22,
        standingJump: 163.9,
        handballThrow: 11.05,
        totalScore: 42.85,
        height: 152.04,
        weight: 44.34,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.2,
        sitUps: 21.39,
        bending: 45.56,
        sideJump: 46.22,
        shuttleRun: 52.57,
        enduranceRun: null,
        sprintRun: 8.98,
        standingJump: 168.82,
        handballThrow: 12.42,
        totalScore: 47.68,
        height: 154.82,
        weight: 46.52,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.59,
        sitUps: 22.79,
        bending: 48.16,
        sideJump: 47.57,
        shuttleRun: 53.99,
        enduranceRun: null,
        sprintRun: 8.84,
        standingJump: 173.58,
        handballThrow: 13.35,
        totalScore: 51.25,
        height: 155.97,
        weight: 49.31,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.26,
        sitUps: 21.94,
        bending: 47.22,
        sideJump: 47.49,
        shuttleRun: 47.22,
        enduranceRun: null,
        sprintRun: 9.11,
        standingJump: 168.3,
        handballThrow: 13,
        totalScore: 48.95,
        height: 156.6,
        weight: 50.32,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.1,
        sitUps: 23.48,
        bending: 49.15,
        sideJump: 47.86,
        shuttleRun: 49.94,
        enduranceRun: null,
        sprintRun: 9,
        standingJump: 169.82,
        handballThrow: 13.55,
        totalScore: 51.43,
        height: 157.12,
        weight: 51.05,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.6,
        sitUps: 24.26,
        bending: 50.38,
        sideJump: 48.33,
        shuttleRun: 49.83,
        enduranceRun: null,
        sprintRun: 9.02,
        standingJump: 170.93,
        handballThrow: 13.86,
        totalScore: 52.39,
        height: 157.54,
        weight: 51.43,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  群馬県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.04,
        sitUps: 12.04,
        bending: 25.8,
        sideJump: 26.12,
        shuttleRun: 17.75,
        enduranceRun: null,
        sprintRun: 11.61,
        standingJump: 112.29,
        handballThrow: 7.82,
        totalScore: 30.02,
        height: 117.39,
        weight: 22.08,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.56,
        sitUps: 14.03,
        bending: 27.61,
        sideJump: 29.79,
        shuttleRun: 26.2,
        enduranceRun: null,
        sprintRun: 10.77,
        standingJump: 123.38,
        handballThrow: 10.66,
        totalScore: 36.44,
        height: 123.29,
        weight: 24.85,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.26,
        sitUps: 15.81,
        bending: 29.81,
        sideJump: 33.21,
        shuttleRun: 32.33,
        enduranceRun: null,
        sprintRun: 10.25,
        standingJump: 132.01,
        handballThrow: 13.74,
        totalScore: 41.91,
        height: 128.91,
        weight: 28.35,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.04,
        sitUps: 17.35,
        bending: 31.45,
        sideJump: 37.06,
        shuttleRun: 38.88,
        enduranceRun: null,
        sprintRun: 9.78,
        standingJump: 141.67,
        handballThrow: 16.91,
        totalScore: 47.25,
        height: 134.34,
        weight: 32,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.23,
        sitUps: 19.23,
        bending: 34.3,
        sideJump: 41.15,
        shuttleRun: 46.8,
        enduranceRun: null,
        sprintRun: 9.36,
        standingJump: 150.75,
        handballThrow: 19.89,
        totalScore: 53.01,
        height: 140.06,
        weight: 36.13,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.44,
        sitUps: 21.02,
        bending: 36.52,
        sideJump: 44.57,
        shuttleRun: 53.8,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 161.98,
        handballThrow: 23.19,
        totalScore: 58.69,
        height: 146.42,
        weight: 40.69,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.84,
        sitUps: 23.04,
        bending: 40.72,
        sideJump: 48.11,
        shuttleRun: 61.58,
        enduranceRun: 443.68,
        sprintRun: 8.62,
        standingJump: 181.01,
        handballThrow: 16.69,
        totalScore: 32.79,
        height: 153.85,
        weight: 45.81,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.24,
        sitUps: 25.99,
        bending: 44.31,
        sideJump: 51.83,
        shuttleRun: 77.76,
        enduranceRun: 407.47,
        sprintRun: 7.98,
        standingJump: 198.74,
        handballThrow: 19.73,
        totalScore: 41.05,
        height: 160.81,
        weight: 50.81,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.63,
        sitUps: 27.89,
        bending: 47.93,
        sideJump: 54.3,
        shuttleRun: 85.61,
        enduranceRun: 387.26,
        sprintRun: 7.59,
        standingJump: 211.96,
        handballThrow: 22.2,
        totalScore: 47.47,
        height: 165.46,
        weight: 54.81,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.81,
        sitUps: 27.83,
        bending: 48.15,
        sideJump: 55.83,
        shuttleRun: 79.84,
        enduranceRun: 391.02,
        sprintRun: 7.43,
        standingJump: 216.35,
        handballThrow: 22.92,
        totalScore: 49.17,
        height: 168.11,
        weight: 58.98,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.81,
        sitUps: 29.08,
        bending: 49.29,
        sideJump: 57.01,
        shuttleRun: 85.17,
        enduranceRun: 384.85,
        sprintRun: 7.29,
        standingJump: 221.76,
        handballThrow: 23.99,
        totalScore: 52.15,
        height: 169.66,
        weight: 60.35,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.37,
        sitUps: 30.62,
        bending: 51.74,
        sideJump: 58.27,
        shuttleRun: 88.84,
        enduranceRun: 381.7,
        sprintRun: 7.19,
        standingJump: 226.62,
        handballThrow: 25.35,
        totalScore: 55.2,
        height: 170.38,
        weight: 62.34,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.52,
        sitUps: 11.81,
        bending: 28.25,
        sideJump: 25.85,
        shuttleRun: 15.19,
        enduranceRun: null,
        sprintRun: 11.9,
        standingJump: 106.68,
        handballThrow: 5.5,
        totalScore: 30.57,
        height: 116.43,
        weight: 21.54,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.01,
        sitUps: 13.94,
        bending: 30.33,
        sideJump: 29.05,
        shuttleRun: 21.15,
        enduranceRun: null,
        sprintRun: 11.06,
        standingJump: 116.32,
        handballThrow: 7.28,
        totalScore: 37.35,
        height: 122.3,
        weight: 24.22,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.66,
        sitUps: 15.32,
        bending: 33.35,
        sideJump: 32.21,
        shuttleRun: 26.09,
        enduranceRun: null,
        sprintRun: 10.52,
        standingJump: 125.67,
        handballThrow: 9.22,
        totalScore: 43.28,
        height: 128.35,
        weight: 27.55,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.72,
        sitUps: 17.17,
        bending: 35.58,
        sideJump: 36.09,
        shuttleRun: 31.8,
        enduranceRun: null,
        sprintRun: 10.01,
        standingJump: 136.3,
        handballThrow: 11.44,
        totalScore: 49.54,
        height: 134.87,
        weight: 31.5,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.17,
        sitUps: 18.57,
        bending: 38.93,
        sideJump: 39.46,
        shuttleRun: 38.53,
        enduranceRun: null,
        sprintRun: 9.57,
        standingJump: 145.65,
        handballThrow: 13.4,
        totalScore: 55.51,
        height: 141.69,
        weight: 36.05,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.14,
        sitUps: 19.53,
        bending: 41.6,
        sideJump: 42.38,
        shuttleRun: 42.72,
        enduranceRun: null,
        sprintRun: 9.25,
        standingJump: 153.39,
        handballThrow: 15.23,
        totalScore: 60.34,
        height: 147.98,
        weight: 41,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.54,
        sitUps: 20.19,
        bending: 44.84,
        sideJump: 44.51,
        shuttleRun: 46.52,
        enduranceRun: 312.43,
        sprintRun: 9.15,
        standingJump: 164.01,
        handballThrow: 10.87,
        totalScore: 43,
        height: 152.07,
        weight: 44.51,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.62,
        sitUps: 22.14,
        bending: 47.34,
        sideJump: 46.45,
        shuttleRun: 53.33,
        enduranceRun: 297.97,
        sprintRun: 8.86,
        standingJump: 170.8,
        handballThrow: 12.38,
        totalScore: 48.61,
        height: 154.75,
        weight: 47.58,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.13,
        sitUps: 23.6,
        bending: 49.75,
        sideJump: 47.64,
        shuttleRun: 55.17,
        enduranceRun: 294.19,
        sprintRun: 8.76,
        standingJump: 173.86,
        handballThrow: 13.52,
        totalScore: 52.13,
        height: 156.21,
        weight: 49.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.48,
        sitUps: 22.63,
        bending: 50.53,
        sideJump: 48.79,
        shuttleRun: 47.95,
        enduranceRun: 302.35,
        sprintRun: 8.78,
        standingJump: 173.44,
        handballThrow: 13.2,
        totalScore: 51.43,
        height: 157,
        weight: 51.04,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.24,
        sitUps: 23.41,
        bending: 51.24,
        sideJump: 48.94,
        shuttleRun: 49.71,
        enduranceRun: 303.51,
        sprintRun: 8.79,
        standingJump: 173.13,
        handballThrow: 13.67,
        totalScore: 52.44,
        height: 157.66,
        weight: 51.98,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.56,
        sitUps: 24.25,
        bending: 53.29,
        sideJump: 49.59,
        shuttleRun: 49.17,
        enduranceRun: 306.15,
        sprintRun: 8.81,
        standingJump: 174.48,
        handballThrow: 14.1,
        totalScore: 53.74,
        height: 157.76,
        weight: 52.12,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  茨城県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.04,
        sitUps: 12.58,
        bending: 27.02,
        sideJump: 27.22,
        shuttleRun: 20.59,
        enduranceRun: null,
        sprintRun: 11.64,
        standingJump: 115.38,
        handballThrow: 8.05,
        totalScore: 31.34,
        height: 116.86,
        weight: 21.93,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.54,
        sitUps: 15.46,
        bending: 28.92,
        sideJump: 31.12,
        shuttleRun: 29.68,
        enduranceRun: null,
        sprintRun: 10.74,
        standingJump: 125.86,
        handballThrow: 10.98,
        totalScore: 38.22,
        height: 122.97,
        weight: 24.91,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.43,
        sitUps: 17.6,
        bending: 31.56,
        sideJump: 34.87,
        shuttleRun: 36.75,
        enduranceRun: null,
        sprintRun: 10.16,
        standingJump: 136.68,
        handballThrow: 14.07,
        totalScore: 44.33,
        height: 128.66,
        weight: 28.43,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.35,
        sitUps: 18.9,
        bending: 34.02,
        sideJump: 38.36,
        shuttleRun: 42.61,
        enduranceRun: null,
        sprintRun: 9.74,
        standingJump: 144.31,
        handballThrow: 17.15,
        totalScore: 49.35,
        height: 134.26,
        weight: 32.39,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.69,
        sitUps: 20.4,
        bending: 36.42,
        sideJump: 41.97,
        shuttleRun: 49.5,
        enduranceRun: null,
        sprintRun: 9.37,
        standingJump: 153.96,
        handballThrow: 20.23,
        totalScore: 54.77,
        height: 139.74,
        weight: 36.3,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 20,
        sitUps: 22.03,
        bending: 39.19,
        sideJump: 44.74,
        shuttleRun: 56.41,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 164.41,
        handballThrow: 23.36,
        totalScore: 60.25,
        height: 146.42,
        weight: 40.96,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.51,
        sitUps: 24,
        bending: 43.25,
        sideJump: 48.25,
        shuttleRun: 65.31,
        enduranceRun: null,
        sprintRun: 8.49,
        standingJump: 180.88,
        handballThrow: 17.67,
        totalScore: 35.35,
        height: 154.28,
        weight: 46.41,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 30.2,
        sitUps: 26.89,
        bending: 47.33,
        sideJump: 52.01,
        shuttleRun: 80.47,
        enduranceRun: null,
        sprintRun: 7.83,
        standingJump: 199.69,
        handballThrow: 21.08,
        totalScore: 44.34,
        height: 161.01,
        weight: 51.13,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.85,
        sitUps: 28.8,
        bending: 50.39,
        sideJump: 54.51,
        shuttleRun: 87.57,
        enduranceRun: null,
        sprintRun: 7.43,
        standingJump: 212.92,
        handballThrow: 23.79,
        totalScore: 50.7,
        height: 165.82,
        weight: 55.52,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.33,
        sitUps: 28.07,
        bending: 48.49,
        sideJump: 56.22,
        shuttleRun: 80.81,
        enduranceRun: null,
        sprintRun: 7.48,
        standingJump: 215.34,
        handballThrow: 22.91,
        totalScore: 49.42,
        height: 168.24,
        weight: 59.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.4,
        sitUps: 29.16,
        bending: 49.78,
        sideJump: 57.21,
        shuttleRun: 85.34,
        enduranceRun: null,
        sprintRun: 7.34,
        standingJump: 221.34,
        handballThrow: 24.34,
        totalScore: 52.5,
        height: 169.6,
        weight: 61,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.09,
        sitUps: 30.32,
        bending: 51.82,
        sideJump: 58.37,
        shuttleRun: 87.54,
        enduranceRun: null,
        sprintRun: 7.23,
        standingJump: 225.09,
        handballThrow: 25.52,
        totalScore: 55.2,
        height: 170.42,
        weight: 62.82,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.53,
        sitUps: 12.12,
        bending: 29.3,
        sideJump: 26.45,
        shuttleRun: 17.3,
        enduranceRun: null,
        sprintRun: 11.9,
        standingJump: 108.47,
        handballThrow: 5.8,
        totalScore: 31.73,
        height: 115.98,
        weight: 21.48,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.98,
        sitUps: 14.72,
        bending: 31.79,
        sideJump: 30.21,
        shuttleRun: 23.78,
        enduranceRun: null,
        sprintRun: 11.03,
        standingJump: 118.5,
        handballThrow: 7.64,
        totalScore: 39.01,
        height: 122.01,
        weight: 24.26,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.79,
        sitUps: 17,
        bending: 34.66,
        sideJump: 33.77,
        shuttleRun: 29.2,
        enduranceRun: null,
        sprintRun: 10.44,
        standingJump: 128.64,
        handballThrow: 9.71,
        totalScore: 45.56,
        height: 127.95,
        weight: 27.56,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.83,
        sitUps: 18.3,
        bending: 37.75,
        sideJump: 37.14,
        shuttleRun: 34.94,
        enduranceRun: null,
        sprintRun: 9.98,
        standingJump: 137.72,
        handballThrow: 11.85,
        totalScore: 51.37,
        height: 134.53,
        weight: 31.56,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.65,
        sitUps: 19.8,
        bending: 40.94,
        sideJump: 40.5,
        shuttleRun: 41.9,
        enduranceRun: null,
        sprintRun: 9.54,
        standingJump: 147.1,
        handballThrow: 14.11,
        totalScore: 57.5,
        height: 141.47,
        weight: 36.05,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.69,
        sitUps: 20.79,
        bending: 44.24,
        sideJump: 42.63,
        shuttleRun: 45.76,
        enduranceRun: null,
        sprintRun: 9.21,
        standingJump: 154.58,
        handballThrow: 15.72,
        totalScore: 62.09,
        height: 147.91,
        weight: 41.08,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.61,
        sitUps: 20.97,
        bending: 46.51,
        sideJump: 44.08,
        shuttleRun: 47.68,
        enduranceRun: null,
        sprintRun: 9.03,
        standingJump: 160.84,
        handballThrow: 11.53,
        totalScore: 44.77,
        height: 152.43,
        weight: 44.87,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.85,
        sitUps: 22.67,
        bending: 49.29,
        sideJump: 46.1,
        shuttleRun: 53.68,
        enduranceRun: null,
        sprintRun: 8.75,
        standingJump: 167.49,
        handballThrow: 13.05,
        totalScore: 50.34,
        height: 154.92,
        weight: 47.65,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.26,
        sitUps: 23.66,
        bending: 51.76,
        sideJump: 46.89,
        shuttleRun: 54.13,
        enduranceRun: null,
        sprintRun: 8.64,
        standingJump: 171.02,
        handballThrow: 14.05,
        totalScore: 53.17,
        height: 156.13,
        weight: 49.82,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.05,
        sitUps: 22.61,
        bending: 49.77,
        sideJump: 48.19,
        shuttleRun: 47.22,
        enduranceRun: null,
        sprintRun: 8.91,
        standingJump: 168.84,
        handballThrow: 13.11,
        totalScore: 50.18,
        height: 156.76,
        weight: 51.5,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.74,
        sitUps: 23.12,
        bending: 50.82,
        sideJump: 48.46,
        shuttleRun: 47.53,
        enduranceRun: null,
        sprintRun: 8.94,
        standingJump: 170.35,
        handballThrow: 13.72,
        totalScore: 51.45,
        height: 157.21,
        weight: 52.55,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.19,
        sitUps: 23.88,
        bending: 52.5,
        sideJump: 49.06,
        shuttleRun: 48.21,
        enduranceRun: null,
        sprintRun: 8.93,
        standingJump: 171.1,
        handballThrow: 14.06,
        totalScore: 52.78,
        height: 157.55,
        weight: 52.75,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  長野県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.38,
        sitUps: 11.19,
        bending: 24.6,
        sideJump: 26.35,
        shuttleRun: 19.44,
        enduranceRun: null,
        sprintRun: 11.6,
        standingJump: 114.94,
        handballThrow: 8.35,
        totalScore: 30.16,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 11.36,
        sitUps: 14.22,
        bending: 26.79,
        sideJump: 30.88,
        shuttleRun: 28.48,
        enduranceRun: null,
        sprintRun: 10.88,
        standingJump: 124.83,
        handballThrow: 11.8,
        totalScore: 37.66,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.95,
        sitUps: 15.85,
        bending: 29.22,
        sideJump: 33.8,
        shuttleRun: 35.43,
        enduranceRun: null,
        sprintRun: 10.2,
        standingJump: 135.74,
        handballThrow: 14.99,
        totalScore: 43.39,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.34,
        sitUps: 16.66,
        bending: 30.38,
        sideJump: 36.4,
        shuttleRun: 39.64,
        enduranceRun: null,
        sprintRun: 10.07,
        standingJump: 142.41,
        handballThrow: 17.72,
        totalScore: 46.62,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 17.11,
        sitUps: 19.37,
        bending: 34.36,
        sideJump: 41.18,
        shuttleRun: 49.23,
        enduranceRun: null,
        sprintRun: 9.51,
        standingJump: 152.05,
        handballThrow: 21.39,
        totalScore: 53.67,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 20.51,
        sitUps: 20.69,
        bending: 36.49,
        sideJump: 45.27,
        shuttleRun: 56.88,
        enduranceRun: null,
        sprintRun: 9.1,
        standingJump: 164.6,
        handballThrow: 25.07,
        totalScore: 59.72,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.31,
        sitUps: 22.76,
        bending: 41.51,
        sideJump: 46.36,
        shuttleRun: 62.97,
        enduranceRun: 456.65,
        sprintRun: 8.5,
        standingJump: 181.75,
        handballThrow: 17.92,
        totalScore: 33.47,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.7,
        sitUps: 25.79,
        bending: 45.63,
        sideJump: 49.48,
        shuttleRun: 76.52,
        enduranceRun: 425.7,
        sprintRun: 7.9,
        standingJump: 198.24,
        handballThrow: 21.04,
        totalScore: 41.94,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.69,
        sitUps: 27.83,
        bending: 47.28,
        sideJump: 52.53,
        shuttleRun: 83.74,
        enduranceRun: 406.27,
        sprintRun: 7.51,
        standingJump: 214.09,
        handballThrow: 23.96,
        totalScore: 48.57,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.12,
        sitUps: 26.53,
        bending: 46.71,
        sideJump: 54,
        shuttleRun: 82.72,
        enduranceRun: 398.83,
        sprintRun: 7.47,
        standingJump: 218.56,
        handballThrow: 23.22,
        totalScore: 48.15,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.8,
        sitUps: 28.71,
        bending: 49.68,
        sideJump: 56.42,
        shuttleRun: 87.08,
        enduranceRun: 382.33,
        sprintRun: 7.32,
        standingJump: 223.12,
        handballThrow: 24.47,
        totalScore: 52.63,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.19,
        sitUps: 29.82,
        bending: 51.22,
        sideJump: 57.36,
        shuttleRun: 88.31,
        enduranceRun: 386.39,
        sprintRun: 7.25,
        standingJump: 229.86,
        handballThrow: 25.52,
        totalScore: 54.97,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.65,
        sitUps: 10.85,
        bending: 27.34,
        sideJump: 26.12,
        shuttleRun: 16.57,
        enduranceRun: null,
        sprintRun: 11.89,
        standingJump: 106.99,
        handballThrow: 5.81,
        totalScore: 30.48,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.44,
        sitUps: 13.5,
        bending: 29.68,
        sideJump: 29.86,
        shuttleRun: 22.11,
        enduranceRun: null,
        sprintRun: 11.15,
        standingJump: 117.8,
        handballThrow: 7.82,
        totalScore: 38.14,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.14,
        sitUps: 14.85,
        bending: 32.43,
        sideJump: 32.34,
        shuttleRun: 26.79,
        enduranceRun: null,
        sprintRun: 10.58,
        standingJump: 128.24,
        handballThrow: 9.83,
        totalScore: 43.7,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.61,
        sitUps: 15.86,
        bending: 33.88,
        sideJump: 34.84,
        shuttleRun: 31.14,
        enduranceRun: null,
        sprintRun: 10.35,
        standingJump: 134.26,
        handballThrow: 11.75,
        totalScore: 47.81,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.41,
        sitUps: 17.57,
        bending: 38.71,
        sideJump: 38.53,
        shuttleRun: 38.24,
        enduranceRun: null,
        sprintRun: 9.81,
        standingJump: 143.59,
        handballThrow: 14.1,
        totalScore: 54.47,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.6,
        sitUps: 18.99,
        bending: 40.77,
        sideJump: 41.99,
        shuttleRun: 41.73,
        enduranceRun: null,
        sprintRun: 9.42,
        standingJump: 152.23,
        handballThrow: 15.91,
        totalScore: 59.64,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.7,
        sitUps: 19.3,
        bending: 45.4,
        sideJump: 42.8,
        shuttleRun: 44.38,
        enduranceRun: 334.09,
        sprintRun: 9.11,
        standingJump: 161.92,
        handballThrow: 11.39,
        totalScore: 42.78,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.79,
        sitUps: 21.32,
        bending: 47.58,
        sideJump: 44.11,
        shuttleRun: 49.62,
        enduranceRun: 329.68,
        sprintRun: 8.87,
        standingJump: 166.47,
        handballThrow: 12.65,
        totalScore: 47.68,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.33,
        sitUps: 22.56,
        bending: 49.61,
        sideJump: 45.05,
        shuttleRun: 49.97,
        enduranceRun: 314.13,
        sprintRun: 8.73,
        standingJump: 172.4,
        handballThrow: 13.74,
        totalScore: 50.83,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.37,
        sitUps: 21.34,
        bending: 49.04,
        sideJump: 46.63,
        shuttleRun: 47.14,
        enduranceRun: 336.43,
        sprintRun: 8.97,
        standingJump: 169.82,
        handballThrow: 13.34,
        totalScore: 48.96,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.91,
        sitUps: 22.29,
        bending: 49.35,
        sideJump: 47.39,
        shuttleRun: 49.36,
        enduranceRun: 321.39,
        sprintRun: 8.96,
        standingJump: 171.95,
        handballThrow: 13.93,
        totalScore: 50.69,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.39,
        sitUps: 23.06,
        bending: 51.09,
        sideJump: 48.03,
        shuttleRun: 49.02,
        enduranceRun: 317.88,
        sprintRun: 8.94,
        standingJump: 171.04,
        handballThrow: 14.12,
        totalScore: 51.75,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  青森県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.07,
        sitUps: 11.7,
        bending: 26.03,
        sideJump: 27.73,
        shuttleRun: 19.07,
        enduranceRun: null,
        sprintRun: 11.86,
        standingJump: 111.61,
        handballThrow: 7.9,
        totalScore: 30.11,
        height: 117.5,
        weight: 22.6,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.59,
        sitUps: 14.41,
        bending: 27.33,
        sideJump: 31.97,
        shuttleRun: 27.89,
        enduranceRun: null,
        sprintRun: 10.98,
        standingJump: 123.82,
        handballThrow: 10.83,
        totalScore: 36.83,
        height: 123.5,
        weight: 25.6,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.56,
        sitUps: 15.81,
        bending: 29.24,
        sideJump: 35.1,
        shuttleRun: 34.17,
        enduranceRun: null,
        sprintRun: 10.5,
        standingJump: 132.57,
        handballThrow: 13.67,
        totalScore: 42,
        height: 129.2,
        weight: 29.4,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.3,
        sitUps: 17.39,
        bending: 30.69,
        sideJump: 38.54,
        shuttleRun: 40.72,
        enduranceRun: null,
        sprintRun: 10.1,
        standingJump: 140.04,
        handballThrow: 16.98,
        totalScore: 46.97,
        height: 134.9,
        weight: 33.6,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.34,
        sitUps: 19.16,
        bending: 32.56,
        sideJump: 42.05,
        shuttleRun: 48.79,
        enduranceRun: null,
        sprintRun: 9.72,
        standingJump: 149.3,
        handballThrow: 20.34,
        totalScore: 52.19,
        height: 140.4,
        weight: 37.4,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.71,
        sitUps: 20.74,
        bending: 34.28,
        sideJump: 44.83,
        shuttleRun: 55.87,
        enduranceRun: null,
        sprintRun: 9.3,
        standingJump: 159.59,
        handballThrow: 23.7,
        totalScore: 57.53,
        height: 147.4,
        weight: 42.7,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.54,
        sitUps: 22.59,
        bending: 40.8,
        sideJump: 48.02,
        shuttleRun: 64.2,
        enduranceRun: 460.86,
        sprintRun: 8.66,
        standingJump: 179.38,
        handballThrow: 16.62,
        totalScore: 33.99,
        height: 155.4,
        weight: 48,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 30.05,
        sitUps: 26.17,
        bending: 45.22,
        sideJump: 51.49,
        shuttleRun: 77.18,
        enduranceRun: 427.45,
        sprintRun: 8.02,
        standingJump: 198.76,
        handballThrow: 19.86,
        totalScore: 42.3,
        height: 162,
        weight: 53.1,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.62,
        sitUps: 28.33,
        bending: 48.71,
        sideJump: 54.75,
        shuttleRun: 83.63,
        enduranceRun: 412.31,
        sprintRun: 7.57,
        standingJump: 212.29,
        handballThrow: 22.53,
        totalScore: 49.21,
        height: 166.7,
        weight: 57.4,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.38,
        sitUps: 28.6,
        bending: 48.94,
        sideJump: 55.29,
        shuttleRun: 80.4,
        enduranceRun: 411.38,
        sprintRun: 7.51,
        standingJump: 216,
        handballThrow: 23.36,
        totalScore: 50.08,
        height: 169.3,
        weight: 60.9,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.68,
        sitUps: 29.94,
        bending: 50.93,
        sideJump: 56.85,
        shuttleRun: 84.58,
        enduranceRun: 403.16,
        sprintRun: 7.3,
        standingJump: 223.34,
        handballThrow: 24.46,
        totalScore: 53.77,
        height: 170.5,
        weight: 62.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.3,
        sitUps: 31.2,
        bending: 52.17,
        sideJump: 57.82,
        shuttleRun: 86.7,
        enduranceRun: 403.43,
        sprintRun: 7.26,
        standingJump: 225.41,
        handballThrow: 25.48,
        totalScore: 55.83,
        height: 171.2,
        weight: 65.2,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.57,
        sitUps: 11.52,
        bending: 28.28,
        sideJump: 27.22,
        shuttleRun: 16.82,
        enduranceRun: null,
        sprintRun: 12.05,
        standingJump: 103.96,
        handballThrow: 5.56,
        totalScore: 30.73,
        height: 116.8,
        weight: 22.1,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.06,
        sitUps: 14.06,
        bending: 30.2,
        sideJump: 31.28,
        shuttleRun: 23.32,
        enduranceRun: null,
        sprintRun: 11.18,
        standingJump: 116.23,
        handballThrow: 7.38,
        totalScore: 38.05,
        height: 122.9,
        weight: 25,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.91,
        sitUps: 15.6,
        bending: 32.34,
        sideJump: 34.32,
        shuttleRun: 28.9,
        enduranceRun: null,
        sprintRun: 10.63,
        standingJump: 125.55,
        handballThrow: 9.23,
        totalScore: 43.87,
        height: 129,
        weight: 28.5,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.04,
        sitUps: 17.11,
        bending: 34.88,
        sideJump: 37.84,
        shuttleRun: 35.33,
        enduranceRun: null,
        sprintRun: 10.17,
        standingJump: 134.47,
        handballThrow: 11.51,
        totalScore: 49.85,
        height: 135.8,
        weight: 32.8,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.58,
        sitUps: 18.57,
        bending: 36.96,
        sideJump: 40.98,
        shuttleRun: 42.17,
        enduranceRun: null,
        sprintRun: 9.83,
        standingJump: 143.15,
        handballThrow: 13.74,
        totalScore: 55.24,
        height: 142.7,
        weight: 37.4,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.31,
        sitUps: 19.32,
        bending: 38.93,
        sideJump: 43.15,
        shuttleRun: 46.66,
        enduranceRun: null,
        sprintRun: 9.46,
        standingJump: 150.81,
        handballThrow: 15.48,
        totalScore: 59.58,
        height: 148.8,
        weight: 42.2,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.26,
        sitUps: 19.39,
        bending: 43.73,
        sideJump: 44.83,
        shuttleRun: 48.33,
        enduranceRun: 313.54,
        sprintRun: 9.21,
        standingJump: 160.41,
        handballThrow: 10.98,
        totalScore: 43.01,
        height: 153,
        weight: 46,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.49,
        sitUps: 21.62,
        bending: 46.63,
        sideJump: 46.21,
        shuttleRun: 50.64,
        enduranceRun: 313.48,
        sprintRun: 9.02,
        standingJump: 165.01,
        handballThrow: 12.15,
        totalScore: 47.68,
        height: 155.6,
        weight: 49.3,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.59,
        sitUps: 23.29,
        bending: 49.89,
        sideJump: 47.24,
        shuttleRun: 51.48,
        enduranceRun: 312.6,
        sprintRun: 8.88,
        standingJump: 168.72,
        handballThrow: 13.14,
        totalScore: 51.17,
        height: 156.8,
        weight: 51.1,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.46,
        sitUps: 23.12,
        bending: 49.14,
        sideJump: 47.89,
        shuttleRun: 47.89,
        enduranceRun: 326.1,
        sprintRun: 8.93,
        standingJump: 170.41,
        handballThrow: 13.11,
        totalScore: 50.65,
        height: 157.5,
        weight: 51.9,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.18,
        sitUps: 23.34,
        bending: 50.18,
        sideJump: 48.01,
        shuttleRun: 48.44,
        enduranceRun: 328.68,
        sprintRun: 8.93,
        standingJump: 170.07,
        handballThrow: 13.53,
        totalScore: 51.48,
        height: 158.1,
        weight: 52.8,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.62,
        sitUps: 24.24,
        bending: 51,
        sideJump: 47.95,
        shuttleRun: 47.18,
        enduranceRun: 329.46,
        sprintRun: 8.95,
        standingJump: 170.89,
        handballThrow: 13.97,
        totalScore: 52.54,
        height: 158.3,
        weight: 53.1,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  静岡県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.22,
        sitUps: 11.46,
        bending: 26.31,
        sideJump: 27.36,
        shuttleRun: 19.79,
        enduranceRun: null,
        sprintRun: 11.56,
        standingJump: 116.08,
        handballThrow: 8.68,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.81,
        sitUps: 14.17,
        bending: 28,
        sideJump: 31.64,
        shuttleRun: 27.14,
        enduranceRun: null,
        sprintRun: 10.79,
        standingJump: 127.14,
        handballThrow: 11.79,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.53,
        sitUps: 15.34,
        bending: 29.09,
        sideJump: 33.47,
        shuttleRun: 32.93,
        enduranceRun: null,
        sprintRun: 10.25,
        standingJump: 135.76,
        handballThrow: 14.52,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.18,
        sitUps: 17.13,
        bending: 31.14,
        sideJump: 37.68,
        shuttleRun: 40.44,
        enduranceRun: null,
        sprintRun: 9.82,
        standingJump: 144.53,
        handballThrow: 17.48,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.16,
        sitUps: 19.04,
        bending: 33.2,
        sideJump: 41.53,
        shuttleRun: 48.18,
        enduranceRun: null,
        sprintRun: 9.44,
        standingJump: 153.05,
        handballThrow: 20.33,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.38,
        sitUps: 20.82,
        bending: 35.7,
        sideJump: 44.75,
        shuttleRun: 56.68,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 165.25,
        handballThrow: 23.45,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 24.94,
        sitUps: 23.96,
        bending: 42.43,
        sideJump: 49.97,
        shuttleRun: 69.81,
        enduranceRun: 429.53,
        sprintRun: 8.39,
        standingJump: 187.81,
        handballThrow: 18.37,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.95,
        sitUps: 26.97,
        bending: 46.85,
        sideJump: 53.69,
        shuttleRun: 81.02,
        enduranceRun: 397.16,
        sprintRun: 7.8,
        standingJump: 205.17,
        handballThrow: 21.58,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.53,
        sitUps: 29.59,
        bending: 51.96,
        sideJump: 56.68,
        shuttleRun: 87.04,
        enduranceRun: 384.63,
        sprintRun: 7.44,
        standingJump: 218.76,
        handballThrow: 24.32,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.71,
        sitUps: 30.86,
        bending: 53.61,
        sideJump: 59.47,
        shuttleRun: 91.18,
        enduranceRun: 375.97,
        sprintRun: 7.18,
        standingJump: 228.54,
        handballThrow: 26.09,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.57,
        sitUps: 32.57,
        bending: 55.67,
        sideJump: 60.86,
        shuttleRun: 94.82,
        enduranceRun: 368.04,
        sprintRun: 7.04,
        standingJump: 234.64,
        handballThrow: 27.81,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.71,
        sitUps: 33.3,
        bending: 57,
        sideJump: 61.43,
        shuttleRun: 90.25,
        enduranceRun: 377.11,
        sprintRun: 7.01,
        standingJump: 237.09,
        handballThrow: 28.61,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.73,
        sitUps: 10.86,
        bending: 28.94,
        sideJump: 26.45,
        shuttleRun: 15.94,
        enduranceRun: null,
        sprintRun: 11.92,
        standingJump: 108.91,
        handballThrow: 5.87,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.19,
        sitUps: 13.4,
        bending: 31.14,
        sideJump: 30.46,
        shuttleRun: 20.66,
        enduranceRun: null,
        sprintRun: 11.14,
        standingJump: 119.13,
        handballThrow: 7.85,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.9,
        sitUps: 14.51,
        bending: 32.48,
        sideJump: 31.67,
        shuttleRun: 24.97,
        enduranceRun: null,
        sprintRun: 10.59,
        standingJump: 128.08,
        handballThrow: 9.54,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.81,
        sitUps: 16.32,
        bending: 35.4,
        sideJump: 36.05,
        shuttleRun: 31.49,
        enduranceRun: null,
        sprintRun: 10.11,
        standingJump: 138,
        handballThrow: 11.66,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.24,
        sitUps: 17.89,
        bending: 38.22,
        sideJump: 39.62,
        shuttleRun: 38.48,
        enduranceRun: null,
        sprintRun: 9.69,
        standingJump: 147.03,
        handballThrow: 13.67,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.03,
        sitUps: 18.8,
        bending: 40.87,
        sideJump: 42.08,
        shuttleRun: 42.76,
        enduranceRun: null,
        sprintRun: 9.31,
        standingJump: 154.2,
        handballThrow: 15.42,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.95,
        sitUps: 20.24,
        bending: 45.76,
        sideJump: 45.64,
        shuttleRun: 51.13,
        enduranceRun: 309.78,
        sprintRun: 9.03,
        standingJump: 167.74,
        handballThrow: 11.93,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.9,
        sitUps: 22.45,
        bending: 49.13,
        sideJump: 47.5,
        shuttleRun: 53.73,
        enduranceRun: 299.43,
        sprintRun: 8.78,
        standingJump: 174.02,
        handballThrow: 13.43,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25.54,
        sitUps: 24.05,
        bending: 52.48,
        sideJump: 48.74,
        shuttleRun: 54.25,
        enduranceRun: 298.85,
        sprintRun: 8.68,
        standingJump: 177.96,
        handballThrow: 14.53,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 26.03,
        sitUps: 24.42,
        bending: 54.2,
        sideJump: 50.77,
        shuttleRun: 53.76,
        enduranceRun: 300.07,
        sprintRun: 8.63,
        standingJump: 180.33,
        handballThrow: 14.82,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.83,
        sitUps: 25.86,
        bending: 56.41,
        sideJump: 51.73,
        shuttleRun: 54.84,
        enduranceRun: 298.09,
        sprintRun: 8.57,
        standingJump: 182.79,
        handballThrow: 15.67,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.96,
        sitUps: 26.22,
        bending: 57.54,
        sideJump: 51.79,
        shuttleRun: 51.88,
        enduranceRun: 303.98,
        sprintRun: 8.61,
        standingJump: 182.1,
        handballThrow: 15.91,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  香川県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.62,
        sitUps: 10.89,
        bending: 26,
        sideJump: 25.75,
        shuttleRun: 16.43,
        enduranceRun: null,
        sprintRun: 11.79,
        standingJump: 111.46,
        handballThrow: 7.94,
        totalScore: 28.69,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.08,
        sitUps: 13.66,
        bending: 27.28,
        sideJump: 29.15,
        shuttleRun: 25.57,
        enduranceRun: null,
        sprintRun: 10.89,
        standingJump: 123,
        handballThrow: 11.18,
        totalScore: 35.45,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.7,
        sitUps: 15.17,
        bending: 29.2,
        sideJump: 32.77,
        shuttleRun: 31.44,
        enduranceRun: null,
        sprintRun: 10.32,
        standingJump: 131.11,
        handballThrow: 13.77,
        totalScore: 40.84,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.45,
        sitUps: 17.03,
        bending: 30.67,
        sideJump: 36.51,
        shuttleRun: 39.11,
        enduranceRun: null,
        sprintRun: 9.85,
        standingJump: 141.42,
        handballThrow: 17.22,
        totalScore: 46.38,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.54,
        sitUps: 18.74,
        bending: 32.19,
        sideJump: 40.73,
        shuttleRun: 45.58,
        enduranceRun: null,
        sprintRun: 9.45,
        standingJump: 149.45,
        handballThrow: 20.52,
        totalScore: 51.62,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.21,
        sitUps: 20.78,
        bending: 35.23,
        sideJump: 43.94,
        shuttleRun: 53.58,
        enduranceRun: null,
        sprintRun: 8.98,
        standingJump: 160.14,
        handballThrow: 23.97,
        totalScore: 57.57,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.32,
        sitUps: 22.55,
        bending: 39.31,
        sideJump: 48.59,
        shuttleRun: 61.99,
        enduranceRun: null,
        sprintRun: 8.63,
        standingJump: 183.3,
        handballThrow: 17.41,
        totalScore: 33.02,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 28.39,
        sitUps: 24.95,
        bending: 43.54,
        sideJump: 51.78,
        shuttleRun: 76.54,
        enduranceRun: null,
        sprintRun: 8.06,
        standingJump: 198.31,
        handballThrow: 20,
        totalScore: 40.56,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.59,
        sitUps: 27.81,
        bending: 47.61,
        sideJump: 55.64,
        shuttleRun: 87.12,
        enduranceRun: null,
        sprintRun: 7.61,
        standingJump: 214.88,
        handballThrow: 23.25,
        totalScore: 48.93,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 37.46,
        sitUps: 28.33,
        bending: 48.96,
        sideJump: 56.69,
        shuttleRun: 84.68,
        enduranceRun: null,
        sprintRun: 7.47,
        standingJump: 218.64,
        handballThrow: 24.49,
        totalScore: 51.08,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 39.4,
        sitUps: 29.19,
        bending: 50.56,
        sideJump: 57.76,
        shuttleRun: 89.29,
        enduranceRun: null,
        sprintRun: 7.27,
        standingJump: 225.04,
        handballThrow: 25.46,
        totalScore: 54.37,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 41.01,
        sitUps: 30.64,
        bending: 52.34,
        sideJump: 58.86,
        shuttleRun: 91.51,
        enduranceRun: null,
        sprintRun: 7.16,
        standingJump: 226.79,
        handballThrow: 26.77,
        totalScore: 56.85,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.12,
        sitUps: 10.61,
        bending: 28.49,
        sideJump: 25.32,
        shuttleRun: 14.61,
        enduranceRun: null,
        sprintRun: 12.13,
        standingJump: 104.94,
        handballThrow: 5.49,
        totalScore: 29.03,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.61,
        sitUps: 13.06,
        bending: 30.08,
        sideJump: 28.48,
        shuttleRun: 20.67,
        enduranceRun: null,
        sprintRun: 11.29,
        standingJump: 114.63,
        handballThrow: 7.2,
        totalScore: 35.79,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.12,
        sitUps: 14.58,
        bending: 32.25,
        sideJump: 31.66,
        shuttleRun: 24.75,
        enduranceRun: null,
        sprintRun: 10.6,
        standingJump: 123.66,
        handballThrow: 9.1,
        totalScore: 41.6,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.08,
        sitUps: 16.34,
        bending: 34.68,
        sideJump: 34.82,
        shuttleRun: 30.91,
        enduranceRun: null,
        sprintRun: 10.14,
        standingJump: 134.52,
        handballThrow: 11.34,
        totalScore: 47.66,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.38,
        sitUps: 17.83,
        bending: 37,
        sideJump: 39.22,
        shuttleRun: 37.52,
        enduranceRun: null,
        sprintRun: 9.64,
        standingJump: 143.04,
        handballThrow: 13.66,
        totalScore: 53.92,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.39,
        sitUps: 18.89,
        bending: 40.12,
        sideJump: 41.14,
        shuttleRun: 42.65,
        enduranceRun: null,
        sprintRun: 9.26,
        standingJump: 150.3,
        handballThrow: 15.98,
        totalScore: 59.09,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 20.89,
        sitUps: 19.12,
        bending: 43.39,
        sideJump: 44.54,
        shuttleRun: 44.37,
        enduranceRun: null,
        sprintRun: 9.14,
        standingJump: 163.78,
        handballThrow: 11.2,
        totalScore: 42.27,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.01,
        sitUps: 20.95,
        bending: 45.69,
        sideJump: 46.07,
        shuttleRun: 50.96,
        enduranceRun: null,
        sprintRun: 8.97,
        standingJump: 168.2,
        handballThrow: 12.41,
        totalScore: 47.17,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.8,
        sitUps: 22.71,
        bending: 49.82,
        sideJump: 48.07,
        shuttleRun: 54.06,
        enduranceRun: null,
        sprintRun: 8.8,
        standingJump: 175.04,
        handballThrow: 13.95,
        totalScore: 52.11,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.88,
        sitUps: 22.84,
        bending: 50.24,
        sideJump: 48.6,
        shuttleRun: 49.48,
        enduranceRun: null,
        sprintRun: 8.92,
        standingJump: 173.09,
        handballThrow: 13.83,
        totalScore: 51.74,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.36,
        sitUps: 23.47,
        bending: 52.02,
        sideJump: 48.79,
        shuttleRun: 51.68,
        enduranceRun: null,
        sprintRun: 8.9,
        standingJump: 173.98,
        handballThrow: 14.4,
        totalScore: 53.23,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.84,
        sitUps: 23.54,
        bending: 52.64,
        sideJump: 48.85,
        shuttleRun: 51.05,
        enduranceRun: null,
        sprintRun: 8.94,
        standingJump: 172.96,
        handballThrow: 14.51,
        totalScore: 53.52,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  高知県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.79,
        sitUps: 12.06,
        bending: 27.56,
        sideJump: 26.49,
        shuttleRun: 18.76,
        enduranceRun: null,
        sprintRun: 11.73,
        standingJump: 113.22,
        handballThrow: 8.84,
        totalScore: 30.23,
        height: 116.11,
        weight: 21.53,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.36,
        sitUps: 14.5,
        bending: 29.53,
        sideJump: 30.13,
        shuttleRun: 26.66,
        enduranceRun: null,
        sprintRun: 10.89,
        standingJump: 126.28,
        handballThrow: 11.52,
        totalScore: 37.43,
        height: 121.75,
        weight: 24.55,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.23,
        sitUps: 16.18,
        bending: 31.35,
        sideJump: 33.93,
        shuttleRun: 33.26,
        enduranceRun: null,
        sprintRun: 10.33,
        standingJump: 133.07,
        handballThrow: 14.68,
        totalScore: 42.72,
        height: 127.63,
        weight: 27.82,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.69,
        sitUps: 17.91,
        bending: 32,
        sideJump: 36.74,
        shuttleRun: 38.13,
        enduranceRun: null,
        sprintRun: 9.91,
        standingJump: 142.04,
        handballThrow: 17.75,
        totalScore: 46.72,
        height: 133.24,
        weight: 31.12,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.06,
        sitUps: 19.42,
        bending: 34.49,
        sideJump: 40.76,
        shuttleRun: 45.84,
        enduranceRun: null,
        sprintRun: 9.53,
        standingJump: 152.4,
        handballThrow: 20.76,
        totalScore: 52.98,
        height: 138.77,
        weight: 35.07,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.14,
        sitUps: 21.27,
        bending: 35.95,
        sideJump: 43.57,
        shuttleRun: 53.16,
        enduranceRun: null,
        sprintRun: 9.18,
        standingJump: 160.56,
        handballThrow: 24.08,
        totalScore: 57.32,
        height: 145.35,
        weight: 39.54,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.83,
        sitUps: 23.02,
        bending: 40.91,
        sideJump: 47.99,
        shuttleRun: 60.77,
        enduranceRun: 456.72,
        sprintRun: 8.68,
        standingJump: 180.96,
        handballThrow: 17.83,
        totalScore: 32.66,
        height: 153.19,
        weight: 45.98,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.3,
        sitUps: 25.86,
        bending: 43.81,
        sideJump: 51.19,
        shuttleRun: 73.96,
        enduranceRun: 417.91,
        sprintRun: 8.06,
        standingJump: 198.2,
        handballThrow: 20.61,
        totalScore: 40.07,
        height: 160.1,
        weight: 50.22,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 34.18,
        sitUps: 28.06,
        bending: 46.39,
        sideJump: 54.07,
        shuttleRun: 84.96,
        enduranceRun: 400.94,
        sprintRun: 7.65,
        standingJump: 212.28,
        handballThrow: 23.04,
        totalScore: 46.58,
        height: 164.83,
        weight: 54.72,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.69,
        sitUps: 27.28,
        bending: 44.43,
        sideJump: 55.28,
        shuttleRun: 73.49,
        enduranceRun: 391.31,
        sprintRun: 7.61,
        standingJump: 214.98,
        handballThrow: 22.76,
        totalScore: 45.17,
        height: 167.17,
        weight: 58.67,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.94,
        sitUps: 28.54,
        bending: 45.84,
        sideJump: 57.07,
        shuttleRun: 79.26,
        enduranceRun: 393.81,
        sprintRun: 7.52,
        standingJump: 220.48,
        handballThrow: 23.76,
        totalScore: 48.97,
        height: 168.8,
        weight: 60.37,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.62,
        sitUps: 30.22,
        bending: 47.62,
        sideJump: 58.11,
        shuttleRun: 82.75,
        enduranceRun: 393.97,
        sprintRun: 7.4,
        standingJump: 223.6,
        handballThrow: 25.76,
        totalScore: 52.19,
        height: 169.32,
        weight: 62.21,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.22,
        sitUps: 11.83,
        bending: 29.71,
        sideJump: 26.35,
        shuttleRun: 16.45,
        enduranceRun: null,
        sprintRun: 11.99,
        standingJump: 106.47,
        handballThrow: 5.71,
        totalScore: 30.66,
        height: 115.58,
        weight: 21.2,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 9.75,
        sitUps: 13.74,
        bending: 31.83,
        sideJump: 29.82,
        shuttleRun: 22.78,
        enduranceRun: null,
        sprintRun: 11.15,
        standingJump: 119.09,
        handballThrow: 7.61,
        totalScore: 38.37,
        height: 121.33,
        weight: 23.79,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.33,
        sitUps: 15.41,
        bending: 33.8,
        sideJump: 33.15,
        shuttleRun: 28.33,
        enduranceRun: null,
        sprintRun: 10.57,
        standingJump: 126.1,
        handballThrow: 9.85,
        totalScore: 43.84,
        height: 127.63,
        weight: 27.15,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.27,
        sitUps: 17.13,
        bending: 35.05,
        sideJump: 35.42,
        shuttleRun: 31.82,
        enduranceRun: null,
        sprintRun: 10.17,
        standingJump: 134.1,
        handballThrow: 11.77,
        totalScore: 48.39,
        height: 133.85,
        weight: 31.01,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 15.96,
        sitUps: 18.8,
        bending: 38.28,
        sideJump: 39.13,
        shuttleRun: 39.37,
        enduranceRun: null,
        sprintRun: 9.77,
        standingJump: 144.25,
        handballThrow: 13.84,
        totalScore: 54.46,
        height: 140.72,
        weight: 35.49,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.51,
        sitUps: 19.46,
        bending: 40.17,
        sideJump: 41.48,
        shuttleRun: 43.15,
        enduranceRun: null,
        sprintRun: 9.44,
        standingJump: 149.9,
        handballThrow: 15.73,
        totalScore: 58.75,
        height: 146.98,
        weight: 40.35,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.38,
        sitUps: 20.04,
        bending: 43.96,
        sideJump: 44.72,
        shuttleRun: 45.79,
        enduranceRun: 328.86,
        sprintRun: 9.26,
        standingJump: 163.04,
        handballThrow: 11.59,
        totalScore: 42.3,
        height: 151.36,
        weight: 44.68,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.53,
        sitUps: 22.18,
        bending: 46.11,
        sideJump: 46.87,
        shuttleRun: 53.27,
        enduranceRun: 312.42,
        sprintRun: 8.98,
        standingJump: 169.69,
        handballThrow: 13.24,
        totalScore: 47.82,
        height: 154,
        weight: 47.82,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 24.61,
        sitUps: 22.97,
        bending: 47.74,
        sideJump: 47.57,
        shuttleRun: 53.25,
        enduranceRun: 310.69,
        sprintRun: 8.95,
        standingJump: 171.39,
        handballThrow: 13.85,
        totalScore: 49.39,
        height: 155.78,
        weight: 50.01,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 25.18,
        sitUps: 21.13,
        bending: 4419,
        sideJump: 47.36,
        shuttleRun: 40.84,
        enduranceRun: 326.25,
        sprintRun: 9.19,
        standingJump: 168.82,
        handballThrow: 13.35,
        totalScore: 45.51,
        height: 155.88,
        weight: 51.36,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 26.03,
        sitUps: 22.2,
        bending: 45.58,
        sideJump: 48.51,
        shuttleRun: 46.61,
        enduranceRun: 324.71,
        sprintRun: 9.26,
        standingJump: 168.39,
        handballThrow: 13.9,
        totalScore: 47.87,
        height: 156.7,
        weight: 51.76,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.49,
        sitUps: 23.24,
        bending: 47.29,
        sideJump: 48.73,
        shuttleRun: 46.45,
        enduranceRun: 322.5,
        sprintRun: 9.21,
        standingJump: 168.98,
        handballThrow: 14.24,
        totalScore: 49.15,
        height: 156.94,
        weight: 52.61,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
  鳥取県: {
    male: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 9.19,
        sitUps: 10.9,
        bending: 26.23,
        sideJump: 27.29,
        shuttleRun: 20.62,
        enduranceRun: null,
        sprintRun: 11.64,
        standingJump: 113.95,
        handballThrow: 8.21,
        totalScore: 30.41,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.77,
        sitUps: 13.66,
        bending: 27.72,
        sideJump: 30.99,
        shuttleRun: 29.42,
        enduranceRun: null,
        sprintRun: 10.74,
        standingJump: 126.2,
        handballThrow: 11.46,
        totalScore: 37.29,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 12.51,
        sitUps: 15.58,
        bending: 29.36,
        sideJump: 34.81,
        shuttleRun: 38.22,
        enduranceRun: null,
        sprintRun: 10.22,
        standingJump: 134.79,
        handballThrow: 14.62,
        totalScore: 42.98,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 14.46,
        sitUps: 17.22,
        bending: 30.76,
        sideJump: 38.43,
        shuttleRun: 45.14,
        enduranceRun: null,
        sprintRun: 9.79,
        standingJump: 143.14,
        handballThrow: 18.49,
        totalScore: 48.22,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.6,
        sitUps: 18.59,
        bending: 32.6,
        sideJump: 41.58,
        shuttleRun: 52.39,
        enduranceRun: null,
        sprintRun: 9.49,
        standingJump: 151.18,
        handballThrow: 21.3,
        totalScore: 52.91,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 19.15,
        sitUps: 20.27,
        bending: 34.21,
        sideJump: 44.41,
        shuttleRun: 59.82,
        enduranceRun: null,
        sprintRun: 9.07,
        standingJump: 160.86,
        handballThrow: 24.97,
        totalScore: 57.99,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 23.71,
        sitUps: 22.05,
        bending: 39.15,
        sideJump: 48.61,
        shuttleRun: 69.05,
        enduranceRun: null,
        sprintRun: 8.56,
        standingJump: 181.31,
        handballThrow: 17.17,
        totalScore: 33.2,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 29.4,
        sitUps: 25.71,
        bending: 42.8,
        sideJump: 52.7,
        shuttleRun: 83.18,
        enduranceRun: null,
        sprintRun: 7.91,
        standingJump: 202.49,
        handballThrow: 20.45,
        totalScore: 42.27,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 33.67,
        sitUps: 28.24,
        bending: 46.78,
        sideJump: 55.79,
        shuttleRun: 90.13,
        enduranceRun: null,
        sprintRun: 7.45,
        standingJump: 214.15,
        handballThrow: 22.8,
        totalScore: 49.07,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 36.27,
        sitUps: 27.51,
        bending: 49.27,
        sideJump: 55.85,
        shuttleRun: 86.44,
        enduranceRun: null,
        sprintRun: 7.41,
        standingJump: 223.71,
        handballThrow: 23.98,
        totalScore: 50.72,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 38.67,
        sitUps: 28.93,
        bending: 51.18,
        sideJump: 57.21,
        shuttleRun: 90.59,
        enduranceRun: null,
        sprintRun: 7.24,
        standingJump: 229.3,
        handballThrow: 25.37,
        totalScore: 54.47,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 40.74,
        sitUps: 30.33,
        bending: 52.78,
        sideJump: 57.9,
        shuttleRun: 91.48,
        enduranceRun: null,
        sprintRun: 7.19,
        standingJump: 232.38,
        handballThrow: 26.1,
        totalScore: null,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
    female: [
      {
        schoolCategoryCode: 'B1',
        grade: 1,
        age: 6,
        grip: 8.69,
        sitUps: 10.75,
        bending: 28.32,
        sideJump: 26.73,
        shuttleRun: 17.35,
        enduranceRun: null,
        sprintRun: 11.96,
        standingJump: 106.93,
        handballThrow: 5.55,
        totalScore: 30.62,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 2,
        age: 7,
        grip: 10.01,
        sitUps: 13.31,
        bending: 30.46,
        sideJump: 30.39,
        shuttleRun: 23.81,
        enduranceRun: null,
        sprintRun: 11.07,
        standingJump: 118.23,
        handballThrow: 7.45,
        totalScore: 37.9,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 3,
        age: 8,
        grip: 11.96,
        sitUps: 14.89,
        bending: 32.35,
        sideJump: 34.04,
        shuttleRun: 30.16,
        enduranceRun: null,
        sprintRun: 10.58,
        standingJump: 127.22,
        handballThrow: 9.4,
        totalScore: 45.33,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 4,
        age: 9,
        grip: 13.64,
        sitUps: 16.57,
        bending: 34.33,
        sideJump: 37.1,
        shuttleRun: 36.3,
        enduranceRun: null,
        sprintRun: 10.08,
        standingJump: 135.27,
        handballThrow: 11.54,
        totalScore: 49.36,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 5,
        age: 10,
        grip: 16.3,
        sitUps: 18.04,
        bending: 36.6,
        sideJump: 40.31,
        shuttleRun: 43.81,
        enduranceRun: null,
        sprintRun: 9.7,
        standingJump: 145.17,
        handballThrow: 13.79,
        totalScore: 55.17,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'B1',
        grade: 6,
        age: 11,
        grip: 18.53,
        sitUps: 18.6,
        bending: 38.51,
        sideJump: 42.3,
        shuttleRun: 48.35,
        enduranceRun: null,
        sprintRun: 9.39,
        standingJump: 149.23,
        handballThrow: 15.4,
        totalScore: 58.83,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 1,
        age: 12,
        grip: 21.04,
        sitUps: 19.09,
        bending: 42.45,
        sideJump: 45.46,
        shuttleRun: 52.51,
        enduranceRun: null,
        sprintRun: 9.1,
        standingJump: 163.88,
        handballThrow: 11.12,
        totalScore: 43.26,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 2,
        age: 13,
        grip: 23.1,
        sitUps: 21.45,
        bending: 45.62,
        sideJump: 47.29,
        shuttleRun: 56.08,
        enduranceRun: null,
        sprintRun: 8.83,
        standingJump: 170.59,
        handballThrow: 12.29,
        totalScore: 48.44,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'C1',
        grade: 3,
        age: 14,
        grip: 25,
        sitUps: 23.82,
        bending: 48.97,
        sideJump: 49.71,
        shuttleRun: 58.4,
        enduranceRun: null,
        sprintRun: 8.65,
        standingJump: 175.08,
        handballThrow: 13.43,
        totalScore: 53.18,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 1,
        age: 15,
        grip: 24.92,
        sitUps: 21.7,
        bending: 49.15,
        sideJump: 48.13,
        shuttleRun: 50.94,
        enduranceRun: null,
        sprintRun: 8.82,
        standingJump: 176.61,
        handballThrow: 13.26,
        totalScore: 50.89,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 2,
        age: 16,
        grip: 25.95,
        sitUps: 22.96,
        bending: 50.79,
        sideJump: 48.64,
        shuttleRun: 53.01,
        enduranceRun: null,
        sprintRun: 8.75,
        standingJump: 178,
        handballThrow: 14.11,
        totalScore: 53.35,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 3,
        age: 17,
        grip: 26.51,
        sitUps: 23.26,
        bending: 51.69,
        sideJump: 49.06,
        shuttleRun: 52.53,
        enduranceRun: null,
        sprintRun: 8.87,
        standingJump: 176.59,
        handballThrow: 14.52,
        totalScore: 53.86,
        height: null,
        weight: null,
      },
      {
        schoolCategoryCode: 'D1',
        grade: 4,
        age: 18,
        grip: null,
        sitUps: null,
        bending: null,
        sideJump: null,
        shuttleRun: null,
        enduranceRun: null,
        sprintRun: null,
        standingJump: null,
        handballThrow: null,
        totalScore: null,
        height: null,
        weight: null,
      },
    ],
  },
}

export default prefectureAverage2022
